import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import Display from '../Display';


class Notes extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            toggle: false,
            note: '',
            notes: []
        };
        this.buildDOM = this.buildDOM.bind( this );
        this.addNew = this.addNew.bind( this );
        this.noteChange = this.noteChange.bind( this );
        this.saveNote = this.saveNote.bind( this );
        this.edit = this.edit.bind( this );
        this.delete = this.delete.bind( this );
    }

    UNSAFE_componentWillMount() {
    // this is needed in case of refresh on route /account
        this.setState( { notes: this.props.data } );
    }

    componentDidMount() {
    }

    edit( timestamp ) {
        this.setState( { note: this.state.notes.filter( x => x.timestamp === timestamp )[0].value, toggle: true, notes: this.state.notes.filter( x => x.timestamp !== timestamp ) } );
    }

    delete( timestamp ) {
        this.setState( { note: '', notes: this.state.notes.filter( x => x.timestamp !== timestamp ) } );
    }

    buildDOM( ) {
        return this.state.notes.map( note => <ListGroupItem className="noteItem">{note.value}<Display when={ note.new }><div className="actionHolder"><span className="fa fa-edit" onClick={ () => this.edit( note.timestamp ) } /> <span className="fa fa-remove" onClick={ () => this.delete( note.timestamp ) } /></div></Display><div className="noteDetails">by: {note.by}, at: {moment( note.timestamp ).format( 'DD/MM/YY HH:MM A' )}</div></ListGroupItem> );
    }

    addNew() {
        this.setState( { toggle: true } );
    }

    saveNote() {
        this.setState( { notes: [ { new: true, value: this.state.note, timestamp: new Date().toISOString(), by: this.props.currentUser.email }, ...this.state.notes ], toggle: false, note: '' } );
        setTimeout( () => { this.props.notesUpdates( this.state.notes ); }, 500 );
    }

    noteChange( e ) {
        this.setState( { note: e.target.value } );
    }

    render() {
        return (
            <React.Fragment>
                <Display when={ !this.state.toggle }>
                    <Button onClick={ this.addNew } className="position-absolute small" style={ { top: -6, right: 0 } }>Add new</Button>
                </Display>
                <ListGroup>
                    <Display when={ this.state.toggle }>
                        <React.Fragment>
                            <textarea onChange={ this.noteChange } className="form-control" value={ this.state.note } />
                            <Button onClick={ this.saveNote }>save</Button>
                        </React.Fragment>
                    </Display>
                    {this.buildDOM()}
                </ListGroup>
            </React.Fragment>
        );
    }
}

Notes.propTypes = {
    data: PropTypes.array.isRequired,
    notesUpdates: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired
};

export default Notes;
