import React, { Component, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';

import Button from 'reactstrap/lib/Button';
import FileAction from './FileAction';

import { formatBytes } from '../../utils/formatFileSize';
import fetchHelper, { baseAxios } from '../../utils/fetchHelper';
import TableView from '../Table/Table';
import FileViewModal from '../Modals/FileViewModal';
import { versionOperations } from '../../redux/ducks/version';
import FileRenameModal from '../Modals/FileRenameModal';
import { fileOperations } from '../../redux/ducks/file';

const columns = [
    { name: 'fileName', title: 'File Name' },
    { name: 'date', title: 'Upload Date' },
    { name: 'contentType', title: 'Content Type' },
    { name: 'size', title: 'Size' },
    { name: 'action', title: 'Action' }
];
const columnWidths = [
    { columnName: 'fileName', width: '40%' },
    { columnName: 'date', width: '10%' },
    { columnName: 'contentType', width: '30%' },
    { columnName: 'size', width: '10%' },
    { columnName: 'action', width: '10%' }
];

class FileList extends Component {
    constructor() {
        super();
        this.state = {
            files: [],
            showFileView: false,
            modal: false,
        };
        this.currentFile = null;
        this.onFileDeleteClick = this.onFileDeleteClick.bind( this );
        this.onFileViewClick = this.onFileViewClick.bind( this );
        this.toggleFileViewModal = this.toggleFileViewModal.bind( this );
        this.onFileClick = this.onFileClick.bind( this );
        this.deleteFile = this.deleteFile.bind( this );
        this.handleModalToggle = this.handleModalToggle.bind( this );
        this.buildRow = this.buildRow.bind( this );
        this.onFileRename = this.onFileRename.bind( this );
        this.saveNewFileName = this.saveNewFileName.bind( this );
        this.asideToggle = this.asideToggle.bind( this );
    }

    componentDidMount() {
        const { documentId, accountId } = this.props;
        firebase.firestore().collection( 'filesMeta' )
            .where( 'accountId', '==', accountId )
            .where( 'documentId', '==', documentId )
            .orderBy( 'fileName' )
            .onSnapshot( ( querySnapshot ) => {
                const files = [];
                querySnapshot.forEach( doc => {
                    files.push( { ...doc.data(), fileMetaId: doc.id } );
                } );
                this.setState( { files } );
            } );
    }

    componentWillUnmount() {
        const unsubscribeFiles = firebase.firestore().collection( 'filesMeta' ).onSnapshot( () => {} );
        unsubscribeFiles();
    }

    onFileDeleteClick( file ) {
        this.setState( { modal: true, deleteFile: file } );
    }

    onFileRename( file ) {
        this.setState( { renameModal: true, fileToRename: file } );
    }

    onFileViewClick( file ) {
        fetchHelper( 'requestDownloadUrl', 'POST', null, `{ "filename": "/${file.filePath}", "accountId": ${this.props.accountId}, "categoryNode": ${this.props.document.formData.location}` )
            .then( ( result ) => {
                this.setState( { fileViewUrl: result.data.downloadUrl, showFileView: true } );
            } )
        // next then()
            .catch( ( err ) => {
                console.log( err );
            } );
    }

    toggleFileViewModal() {
        this.setState( prevState => ( {
            showFileView: !prevState.showFileView
        } ) );
    }

    onFileClick( file, requestPdf = false ) {
        const fileData = file;
        let fileName;
        let type = '';
        if ( requestPdf ) {
            fileName = `${fileData.fileName.split( '.' ).slice( 0, -1 ).join( '.' )}.pdf`;
            type = 'application/pdf';
        } else {
            fileName = fileData.fileName;
        }

        fetchHelper( 'requestDownloadUrl', 'POST', null, { filename: `/${file.filePath}`, pdf: requestPdf, accountId: this.props.accountId, categoryNode: this.props.document.formData.location } )
            .then( ( result ) => {
                baseAxios()( {
                    url: `${result.data.downloadUrl}&alt=media`,
                    method: 'GET',
                    responseType: 'blob', // important
                } ).then( ( response ) => {
                    const url = window.URL.createObjectURL( new Blob( [ response.data ] ) );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    link.setAttribute( 'download', fileName.split( '/' )[fileName.split( '/' ).length - 1] );
                    // link.setAttribute( 'download', fileData.fileName );
                    document.body.appendChild( link );
                    link.click();
                } );
            } )
            // next then()
            .catch( ( err ) => {
                console.log( err );
            } );
    }

    deleteFile() {
        fetchHelper( 'deleteFile', 'POST', null, JSON.stringify( this.state.deleteFile ) )
            .then( ( result ) => {
                this.setState( { deleteFile: undefined } );
            } )
            .catch( ( err ) => {
                console.log( err );
            } );
        this.setState( { modal: false } );
    }

    handleModalToggle() { this.setState( { modal: !this.state.modal } ); }

    asideToggle( file ) {
        this.currentFile = file.fileId;
        this.props.getVersions( {
            accountId: file.accountId,
            elementId: file.fileMetaId,
            element: file,
            collection: 'filesMeta'
        } );
    }

    buildRow( data ) {
        return data.map( file => (
            {
                id: file.id,
                fileName: <div>{file.fileName}</div>,
                date: file.uploaded,
                contentType: <div><span onClick={ () => this.onFileClick( file ) }>{imagePlaceholder( file.contentType )}</span>
                    <span onClick={ () => this.onFileClick( file, true ) }>{ file.isPDFConverted ? imagePlaceholder( 'application/pdf' ) : ''}</span>
                </div>,
                size: formatBytes( file.size ),
                action: <FileAction
                    file={ file }
                    asideToggle={ this.asideToggle }
                    canCreate={ this.props.canCreate }
                    onFileDeleteClick={ this.onFileDeleteClick }
                    onFileRename={ this.onFileRename }
                    onFileClick={ this.onFileClick } />
            }
        ) );
    }

    async saveNewFileName( newName, file ) {
        if ( this.state.saveInProgress ) {
            return;
        }
        const payload = {
            fileName: `${newName}.${file.fileName.split( '.' ).last()}`,
            accountId: file.accountId,
            fileMetaId: file.fileMetaId
        };
        this.setState( { saveInProgress: true } );
        await this.props.renameFile( payload );
        this.setState( { newName, renameModal: false, saveInProgress: false } );
    }

    render() {
        const data = this.buildRow( this.state.files );
        console.log( 'new name', this.state.newName );
        return (
            <Fragment>

                <FileViewModal url={ this.state.fileViewUrl } showFileView={ this.state.showFileView } onClose={ this.toggleFileViewModal } />
                <FileRenameModal
                    file={ this.state.fileToRename }
                    showModal={ this.state.renameModal }
                    saveInProgress={ this.state.saveInProgress }
                    onClose={ () => { this.setState( { renameModal: false, fileToRename: null } ); } }
                    onSave={ this.saveNewFileName }
                />

                <div className="row mb-4" style={ { marginTop: '30px' } }>
                    <div className="col-12 customTable clickableRow">
                        <h3>File List</h3>
                        <TableView
                            rowsDOM={ data }
                            columns={ columns }
                            columnWidths={ columnWidths }
                            disableSortingFor={ [ 'action' ] }
                        />
                        <Modal isOpen={ this.state.modal } toggle={ this.handleModalToggle }>
                            <ModalHeader toggle={ this.handleModalToggle }>Delete File</ModalHeader>
                            <ModalBody>
                        Are you sure you want to delete the File:
                                {' '}
                                <b>{ this.state.deleteFile && this.state.deleteFile.fileName }</b>
  ?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={ this.deleteFile }>Delete</Button>
                                {' '}
                                <Button color="secondary" onClick={ this.handleModalToggle }>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
                <div style={ { marginTop: '75px', marginBottom: '75px' } } />
            </Fragment>
        );
    }
}

const imagePlaceholder = ( contentType ) => {
    const splitted = contentType.split( '/' );
    if ( splitted[0] === 'image' ) {
        return <img src="img/images/image.png" width="35px" />;
    }
    if ( splitted[1] === 'pdf' ) {
        return <img style={ { padding: '5px 0px 5px 0px' } } src="img/images/pdf-48x48.png" width="35px" />;
    }
    if ( splitted[0] === 'text' || splitted[1] === 'msword' || splitted[1].contains( 'word' ) || splitted[1] === 'rtf' ) {
        return <img src="img/images/docx_48x1.svg" width="35px" />;
    }
    if ( splitted[0] === 'text' || splitted[1] === 'spreadsheet' || splitted[1].contains( 'sheet' ) ) {
        return <img src="img/images/xlsx_48x1.svg" width="35px" />;
    }

    if ( splitted[0] === 'text' || splitted[1] === 'csv' || splitted[1].contains( 'csv' ) ) {
        return <img src="img/images/csv_48x1.svg" width="35px" />;
    }

    if ( splitted[0] === 'application' || splitted[1].contains( 'powerpoint' ) || splitted[1].contains( 'presentation' ) ) {
        return <img src="img/images/pptx_48x1.svg" width="35px" />;
    }

    return contentType;
};


FileList.propTypes = {
    documentId: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    document: PropTypes.object.isRequired,
};

const mapStateToProps = state => ( {
    currentUser: state.currentUser
} );

const mapDispatchToProps = dispatch => ( {
    getVersions: ( data ) => dispatch( versionOperations.getVersions( data ) ),
    renameFile: ( data ) => dispatch( fileOperations.renameFile( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( FileList );
