import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.firestore_apiKey,
    authDomain: process.env.firestore_authDomain,
    databaseURL: process.env.firestore_databaseURL,
    projectId: process.env.firestore_projectId,
    storageBucket: process.env.firestore_storageBucket,
    messagingSenderId: process.env.firestore_messagingSenderId
};

if ( !firebase.apps.length ) {
    firebase.initializeApp( firebaseConfig );
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const settings = { };
firestore.settings( settings );
// firestore.enablePersistence()
//     .then( () => {
//         // Initialize Cloud Firestore through firebase
//         firestore = firebase.firestore();
//     } )
//     .catch( ( err ) => {
//         if ( err.code === 'failed-precondition' ) {
//             // Multiple tabs open, persistence can only be enabled
//             // in one tab at a a time.
//             console.error( 'You have multiple tabs open.\nFirestore presistance only works in one tab ! \n Please close the other tabs !' );
//             // ...
//         } else if ( err.code === 'unimplemented' ) {
//             // The current browser does not support all of the
//             // features required to enable persistence
//             // ...
//             console.error( 'The current browser doesnt support persistance' );
//         }
//     } );
export {
    auth,
    firestore
};
