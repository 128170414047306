import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { load } from 'recaptcha-v3';
import fetchHelper from '../../utils/fetchHelper';

const siteKey = process.env.recaptchaSiteKey || 'recaptcha-sitekey-undefined';
const isReady = () => typeof window !== 'undefined';
let readyCheck;

class RecaptchaV3 extends PureComponent {
    constructor( props ) {
        super( props );
        this.state = {
            ready: isReady(),
        };

        if ( !this.state.ready ) {
            readyCheck = setInterval( this._updateReadyState.bind( this ), 1000 );
        }
        this.execute = this.execute.bind( this );
    }

    componentDidMount() {
        this.execute();
    }

    componentDidUpdate( prevProps, prevState ) {
        if ( this.state.ready && !prevState.ready ) {
            this.execute();
        }
    }

    componentWillUnmount() {
        clearInterval( readyCheck );
    }

    _updateReadyState() {
        if ( isReady() ) {
            this.setState( { ready: true } );
            clearInterval( readyCheck );
        }
    }

    async execute() {
        const { ready } = this.state;
        const { handleResult, action } = this.props;
        if ( ready ) {
            const recaptcha = await load( siteKey, {
                useRecaptchaNet: true,
                autoHideBadge: true
            } );
            const token = await recaptcha.execute( action );
            // Verify the token on the server.
            fetchHelper( 'verifyRecaptchaToken', 'POST', null, `{"token": "${token}" }` )
                .then( ( result ) => {
                    handleResult( result.data );
                } )
                .catch( ( err ) => {
                    handleResult( { score: 0 } );
                    console.error( err );
                } );
        }
    }

    render() {
        const { elementId } = this.props;

        return (
            <div
                id={ elementId }
                className="g-recaptcha"
            />
        );
    }
}

RecaptchaV3.defaultProps = {
    action: 'default',
    elementId: 'g-recaptcha',
};

RecaptchaV3.propTypes = {
    action: PropTypes.string,
    elementId: PropTypes.string,
    handleResult: PropTypes.func.isRequired,

};

export default RecaptchaV3;
