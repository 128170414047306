import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { applicationOperations } from '../../redux/ducks/application';
import { invitesOperations } from '../../redux/ducks/invites';
import { currentAccountOperations } from '../../redux/ducks/currentAccount';
import TableView from '../Table/Table';

let x = 0;

const columns = [
    { name: 'projectName', title: 'Project name' },
    { name: 'date', title: 'Invitation date' },
    { name: 'status', title: 'Status' },
    { name: 'actions', title: 'Actions' }
];

function composeInvitesTable( invites, updateInvite, updating ) {
    return invites.map( invite => (
        {
            projectName: invite.accountName,
            date: invite.creationDate,
            status: invite.status.capitalize(),
            actions: <div>
                { updating === invite.id
        && <span>updating ...</span>
                }
                { ( updating !== invite.id || !updating )
        && (
            <span>
                <span>
                    <button
                        style={ { marginLeft: '10px' } }
                        type="button"
                        className="btn btn-outline-info"
                        onClick={ () => {
                            updateInvite( {
                                accountId: invite.accountId,
                                inviteId: invite.id,
                                status: 'accepted'
                            } );
                        } }>
Accept
                    </button>
                </span>
                <span>
                    <button
                        style={ { marginLeft: '10px' } }
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={ () => {
                            updateInvite( {
                                accountId: invite.accountId,
                                inviteId: invite.id,
                                status: 'declined'
                            } );
                        } }>
Decline
                    </button>
                </span>
            </span>
        )
                }
            </div>
        }
    ) );
}

class InviteMessageModal extends React.Component {
    constructor() {
        super();
        this.state = {
            modal: false
        };
    }

    componentWillUnmount( ) {
        x = 0;
    }

    handleModalToggle = () => { this.setState( { modal: !this.state.modal } ); };

    updateInvite = data => {
        if ( this.state.updating ) {
          return;
        }
        this.setState( { updating: data.inviteId } );
        this.props.updateInvite( data )
            .then( () => {
              setTimeout(()=>{
                // this timeout is needed as it takes a bit of time until new data comes from DB,
                // and the button becomes visible after call complete.
                // when data comes, the modal will dissapear
                this.setState( { updating: null } );
              }, 2500) } )
            .catch( () => { this.setState( { updating: null } ); } );
    };

    render() {
        let invitesToDraw = [];
        const { currentUser } = this.props;
        if ( currentUser && currentUser.invites.having( 'status', 'pending' ).hasItems() ) {
            invitesToDraw = currentUser.invites.having( 'status', 'pending' );
            setTimeout( ( ) => { if ( x < 1 ) { this.setState( { modal: true } ); x++; } }, 500 );
        }
        const data = composeInvitesTable( invitesToDraw, this.updateInvite, this.state.updating );
        return (


            ( invitesToDraw.length > 0 )
                ? (
                    <div>
                        <Modal isOpen={ this.state.modal } toggle={ this.handleModalToggle } className="invitationModal">
                            <ModalHeader toggle={ this.handleModalToggle }>Pending invitations</ModalHeader>
                            <ModalBody>
                                <div className="customTable clickableRow">
                                    <TableView
                                        rowsDOM={ data }
                                        columns={ columns }
                                        disableSortingFor={ [ 'actions' ] }
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={ this.handleModalToggle }>Not now</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                )
                : <div /> );
    }
}

InviteMessageModal.propTypes = {
    currentUser: PropTypes.object.isRequired,
    updateInvite: PropTypes.func.isRequired,
};

const mapStateToProps = state => ( {
    accounts: state.accounts
} );

const mapDispatchToProps = dispatch => ( {
    logout: ( ) => dispatch( applicationOperations.logout( ) ),
    saveNewToken: ( data ) => dispatch( applicationOperations.saveNewToken( data ) ),
    updateInvite: ( status ) => dispatch( invitesOperations.updateInvite( status ) ),
    selectAccount: ( data ) => dispatch( currentAccountOperations.selectAccount( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( InviteMessageModal );
