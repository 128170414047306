import React from 'react';

function Footer(props) {
    const versionInfo = '[AIV]v{version} - {date}[/AIV]';

    return (
        <footer className="app-footer">
            <span><a href={ process.env.productLink }>{process.env.brand}</a> &copy; {new Date().getFullYear()} Delphisquare IPM Pty Ltd.</span>
            <span
                className="ml-auto">Powered by <a href={ process.env.productLink }>mycontracts {versionInfo}</a>
            </span>
        </footer>
    );
}

export default Footer;
