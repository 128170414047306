import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';

// This is a link to an action defined in actions.js.
const { } = actions;

const createGroup = ( { aclData, groupData } ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.storeResource( aclData, { user } ) )
        .then(
            ( res ) => {
                dispatch( actions.createGroup( { ...groupData, resources: [ res.data.resourceId ] }, { user } ) )
                    .then(
                        ( res ) => {
                            dispatch( messageOperations.showSuccess( 'Group created' ) );
                            resolve( res );
                        },
                        ( err ) => {
                            dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                            reject( err );
                        }
                    );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );
const updateGroupWithAcls = ( { aclData, groupData } ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.storeResource( aclData, { user } ) )
        .then(
            ( res ) => {
                const payload = {
                    ...groupData,
                    resources: [ ...groupData.resources, res.data.resourceId ]
                };
                delete payload.createdAt;
                delete payload.updatedAt;
                dispatch( actions.updateGroup( payload, { user } ) )
                    .then(
                        ( res2 ) => {
                            dispatch( messageOperations.showSuccess( 'Group updated' ) );
                            resolve( res2 );
                        },
                        ( err ) => {
                            dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                            reject( err );
                        }
                    );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const updateGroup = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.updateGroup( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Group updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const deleteGroup = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.deleteGroup( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Group deleted' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    createGroup,
    updateGroupWithAcls,
    deleteGroup,
    updateGroup
};
