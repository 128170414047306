import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Ability } from '@casl/ability';
import ErrorBoundary from './ErrorBoundary';
import { auth, firebase, firestore } from './firebase';

const ownerOnly = [ 'settings', 'account' ];
const excludedUserOnly = [ 'settings', 'account' ];
const RouteEnhancer = ( {
    component: Component, currentUser, currentAccount, ...rest
} ) => (
    <Route
        { ...rest }
        render={ ( props ) => {
            const NewProps = Object.assign( {}, props, {
                auth, firestore, firebase, currentUser, currentAccount
            } );

            let isUserOwner = false;
            if ( currentUser ) {
                const userAbility = new Ability( currentUser.acl );
                isUserOwner = userAbility.can( 'create', `rn::account:${currentAccount.id}::*` );
            }
            if ( excludedUserOnly.contains( props.location.pathname.split( '/' )[1] ) && ( currentUser && currentUser.isUser ) ) {
                props.history.replace( '/dashboard' );
            } else if ( ownerOnly.contains( props.location.pathname.split( '/' )[1] ) && ( currentUser && !isUserOwner ) ) {
                props.history.replace( '/dashboard' );
            }

            return (
                <ErrorBoundary><Component { ...NewProps } /></ErrorBoundary>
            );
        } }
    />
);
const { object, func } = PropTypes;
RouteEnhancer.defaultProps = {
    component: () => {},
    currentAccount: null,
    currentUser: null
};
RouteEnhancer.propTypes = {
    currentAccount: object,
    currentUser: object,
    component: object
};
const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

export default connect( mapStateToProps )( RouteEnhancer );
