import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';

const initialState = [];

const groups = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'groups' ) {
                const groups = build( action.payload.data );
                return groups;
            }
        default: return state;
    }
};

export default groups;
