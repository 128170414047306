/* eslint-disable no-use-before-define */
import uuidv1 from 'uuid/v1';
import mime from 'mime';
import types from './types';
import actions from './actions';
import messageOperations from '../messages/operations';
import documentTypesOperations from '../documentTypes/operations';
import currentAccountActions from '../currentAccount/actions';
import fetchHelper, { baseAxios } from '../../../utils/fetchHelper';
import permissions from '../../../constants/storage/permissions';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';


const deniedExtensions = permissions.deny;

const { clear } = actions;

const uploadFile = ( dispatch, file, signedUrl, id, uploadType ) => new Promise( ( ( resolve, reject ) => {
    if ( uploadType !== 'document' ) {
        return;
    }
    dispatch( { type: types.UPLOAD, file, signedUrl, id } );
    resolve();
} ) );

const uploadFileComplete = ( dispatch, file, signedUrl, id, uploadType ) => new Promise( ( ( resolve, reject ) => {
    if ( uploadType !== 'document' ) {
        dispatch( messageOperations.showSuccess( 'File upload completed' ) );
        resolve();
        return;
    }
    dispatch( { type: types.UPLOAD_COMPLETE, file, signedUrl, id } );
    resolve();
} ) );

const uploadFileProgress = ( dispatch, file, signedUrl, progress, id, uploadType ) => new Promise( ( ( resolve, reject ) => {
    if ( uploadType !== 'document' ) {
        dispatch( messageOperations.showWarning( 'File upload in progress' ) );
        resolve();
        return;
    }
    dispatch( { type: types.UPLOAD_PROGRESS, file, signedUrl, progress, id } );
    resolve();
} ) );

const uploadFileError = ( dispatch, file, error, id, uploadType ) => new Promise( ( ( resolve, reject ) => {
    if ( uploadType !== 'document' ) {
        dispatch( messageOperations.showError( `File upload ${file.name} error` ) );
        resolve();
        return;
    }
    dispatch( { type: 'UPLOAD_ERROR', file, error, id } );
    dispatch( messageOperations.showError( `File upload ${file.name} error` ) );
    resolve();
} ) );

const buildUploadQueue = ( files, newDoc = false ) => ( dispatch, getState ) => {
    new Promise( ( ( resolve, reject ) => {
        let extendedFiles = files;
        if ( !newDoc ) {
            const { selectedDocument } = getState().documents;
            // enrich each file with document and account id to whom it belongs.
            extendedFiles = files.map( file => ( { file, accountId: selectedDocument.accountId, documentId: selectedDocument.id, id: uuidv1(), completed: false } ) );
        }
        dispatch( actions.buildUploadQueue( extendedFiles ) );
        resolve();
    } ) );
};

const uploadDocTypeTemplate = ( data ) => ( dispatch, getState ) => {
    upload( { ...data, dispatch, id: uuidv1() } )
        .then( () => {
            const { docId } = data;
            const selectedDocument = getState().documentTypes.list.having( 'id', docId )[0];
            const payload = {
                doc: { ...selectedDocument, hasTemplate: true },
                accountId: selectedDocument.accountId };

            dispatch( documentTypesOperations.saveDocument( payload ) );
        } )
        .catch( () => {

        } );
};

const uploadCategory = ( data ) => async ( dispatch, getState ) => {
    let hasErrors = false;
    await uploadDefaultCategory( { ...data.catData, dispatch } )
        .catch( ( err ) => {
            console.error( 'there was a problem uploading default cat' );
            hasErrors = true;
        } );
    if ( hasErrors ) {
        return;
    }
    await dispatch( currentAccountActions.updateAccount(
        {
            ...data.currentAccount,
            defaultCat: data.catData.filename,
            accountId: data.catData.accountId }
    ) )
        .catch( ( err ) => dispatch( messageOperations.showError( displayErrorFromServer( err ) ) ) );
    dispatch( messageOperations.showSuccess( 'Account updated' ) );
};

const uploadAccountLogo = ( data ) => async ( dispatch, getState ) => {
    let hasErrors = false;
    await uploadImage( { ...data, dispatch } )
        .catch( ( err ) => {
            console.error( 'there was a problem uploading default cat' );
            hasErrors = true;
        } );
    if ( hasErrors ) {
        return;
    }
};

const triggerUpload = ( action = null ) => async ( dispatch, getState ) => {
    const { queue } = getState().uploads;
    if ( queue.isEmpty() ) {
        return;
    }
    dispatch( { type: types.UPLOAD_STARTED } );
    for ( const fileData of queue ) {
        const { file, accountId, documentId, id } = fileData;
        const selectedDocument = getState().documents.list.having( 'id', documentId )[0];
        const categoryNode = selectedDocument.formData.location;
        if ( isDeniedExtension( file ) ) {
            uploadFileError( dispatch, file, 'File extension is not permitted !' );
            return console.error( `File upload ${file.name} error! File extension is not permitted !` );
        }
        const filename = `/${accountId}/${documentId}/${file.name}`;
        // eslint-disable-next-line no-await-in-loop
        await upload( { filename, file, dispatch, id, accountId, categoryNode, action } );
    }
};

export default {
    uploadAccountLogo,
    triggerUpload,
    uploadFile,
    uploadFileProgress,
    uploadFileComplete,
    uploadFileError,
    clear,
    buildUploadQueue,
    uploadDocTypeTemplate,
    uploadCategory
};

const isDeniedExtension = ( file ) => {
    const extSplit = file.name.split( '.' );
    if ( extSplit.length < 2 ) return false;

    const ext = extSplit[extSplit.length - 1];

    return deniedExtensions.having( 'extension', ext ).length > 0;
};


function upload( { filename, file, dispatch, id, uploadType = 'document', accountId, categoryNode, action } ) {
    return new Promise( ( ( resolve, reject ) => {
        fetchHelper( 'requestUploadUrl', action || 'post', {}, {
            filename,
            uploadType,
            accountId,
            categoryNode
        } )
            .then( ( result ) => {
                const signedUrl = result.data.url;
                uploadFile( dispatch, file, signedUrl, id, uploadType );
                const type = mime.getType( file.name );
                const options = {
                    headers: {
                        'Content-Type': type,
                    },
                    onUploadProgress( progressEvent ) {
                        const progress = Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total );
                        uploadFileProgress( dispatch, file, signedUrl, progress, id, uploadType );
                    }
                };
                return baseAxios()
                    .put( signedUrl, file, options )
                    .then( res => { uploadFileComplete( dispatch, file, signedUrl, id, uploadType ); resolve(); } )
                    .catch( err => { uploadFileError( dispatch, file, signedUrl, id, uploadType ); reject(); } );
            } )
            .then( ( result ) => {
                resolve();
            } )
            .catch( ( err ) => {
                console.error( err );
                reject();
                uploadFileError( dispatch, file, err, uploadType );
            } );
    } ) );
}

function uploadDefaultCategory( { filename, file, uploadType = 'category', accountId, dispatch } ) {
    return new Promise( ( ( resolve, reject ) => {
        fetchHelper( 'requestUploadUrl', 'post', {}, {
            filename,
            uploadType,
            accountId
        } )
            .then( ( result ) => {
                const signedUrl = result.data.url;
                let type = '';
                if ( file.type !== '' ) {
                    type = 'application/json';
                }
                const options = {
                    headers: {
                        'Content-Type': type,
                    },
                    onUploadProgress( progressEvent ) {
                        const progress = Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total );
                        uploadFileProgress( dispatch, file, signedUrl, progress, uploadType );
                    }
                };
                return baseAxios()
                    .put( signedUrl, file, options )
                    .then( res => {
                        uploadFileComplete( dispatch, file, signedUrl, uploadType ); resolve();
                    } )
                    .catch( err => { uploadFileError( dispatch, file, signedUrl, uploadType ); reject(); } );
            } )
            .then( ( result ) => {
                resolve();
            } )
            .catch( ( err ) => {
                console.error( err );
                reject();
                uploadFileError( dispatch, file, err, uploadType );
            } );
    } ) );
}

function uploadImage( { filename, file, uploadType = 'accountLogo', accountId, dispatch } ) {
    return new Promise( ( ( resolve, reject ) => {
        fetchHelper( 'requestUploadUrl', 'post', {}, {
            filename,
            uploadType,
            accountId
        } )
            .then( ( result ) => {
                const signedUrl = result.data.url;
                const { type } = file;
                const options = {
                    headers: {
                        'Content-Type': type,
                    },
                    onUploadProgress( progressEvent ) {
                        const progress = Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total );
                        uploadFileProgress( dispatch, file, signedUrl, progress, uploadType );
                    }
                };
                return baseAxios()
                    .put( signedUrl, file, options )
                    .then( res => {
                        uploadFileComplete( dispatch, file, signedUrl, uploadType ); resolve();
                    } )
                    .catch( err => { uploadFileError( dispatch, file, signedUrl, uploadType ); reject(); } );
            } )
            .then( ( result ) => {
                resolve();
            } )
            .catch( ( err ) => {
                console.error( err );
                reject();
                uploadFileError( dispatch, file, err, uploadType );
            } );
    } ) );
}
