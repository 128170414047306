import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button,
    Input,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';
import { connect } from 'react-redux';
import applicationOperations from '../../../redux/ducks/application/operations';
import RecaptchaV3 from '../../../components/Recaptcha/RecaptchaV3';
import Display from '../../../components/Display';
import passwordStrengthMeter from '../../../utils/passwordStrengthMeter';
import PasswordInputWithStrength from '../../../components/PasswordStrengthInput/PasswordInputWithStrength';

const versionInfo = '[AIV]v{version} - {date}[/AIV]';

class Register extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            modalOpen: true,
            resetState: '',
            disabled: true,
            error: false,
            emailAdded: false,
            passwordAdded: false,
            email: '',
            recaptchaScore: 0
        };
        this.history = this.props.history;
        this.handleOpen = () => this.setState( { modalOpen: true } );
        this.handleClose = () => this.setState( { modalOpen: false } );
        this.handleChange = this.handleChange.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleResult = this.handleResult.bind( this );
    }

    componentDidMount() {
        setTimeout( () => {
            if ( window.location.search.length > 0 && this.state.email === '' && window.location.search.contains( 'email' ) ) {
                this.setState( { email: window.location.search.split( 'email=' )[1], emailAdded: true } );
            }
        }, 5000 );
    }

  handleOpen = () => this.setState( { modalOpen: true } );

  handleChange = ( e ) => {
      if ( e.target.name === 'email' && e.target.value !== '' ) {
          this.setState( { emailAdded: true } );
      }
      if ( e.target.name === 'password' && e.target.value !== '' ) {
          this.setState( { passwordAdded: true, disabled: false } );
      }
      if ( this.state.passwordAdded && this.state.emailAdded ) {
          this.setState( { disabled: false } );
      }
      if ( e.target.name === 'email' && e.target.value === '' ) {
          this.setState( { emailAdded: false, disabled: true } );
      }
      if ( e.target.name === 'password' && e.target.value === '' ) {
          this.setState( { passwordAdded: false, disabled: true } );
      }
      this.setState( { [e.target.name]: e.target.value, } );
  };

  handleClose = () => {
      this.setState( { modalOpen: false } );
      this.history.push( '/' );
  };

  handleResult = ( result ) => {
      // console.info('RecaptchaVerify Result:', result);
      this.setState( { recaptchaScore: result.score } );
  };

  handleSubmit = () => {
      this.setState( { submitting: true, error: null, errorCode: null, resetState: 'registering ...' } );
      const { password, email } = this.state;
      const resp = passwordStrengthMeter.test( password );
      if ( resp.errors.length > 0 ) {
          this.setState( {
              errorMessage: resp.errors.map( error => <span style={ { color: 'red', display: 'block' } }>{error}</span> ),
              error: true,
              submitting: false,
              resetState: '' } );
          return;
      }
      this.setState( { submittedPassword: password, submittedEmail: email.toLocaleLowerCase().trim(), errorMessage: '', error: false } );
      const self = this;
      this.props.auth.createUserWithEmailAndPassword( this.state.email.toLocaleLowerCase().trim(), this.state.password ).then( () => {
          self.setState( {
              success: true,
              errorMessage: '',
              error: false,
              resetState: 'Registration successful ! Sending Email verification ...'
          } );
          self.setState( { submitting: false } );
          this.props.displayRegisteredMessage();
          setTimeout( () => { this.props.history.replace( '/login' ); }, 5000 );
      } ).catch( ( error ) => {
          self.setState( { errorMessage: error.message, success: false, error: true, errorCode: error.code, resetState: '' } );
          self.setState( { submitting: false } );
      } );
  };

  render() {
      const { errorMessage } = this.state;
      return (
          <div className="app flex-row align-items-center">
              <Container>
                  <Display when={ process.env.host !== 'http://localhost:8080/' }>
                      <RecaptchaV3 action="Register" handleResult={ this.handleResult } />
                  </Display>
                  <Row className="justify-content-center">
                      <Col md="5">
                          <img
                              style={ { marginBottom: '50px', display: 'block', height: 'auto', width: 'auto' } }
                              src="/img/mycontracts-logo-3-long-v3.svg"
                              alt="mycontracts_logo" />
                      </Col>
                  </Row>
                  <Display when={ this.state.recaptchaScore > 0.5 || process.env.host === 'http://localhost:8080/' }>
                      <Row className="justify-content-center">
                          <Col md="6">
                              <Card className="mx-4">
                                  {/* <CardImg top width="100%" src="/img/mycontracts-concept.png" alt="mycontracts_logo" /> */}
                                  <CardBody className="p-4">
                                      <h1>Register</h1>
                                      <p className="text-muted">Create your account</p>
                                      <InputGroup className="mb-3">
                                          <InputGroupAddon addonType="append"><i className="icon-email">@</i></InputGroupAddon>
                                          <Input
                                              className="insideIcon"
                                              name="email"
                                              autoComplete="new-password"
                                              type="email"
                                              placeholder="your.email.com"
                                              value={ this.state.email }
                                              style={ this.state.error ? { borderColor: 'red!important' } : {} }
                                              onChange={ this.handleChange } />
                                      </InputGroup>
                                      <PasswordInputWithStrength
                                          onChange={ ( value ) => { this.handleChange( { target: { name: 'password', value } } ); } }
                                          error={ this.state.error } />
                                      <Row>
                                          {this.state.resetState
                      && (
                          <Col xs="12">
                              <p style={ { color: 'blue' } }>{this.state.resetState}</p>
                          </Col>
                      )
                                          }
                                          {errorMessage
                      && (
                          <Col xs="12">
                              <p style={ { color: 'red' } }>{errorMessage}</p>
                          </Col>
                      )
                                          }

                                          <Col xs="12" className="mt-3">
                                              <Button
                                                  className="text-white bg-primary"
                                                  block
                                                  onClick={ this.handleSubmit }
                                                  disabled={ this.state.disabled }>Create Account
                                              </Button>
                                          </Col>
                                      </Row>
                                  </CardBody>
                                  <CardFooter className="bg-white py-5" style={ { margin: '44px' } }>
                                      <Row>
                                          <Col xs="12" sm="6">
                                              <a className="text-primary" href="/login">Did you mean to login?</a>
                                          </Col>
                                      </Row>
                                  </CardFooter>
                              </Card>
                              <div className="p-1 text-secondary text-center" style={ { marginTop: 20 } }>{versionInfo}</div>
                          </Col>
                      </Row>
                  </Display>
                  <Display when={ this.state.recaptchaScore <= 0.5 }>
                      <div />

                  </Display>

              </Container>
          </div>
      );
  }
}

const mapDispatchToProps = dispatch => ( {
    displayRegisteredMessage: () => dispatch( applicationOperations.displayRegisteredMessage() ),
} );

export default connect( null, mapDispatchToProps )( Register );
