export const build = ( data ) => {
    if ( data.empty ) {
        return [];
    }
    const array = [];
    data.forEach( ( doc ) => {
        array.push( { ...doc.data(), id: doc.id } );
    } );
    return array;
};
