import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import fetchHelper, { baseAxios } from '../../utils/fetchHelper';
import { versionOperations } from '../../redux/ducks/version';
import TooltipWrapper from '../Tooltip/Tooltip';
import DiffView from './DiffView';
import Display from '../Display';

class Versions extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            loading: true,
            showDiffModal: false,
            showConfirmModal: false,
            selectedVersion: {},
            selectedVersionNumber: null,
            currentVersionNumber: null,
            history: []
        };
        this.toggleDiffView = this.toggleDiffView.bind( this );
        this.buildVersions = this.buildVersions.bind( this );
        this.onFileClick = this.onFileClick.bind( this );
        this.toggleConfirmation = this.toggleConfirmation.bind( this );
        this.executeRestore = this.executeRestore.bind( this );
    }

    UNSAFE_componentWillMount() {
        this.setState( { loading: true } );
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.setState( { history: nextProps.activity } );
    }

    toggleDiffView( selectedVersionNumber = null ) {
        this.setState( { showDiffModal: !this.state.showDiffModal, selectedVersionNumber, currentVersionNumber: this.props.versions.data.length } );
    }

    buildVersions( collection ) {
        const total = this.props.versions.data.length;
        return this.props.versions.data.map( ( version, index ) => (
            <li key={ Math.random() }>
                <span style={ { fontSize: 13, margin: 0 } }>{`v.${total - index} from ${moment( version.versionTimestamp ).format( 'DD/MM/YY HH:mm' )}`}</span>
                <Display when={ index !== 0 }>
                    <Display when={ collection !== 'filesMeta' }>
                        <Badge
                            style={ { cursor: 'pointer', marginLeft: 10, float: 'right' } }
                            onClick={ () => { this.setState( { selectedVersion: version } ); this.toggleDiffView( total - index ); } }>diff
                        </Badge>
                    </Display>
                    <Display when={ collection === 'filesMeta' }>
                        <React.Fragment>
                            <TooltipWrapper refId={ `entityTitle-${version.id.substr( 1, 8 )}${index}` } content="restore" />
                            <Badge
                                id={ `entityTitle-${version.id.substr( 1, 8 )}${index}` }
                                style={ { cursor: 'pointer', marginLeft: 10, float: 'right' } }
                                onClick={ () => { this.setState( { selectedVersion: version, selectedVersionNumber: total - index } ); this.toggleConfirmation(); } }>
                                <i className="fa fa-undo" />
                            </Badge>
                            <TooltipWrapper refId={ `entityTitle2-${version.id.substr( 1, 8 )}${index}` } content="download" />
                            <Badge
                                id={ `entityTitle2-${version.id.substr( 1, 8 )}${index}` }
                                style={ { cursor: 'pointer', marginLeft: 10, float: 'right' } }
                                onClick={ () => { this.onFileClick( version ); } }>
                                <i className="fa fa-download" aria-hidden="true" />
                            </Badge>
                        </React.Fragment>
                    </Display>
                </Display>
                { index === 0 ? <p style={ { fontSize: 10, margin: 0 } }>Current version</p> : <script />}
                <p style={ { fontSize: 10, margin: 0, display: 'inline-block', position: 'relative', top: -5 } }>{`Edit by: ${version.userEmail}`}</p>
            </li>

        ) );
    }

    async executeRestore( ) {
        if ( this.state.callInProgress ) {
            return;
        }
        this.setState( { callInProgress: true, showConfirmModal: false } );
        const { restore, versions, restoreFile } = this.props;
        const { selectedVersion } = this.state;
        const { element } = versions;
        const { collection } = versions;
        const body = {
            accountId: element.accountId,
            versionId: selectedVersion.versionId,
            elementId: element.id,
            collection,
        };
        await restore( body )
            .catch( err => { this.setState( { callInProgress: false, selectedVersionNumber: null } ); } );

        setTimeout( () => {
            if ( collection !== 'filesMeta' ) {
                this.toggleDiffView();
            }
            document.body.classList.add( 'aside-menu-hidden' );
            this.setState( { callInProgress: false } );
        }, 500 );
    }

    toggleConfirmation( ) {
        if ( this.state.callInProgress ) {
            return;
        }
        this.setState( { showConfirmModal: !this.state.showConfirmModal } );
    }

    onFileClick( file, requestPdf = false ) {
        const fileData = file;
        let fileName;
        let type = '';
        if ( requestPdf ) {
            fileName = `${file.filePath}.pdf`;
            type = 'application/pdf';
        } else {
            fileName = file.filePath;
        }

        fetchHelper( 'requestDownloadUrl', 'POST', null, {
            filename: `/${file.filePath}`,
            generation: file.version,
            pdf: requestPdf,
            accountId: file.accountId,
            categoryNode: file.categoryNode
        } )
            .then( ( result ) => {
                baseAxios()( {
                    url: `${result.data.downloadUrl}&alt=media`,
                    method: 'GET',
                    responseType: 'blob', // important
                } ).then( ( response ) => {
                    const url = window.URL.createObjectURL( new Blob( [ response.data ] ) );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    // link.setAttribute( 'download', fileName.split( '/' )[fileName.split( '/' ).length - 1] );
                    link.setAttribute( 'download', fileData.fileName );
                    document.body.appendChild( link );
                    link.click();
                } );
            } )
        // next then()
            .catch( ( err ) => {
                console.log( err );
            } );
    }

    render() {
        const { showDiffModal, selectedVersion, showConfirmModal, callInProgress, currentVersionNumber, selectedVersionNumber } = this.state;
        const { versions } = this.props;
        let versionsArray = [];
        let element = {};
        let collection = '';
        let name = 'fetching data ...';
        // for files, when display div the last version is the element (current one)
        // we could do the same for documents and bypass some of the bugs we have now ...
        if ( versions && typeof versions.data !== 'undefined' ) {
            versionsArray = versions.data;
            element = versions.element;
            collection = versions.collection;
            name = extractName( versionsArray[0], collection );
        }
        return (
            <React.Fragment>
                <div
                    className="callout m-0 py-2 text-muted text-center bg-light pointer"
                    onClick={ () => { document.body.classList.toggle( 'aside-menu-hidden' ); } }
                >
                    <small className="text-uppercase"><b>{extractType( collection )} Versions for </b></small>
                    <small style={ { float: 'right', top: 3, position: 'relative' } }><b>x</b></small>
                    <TooltipWrapper refId={ `entityTitle-${element.id}` } content={ name } />
                    <div id={ `entityTitle-${element.id}` } className="truncate" style={ { width: '100%', textAlign: 'center' } }>{name}</div>
                </div>
                <hr className="transparent mx-3 my-0" />
                <Display when={ !versions }>
                    <div className="eventsPlaceholder">loading ...</div>
                </Display>
                <Display when={ versionsArray.isEmpty() && versions }>
                    <div className="eventsPlaceholder">No data</div>
                </Display>
                { showDiffModal
              && (
                  <DiffView
                      showDiff={ showDiffModal }
                      callInProgress={ callInProgress }
                      allowRestore={ collection !== 'filesMeta' && collection !== 'calendarEvents' }
                      oldData={ buildSummary( element, collection ) }
                      newData={ buildSummary( selectedVersion, collection ) }
                      selectedVersionNumber={ selectedVersionNumber }
                      currentVersionNumber={ currentVersionNumber }
                      close={ this.toggleDiffView }
                      action={ this.toggleConfirmation }
                  />
              )
                }

                <ConfirmationModal
                    toggle={ this.toggleConfirmation }
                    open={ showConfirmModal }
                    action={ this.executeRestore }
                    message={ `Are you sure you want to restore to version ${selectedVersionNumber} ?` }
                />

                <Display when={ versionsArray.hasItems() }>
                    {versionsArray.hasItems()
                        ? <ul className="versionList">{this.buildVersions( collection )}</ul>
                        : <div className="eventsPlaceholder">No Activity</div>}
                </Display>
            </React.Fragment>
        );
    }
}

const { string, object } = PropTypes;

Versions.propTypes = {
    accountId: string.isRequired,
    collection: string.isRequired,
    element: object.isRequired
};

const mapStateToProps = state => ( {
    versions: state.versions,
    asideData: state.application.aside
} );

const mapDispatchToProps = dispatch => ( {
    getVersions: ( data ) => dispatch( versionOperations.getVersions( data ) ),
    restoreFile: ( data ) => dispatch( versionOperations.restoreFile( data ) ),
    restore: ( data ) => dispatch( versionOperations.restore( data ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( Versions );

function buildSummary( entity, collection ) {
    switch ( collection ) {
        case 'documents': return buildDocSummary( entity );
        case 'filesMeta': return buildFileSummary( entity );
        case 'calendarEvents': return calendarEventSummary( entity );
        case 'parties': return partiesSummary( entity );
        default: return '';
    }
}
function buildDocSummary( document ) {
    const fieldsWithValues = document.schema.order.filter( x => Object.keys( document.formData ).contains( x ) );
    if ( document.parties.hasItems() ) {
        fieldsWithValues.unshift( 'parties' );
    }
    const summary = [];
    fieldsWithValues.map( key => {
        let value = '';
        let label = '';
        if ( key === 'parties' ) {
            value = document.parties.map( party => `${party.label} defined as ${party.as}` ).join( ' and ' );
            label = 'Parties';
        } else if ( key === 'newField' ) {
            value = '';
            label = 'newField';
        } else if ( typeof document.formData[key] !== 'undefined' ) {
            value = document.formData[key];
            label = document.schema.properties[key].title;
        } else {
            value = '';
            label = typeof document.schema.properties.having( 'id', key )[0] !== 'undefined' ? document.schema.properties.having( 'id', key )[0].title : '';
        }
        const type = document.schema.properties[key];
        if ( typeof type !== 'undefined' ) {
            if ( type.optionType.toLocaleLowerCase() === 'pid' ) {
                value = moment( value ).format( 'DD MMM YYYY' );
            }
            if ( type.optionType.toLocaleLowerCase() === 'pip' ) {
                const obj = JSON.parse( value );
                value = `${moment( obj.start ).format( 'DD MMM YYYY' )} - ${moment( obj.end ).format( 'DD MMM YYYY' )}`;
            }
        }

        summary.push( `${label}: ${value} \n` );
    } );
    // return summary.sort( sortSummaryFields ).join( '' );
    return summary.join( '' );
}
function buildFileSummary( fileEntry ) {
    const summary = [];
    const displayOnly = [ 'categoryNode', 'fileName' ];
    Object.keys( fileEntry ).map( key => {
        if ( !displayOnly.contains( key ) ) {
            return;
        }
        if ( key === 'uploaded' ) {
            summary.push( `${prettyName( key )}: ${moment.utc( fileEntry[key] ).format( 'DD/MM/YYYY HH:mm A' )} \n` );
        } else {
            summary.push( `${prettyName( key )}: ${fileEntry[key]} \n` );
        }
    } );
    // return summary.sort( sortSummaryFields ).join( '' );
    return summary.join( '' );
}
function calendarEventSummary( fileEntry ) {
    const summary = [];
    const displayOnly = [ 'categoryNode', 'name', 'date', 'priority', 'resolved', 'reason' ];
    Object.keys( fileEntry ).map( key => {
        if ( !displayOnly.contains( key ) ) {
            return;
        }
        if ( key === 'reason' && fileEntry.userId === 'roboJobMycontractsCloud' ) {
            return;
        }
        if ( key === 'date' ) {
            summary.push( `${prettyName( key )}: ${moment.utc( fileEntry[key] ).format( 'DD/MM/YYYY HH:mm A' )} \n` );
        } else if ( key === 'resolved' ) {
          summary.push( `${prettyName( key )}: ${ fileEntry[key] ? "Yes" : "No" } \n` );
        } else {
            summary.push( `${prettyName( key )}: ${fileEntry[key]} \n` );
        }
    } );

    // return summary.sort( sortSummaryFields ).join( '' );
    return summary.join( '' );
}

function partiesSummary( fileEntry ) {
    const summary = [];
    const displayExcept = [ 'accountId', 'id', 'versionTimestamp', 'updatedAt', 'versionId', 'userId', 'userIp', 'userEmail', 'reason' ];
    Object.keys( fileEntry ).map( key => {
        if ( displayExcept.contains( key ) ) {
            return;
        }
        if ( key === 'updatedAt' ) {
            summary.push( `${prettyName( key )}: ${moment.utc( fileEntry[key] ).format( 'DD/MM/YYYY HH:mm A' )} \n` );
        } else {
            summary.push( `${prettyName( key )}: ${prettyValue( fileEntry[key] )} \n` );
        }
    } );
    // return summary.sort( sortSummaryFields ).join( '' );
    return summary.join( '' );
}
function prettyValue( value ) {
    if ( !value ) {
        return '-';
    }
    return value;
}
function sortSummaryFields( a, b ) {
    if ( a.split( ':' )[0].toLocaleLowerCase() < b.split( ':' )[0].toLocaleLowerCase() ) { return -1; }
    if ( a.split( ':' )[0].toLocaleLowerCase() > b.split( ':' )[0].toLocaleLowerCase() ) { return 1; }
    return 0;
}
function extractName( element, collection ) {
    if ( typeof element === 'undefined' ) { return ''; }
    switch ( collection ) {
        case 'documents': return element.formData.title;
        case 'filesMeta': return element.fileName;
        case 'calendarEvents': return element.name;
        default: return '';
    }
}
function extractType( collection ) {
    switch ( collection ) {
        case 'documents': return 'doc';
        case 'filesMeta': return 'file';
        default: return '';
    }
}
function prettyName( key ) {
    switch ( key ) {
        case 'categoryNode': return 'location';
        case 'fileName': return 'name';
        case 'partyName': return 'party name';
        case 'partyType': return 'party type';
        case 'postCode': return 'zip code';
        case 'contactPerson': return 'contact person';
        case 'contactPersonEmail': return 'contact person email';
        case 'contactPersonPhone': return 'contact person phone';
        default: return key;
    }
}
