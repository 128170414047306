import React from 'react';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';

import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown
} from 'reactstrap';
import { applicationOperations } from '../../redux/ducks/application';
import Display from '../Display';
import { sortArrayByProperty } from '../../utils/sortArrayByProperty';

function buildAccountselectionOptions( accounts ) {
    return accounts.sort( ( a, b ) => sortArrayByProperty( a, b, 'accountName' ) ).map( account => <option key={ Math.random() } value={ account.id }>{ account.accountName }</option> );
}

class HeaderDropdown extends React.Component {
    constructor( props ) {
        super( props );
        this.history = this.props.history;
        this.state = {
            dropdownOpen: false
        };
        this.login = this.login.bind( this );
        this.signup = this.signup.bind( this );
        this.logout = this.logout.bind( this );
        this.goToAccount = this.goToAccount.bind( this );
    }

    UNSAFE_componentWillMount() {
        this.props.auth.onAuthStateChanged( ( user ) => {
            if ( user ) {
                this.props.refreshToken( user );
            } else if ( typeof document !== 'undefined' ) {
                this.props.history.replace( '/login' );
            }
        } );
        if ( Object.keys( this.props.currentAccount ).hasItems() ) {
            this.setState( { selectedAccount: this.props.currentAccount.id } );
        }
        this.triggerRefreshToken();
    }

    triggerRefreshToken() {
        if ( this.props.auth.currentUser ) {
            this.props.refreshToken( this.props.auth.currentUser );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( nextProps.currentUser && this.props.currentUser && nextProps.currentUser.email !== this.props.currentUser.email ) {
            // logout is required because references needs to be made again with correct email
            // TODO: check if its not possible to resubscribe to Firestore in Full.js
            this.logout();
            return;
        }
        if ( this.props.currentUser !== nextProps.currentUser ) {
            this.setState( { user: nextProps.currentUser } );
        }
        if ( Object.keys( this.props.currentAccount ).isEmpty() && this.props.currentUser ) {
            this.props.selectAccount( nextProps.accounts[0] );
        }
    }

    componentWillUnmount() {
        clearInterval( this.state.intervalId );
    }

  login = () => this.history.push( '/login' );

  signup = () => this.history.push( '/signup' );

  goToSettings = () => this.history.push( '/settings' );

  goToAccount = () => this.history.push( '/account' );

  gotToGroups = () => this.history.push( '/groups' );

  goToProfile = () => this.history.push( '/profile' );

  logout = () => {
      const that = this;
      this.props.auth.signOut().then( () => {
      // Sign-out successful.
          if ( typeof document !== 'undefined' ) {
              localStorage.removeItem( 'currentUserId' );
              localStorage.removeItem( 'sessionId' );
          }
          that.props.logout();
          setTimeout( () => {
              // give a bit of time for data to propagate;
              if ( typeof document !== 'undefined' ) {
                  this.props.history.replace( '/login' );
              }
          }, 300 );

      // this.history.replace( '/login' );
      }, ( error ) => {
          console.error( error );
      } );
  };

  toggle = () => {
      this.setState( {
          dropdownOpen: !this.state.dropdownOpen
      } );
  };

  handleInputChange = event => {
      this.setState( {
          selectedAccount: event.target.value,
      } );
      this.props.selectAccount( this.props.accounts.having( 'id', event.target.value )[0] );
      setTimeout( () => {
          this.setState( { dropdownOpen: false } );
      }, 600 );
  };

  render() {
      const { accounts, selectAccount, currentUser } = this.props;
      return (
          <div>
              <Dropdown isOpen={ this.state.dropdownOpen } toggle={ this.toggle } className="dropdownContainer">
                  <DropdownToggle caret color="white">
                      <div className="avatar" style={ { marginRight: '5px' } }>
                          { currentUser
              && (
                  <div>
                      <Display when={ currentUser.photoURL === null }>
                          <Gravatar
                              email={ this.state.user ? this.state.user.email : 'anonymous@anon.ous' }
                              className="img-avatar" />
                      </Display>
                      <Display when={ currentUser.photoURL !== null }>
                          <img style={ { maxHeight: 36, borderRadius: '50%' } } src={ currentUser.photoURL } />
                      </Display>
                  </div>
              )
                          }

                      </div>
                      <span className="userEmail">
                          { this.state.user ? this.state.user.email : 'Login' }
                      </span>
                  </DropdownToggle>
                  <DropdownMenu right>
                      { this.state.user ? (
                          <div>
                              { accounts.hasItems()
                && (
                    <div>
                        <DropdownItem header tag="div" className="text-center"><strong>Account selection</strong></DropdownItem>
                        <select
                            className="form-control accountSelect"
                            value={ this.state.selectedAccount }
                            onChange={ this.handleInputChange }>
                            { buildAccountselectionOptions( accounts, selectAccount ) }
                        </select>
                    </div>
                )
                              }
                              <Display when={ this.props.currentUser && ( this.props.currentUser.isAdmin || this.props.currentUser.isOwner ) }>
                                  <React.Fragment>
                                      <DropdownItem header tag="div" className="text-center"><strong>General</strong></DropdownItem>
                                      <div>
                                          <DropdownItem onClick={ this.goToSettings } id="docConfigBtn"><i className="fa fa-gears"></i>
                    Configuration
                                          </DropdownItem>
                                      </div>
                                  </React.Fragment>
                              </Display>
                              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
                              <Display when={ this.props.currentUser && ( this.props.currentUser.isAdmin || this.props.currentUser.isOwner ) }>
                                  <DropdownItem onClick={ this.goToAccount } id="accountConfigBtn"><i className="fa fa-wrench"></i>
                  Accounts
                                  </DropdownItem>
                              </Display>
                              <DropdownItem onClick={ this.gotToGroups } id="accountConfigBtn"><i className="fa fa-wrench"></i>
                  Groups
                              </DropdownItem>
                              <DropdownItem onClick={ this.goToProfile } id="profileBtn"><i className="fa fa-user"></i> Profile</DropdownItem>
                              { /* <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem> */ }
                              {/* <DropdownItem divider /> */}
                              <DropdownItem id="logoutBtn" onClick={ this.logout }><i className="fa fa-lock"></i> Logout</DropdownItem>
                          </div>
                      )
                          : <DropdownItem onClick={ this.login }><i className="fa fa-key"></i> Login</DropdownItem>
                      }
                      <DropdownItem
                          header
                          tag="div"
                          className="text-center">
                          <div style={ { display: 'flex', justifyContent: 'space-between', color: '#1030a1', minWidth: 210 } }>
                              <div
                                  onClick={ () => { this.props.history.push( 'privacy-policy' ); } }
                                  style={ { fontSize: 13, color: '#1030a1', cursor: 'pointer' } }>Privacy policy
                              </div>{' '}
                              <div
                                  onClick={ () => { this.props.history.push( 'terms-and-conditions' ); } }
                                  style={ { fontSize: 13, color: '#1030a1', cursor: 'pointer' } }>Terms & Conditions
                              </div>

                          </div>

                          <strong>{ '[AIV]v{version} - {date}[/AIV]' }</strong>
                      </DropdownItem>
                  </DropdownMenu>
              </Dropdown>
          </div>
      );
  }
}

HeaderDropdown.propTypes = {
    history: PropTypes.object.isRequired,
    accounts: PropTypes.array.isRequired,
    selectAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    refreshToken: ( data ) => dispatch( applicationOperations.refreshToken( data ) )
} );
export default connect( mapStateToProps, mapDispatchToProps )( HeaderDropdown );
