import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Display from '../Display';
import DateSelector from '../Date/DateSelector';
import Timepicker from '../Timepicker/Timepicker';
import Repeater from '../Repeater/Repeater';
import Switch from '../Repeater/Switch';
import GroupSelector from '../GroupSelector/GroupSelector';
import buildRule from '../../utils/buildRule';

class DateSelectorWrapper extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            selectedDate: typeof props.formData !== 'undefined' ? props.formData : '',
            selection: 'none',
            note: '',
            displayRepeat: false,
            repeatError: false,
            repeatData: {},
            groups: [],
            dueTime: {
                hour: 0,
                minutes: 0
            },
            time: new Date( new Date( new Date().setHours( 0 ) ).setMinutes( 0 ) )
        };
        this.toggleRepeat = this.toggleRepeat.bind( this );
        this.repeaterCallback = this.repeaterCallback.bind( this );
        this.changeEventPriority = this.changeEventPriority.bind( this );
        this.timeCallback = this.timeCallback.bind( this );
        this.onChange = this.onChange.bind( this );
        this.selectGroup = this.selectGroup.bind( this );
        this.createCalendarEvent = this.createCalendarEvent.bind( this );
        this.saveNote = this.saveNote.bind( this );
        this.removeGroup = this.removeGroup.bind( this );
    }

    onChange( dateObj ) {
        const innerMoment = dateObj;
        this.setState( {
            selectedDate: dateObj.toISOString()
        } );
        this.props.onChange( innerMoment.toISOString() );
        if ( this.state.selection !== 'none' && this.state.selection !== 'nope' ) {
            setTimeout( () => { this.createCalendarEvent( dateObj.toISOString() ); }, 500 );
        }
    }

    UNSAFE_componentWillMount() {
        const state = Object.assign( {}, this.state );
        if ( this.props.uiSchema['ui:field'] === 'dateSelect' ) {
            let eventsToBeCreated = {};
            if ( this.props.extra ) {
                eventsToBeCreated = this.props.extra.eventsToBeCreated;
            }
            if ( Object.keys( eventsToBeCreated ).hasItems() ) {
                const currentEvent = eventsToBeCreated[`root_${this.props.name}`];
                if ( typeof currentEvent !== 'undefined' ) {
                    state.selection = currentEvent.priority;
                    state.repeatData = currentEvent.repeatData;
                    state.displayRepeat = currentEvent.repeatData.repeat || false;
                    const d = new Date();
                    let time = { hour: 0, minutes: 0 };
                    if ( typeof currentEvent.dueTime !== 'undefined' ) {
                        time = currentEvent.dueTime;
                    }
                    d.setHours( Number( time.hour ) );
                    d.setMinutes( Number( time.minutes ) );
                    state.time = d;
                }
            }
        }
        if ( typeof this.props.formData !== 'undefined' ) {
            state.selectedData = this.props.formData;
        }
        this.setState( { ...state } );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( typeof nextProps.formData !== 'undefined' ) {
            this.setState( { selectedDate: this.props.formData } );
        }
    }

    changeEventPriority( e ) {
        const newVal = e.target.value;
        this.setState( {
            selection: newVal
        } );
        if ( newVal !== 'none' && newVal !== 'nope' ) {
            setTimeout( () => {
                this.createCalendarEvent();
            }, 500 );
        }
    }

    createCalendarEvent( date = null ) {
        const selectedData = date || this.state.selectedDate;
        const data = {};
        const groups = {};
        this.state.groups.map( group => groups[group] = true );
        data.priority = this.state.selection;
        data.name = this.props.schema.title;
        data.date = moment( selectedData ).format();
        data.id = this.props.idSchema.$id;
        data.repeatData = this.state.repeatData;
        data.dueTime = this.state.dueTime;
        data.groups = groups;
        data.notes = [ { by: '', timestamp: new Date().getTime(), value: this.state.note } ];
        this.props.createCalendarEvent( data, this.state.repeatError );
    }

    toggleRepeat() {
        const newState = { ...this.state };
        if ( this.state.displayRepeat ) {
            newState.repeatData = {};
            newState.displayRepeat = false;
        } else {
            newState.displayRepeat = true;
        }
        this.setState( { ...newState } );
        setTimeout( () => { this.createCalendarEvent(); }, 500 );
    }

    selectGroup( data ) {
        this.setState( { groups: Array.from( new Set( [ ...this.state.groups, data ] ) ) } );
        setTimeout( () => {
            this.createCalendarEvent();
        }, 500 );
    }

    removeGroup( data ) {
        this.setState( { groups: data } );
        setTimeout( () => {
            this.createCalendarEvent();
        }, 500 );
    }

    saveNote( e ) {
        this.setState( { note: e.target.value } );
        setTimeout( () => { this.createCalendarEvent(); }, 500 );
    }

    repeaterCallback( data ) {
        let dates = [];
        const payload = { dueTime: this.state.dueTime, repeatData: data, date: moment.utc( this.state.date ).toISOString() };
        let hasError = false;
        try {
            const { rule } = buildRule( payload );
            dates = rule.all();
            if ( dates.length > 100 ) {
                hasError = `Maximum 100 recurrent events allowed. The current setup creates ${dates.length}. Please modify the rule.`;
                this.setState( { repeatError: `Maximum 100 recurrent events allowed. The current setup creates ${dates.length}. Please modify the rule.` } );
            } else {
                hasError = false;
            }
        } catch ( e ) {
            console.error( e );
        // throw e;
        }
        this.setState( { repeatData: data, repeatError: hasError } );
        setTimeout( () => { this.createCalendarEvent(); }, 500 );
    }

    timeCallback( dataString ) {
        const time = dataString.split( 'T' )[1].split( ':' );
        const date = new Date( this.state.selectedDate );
        const data = new Date( dataString );
        date.setHours( data.getHours() );
        date.setMinutes( data.getMinutes() );
        this.setState( { dueTime: { hour: time[0], minutes: time[1] }, time: data, selectedDate: date.toISOString() } );
        if ( this.state.selection !== 'none' && this.state.selection !== 'nope' ) {
            setTimeout( () => {
                this.createCalendarEvent();
            }, 500 );
        }
    }

    render() {
        const groups = determineGroups( this.props.groups, this.props.resources, this.props.tempDoc.formData.location )
            .filter( group => group.name !== 'No Access' && group.name !== 'Read Only' && group.name !== 'Users' && !group.escalation );
        return (
            <div>
                <label className="control-label" htmlFor={ this.props.idSchema.$id }>
                    { this.props.schema.title }
                    { this.props.required
                        && <span className="required">*</span>
                    }
                </label>
                <Display when={ !this.props.edit && !!this.state.selectedDate && this.state.selectedDate.length !== 0 }>
                    <span style={ { display: 'inline-block', marginLeft: 10 } }>
                        <select
                            style={ { height: 35, width: 160, marginBottom: 10 } }
                            className="form-control"
                            id={ `eventFor-${this.props.name}` }
                            value={ this.state.selection }
                            onChange={ this.changeEventPriority }>
                            <option value="none">Add Alert</option>
                            <option value="low">- low -</option>
                            <option value="medium">- medium -</option>
                            <option value="high">- high -</option>
                            <option value="nope">- no alert -</option>
                        </select>
                    </span>
                </Display>
                <Display when={ this.state.selection === 'low' || this.state.selection === 'medium' || this.state.selection === 'high' }>
                    <div style={ { padding: '0 10px', display: 'inline-block' } }>Due time: <Timepicker value={ this.state.time } onChange={ this.timeCallback } /></div>
                    <div className="repeatContainerLabel">Repeat: <Switch toggle={ this.toggleRepeat } repeat={ this.state.displayRepeat } /></div>
                </Display>
                <DateSelector
                    onChange={ this.onChange }
                    value={ this.state.selectedDate }
                    disabled={ this.props.readonly }
                />
                <Display when={ this.state.selection === 'low' || this.state.selection === 'medium' || this.state.selection === 'high' }>
                    <div className="onTheFlyAlertBody">
                        <Display when={ this.state.displayRepeat }>
                            <Display when={ this.state.repeatError }>
                                <div style={ { color: 'red', fontSize: '12px', marginTop: '10px' } }>{this.state.repeatError}</div>
                            </Display>
                            <Repeater
                                eventDate={ this.state.selectedDate !== '' ? this.state.selectedDate : new Date().toISOString() }
                                callback={ this.repeaterCallback }
                                open={ this.state.displayRepeat }
                                state={ this.state.repeatData } />
                        </Display>
                        <label className="control-label mt-2">Assign to groups:</label>
                        <GroupSelector
                            groups={ groups }
                            data={ this.state.groups }
                            removeGroup={ this.removeGroup }
                            callback={ this.selectGroup } />
                        <label className="control-label mt-2">Note:</label>
                        <textarea className="form-control" value={ this.state.note } onChange={ this.saveNote } />
                    </div>
                </Display>

            </div>
        );
    }
}

const mapStateToProps = state => ( {
    tempDoc: state.documents.tempDoc,
    resources: state.resources,
    groups: state.groups
} );

const mapDispatchToProps = dispatch => ( {
} );

export default connect( mapStateToProps, mapDispatchToProps )( DateSelectorWrapper );


/**
 * Filters out to display only groups that have access to the event category.
 * @param {array} groups
 * @param {array} resourcesData
 * @param {object} selectedEvent
 * @returns {*}
 */
function determineGroups( groups, resourcesData, categoryNode ) {
    if ( typeof categoryNode === 'undefined' ) {
        return [];
    }
    return groups.filter( group => {
        let isValid = false;
        let resources = [];
        group.resources.map( id => {
            resources = resourcesData.having( 'id', id );
        } );
        resources.map( resource => {
            if ( isValid ) { return; }
            const category = resource.prettyResourceName.split( '::' )[2].split( ':' )[1];
            const eventCategory = categoryNode.split( '/' )[0];
            if ( typeof category === 'undefined' || typeof eventCategory === 'undefined' ) {
                return;
            }
            isValid = category.toLocaleLowerCase() === 'all' || category.toLocaleLowerCase() === eventCategory.toLocaleLowerCase();
        } );
        return isValid;
    } );
}
