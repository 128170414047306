import React from 'react';
import { connect } from 'react-redux';
import SpinnerModal from '../../components/Modals/SpinnerModal';

import { currentUserOperations } from '../../redux/ducks/currentUser';
import { applicationOperations } from '../../redux/ducks/application';
import Display from '../../components/Display';


class TermsAndConditions extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { accept: false, isSubmitting: false };
    }

    UNSAFE_componentWillMount() {
        if ( !this.props.currentUser ) {
            return this.props.history.replace( '/login' );
        }
    }

    toggleAccept = () => {
        this.setState( { accept: !this.state.accept } );
    };

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( !nextProps.currentUser ) {
            return this.props.history.replace( '/login' );
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState( { isSubmitting: true } );
        if ( typeof this.state.accept === 'undefined' ) {
            document.getElementById( 'termAccept' ).style.color = 'red';
            document.getElementById( 'termReject' ).style.color = 'red';
            return;
        }
        this.props.saveTermsAndConditions( { response: this.state.accept } )
            .then( () => {
                this.props.history.replace( '/' );
            } )
            .catch( () => {
              this.setState( { isSubmitting: false } );
            } );
    };

    logout = e => {
        e.preventDefault();
        const that = this;
        this.props.auth.signOut().then( () => {
            // Sign-out successful.
            if ( typeof document !== 'undefined' ) {
                localStorage.removeItem( 'currentUserId' );
                localStorage.removeItem( 'sessionId' );
            }
            that.props.logout();
            setTimeout( () => {
                // give a bit of time for data to propagate;
                if ( typeof document !== 'undefined' ) {
                    this.props.history.replace( '/login' );
                }
            }, 300 );

            // this.history.replace( '/login' );
        }, ( error ) => {
            console.error( error );
        } );
    };

    render() {
        // if ( true ) {
        if ( !this.props.currentUser ) {
            return (
                <div className="container" style={ { height: '100%', textAlign: 'center' } }>
                    <img
                        src="/img/mycontracts-logo-3-long-v3.svg"
                        alt="mycontracts"
                        width="250px"
                        className="logo"
                        style={ { margin: '30px auto', paddingLeft: 0 } } />
                    <h2 style={ { color: '#5a5f61', textAlign: 'center', marginBottom: 30, marginTop: 50 } }>Please login again.</h2>
                    <p style={ { color: '#5a5f61', textAlign: 'center', marginBottom: 30 } }>It seems that you session is expired. Please login again.</p>
                    <button
                        style={ { color: '#5a5f61', textAlign: 'center', marginBottom: 30 } }
                        className="btn btn-outline-secondary"
                        onClick={ () => {
                            localStorage.clear();
                            this.props.history.replace( '/login' );
                            window.location.reload( true );
                        } }>Login
                    </button>
                </div>
            );
        }
        return (
            <div className="container" style={ { height: '100%' } }>
                <SpinnerModal showSpinner={ this.state.isSubmitting } />
                <img
                    src="/img/mycontracts-logo-3-long-v3.svg"
                    alt="mycontracts"
                    width="250px"
                    className="logo"
                    style={ { margin: '30px auto', paddingLeft: 0 } } />

                <h2 style={ { color: '#5a5f61', textAlign: 'center', marginBottom: 30 } }>Terms and conditions</h2>
                <object width="100%" height="550px" data="/img/terms.pdf"></object>
                <Display when={ !this.props.currentUser.termsAccepted }>
                    <form onSubmit={ this.handleSubmit }>
                        <div className="field">
                            <label className="control-label" id="termAccept" style={ { marginRight: 10, marginTop: 10 } }>Accept Terms and conditions: </label>
                            <input
                                type="checkbox"
                                checked={ this.state.accept }
                                onChange={ this.toggleAccept }
                            />
                        </div>
                        <br />
                        <button className="btn btn-outline-secondary pull-right" onClick={ this.logout }>Logout</button>
                        { this.state.accept
                  && <button className="btn btn-outline-success mr-3 pull-right" disabled={ this.state.isSubmitting } type="submit">Accept</button>
                        }
                    </form>
                </Display>
                <Display when={ this.props.currentUser?.termsAccepted }>
                    <button className="btn btn-outline-secondary pull-right" onClick={ () => { this.props.history.replace( '/' ); } }>to Dashboard</button>
                </Display>
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    currentUser: state.currentUser
} );


const mapDispatchToProps = dispatch => ( {
    logout: ( ) => dispatch( applicationOperations.logout( ) ),
    saveTermsAndConditions: ( data ) => dispatch( currentUserOperations.saveTermsAndConditions( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( TermsAndConditions );
