import types from './types';

const logout = ( ) => ( {
    type: types.LOGOUT
} );
const login = ( data ) => ( {
    type: types.LOGIN,
    payload: data
} );

const saveNewToken = ( data ) => ( {
    type: types.SAVE_NEW_TOKEN,
    payload: data
} );
const refreshToken = ( data ) => ( {
    type: types.SAVE_NEW_TOKEN,
    payload: data
} );

const saveIntercomSigniture = ( data ) => ( {
    type: types.SAVE_INTERCOM_SIGNITURE,
    payload: data
} );

const checkToken = ( data ) => ( {
    type: types.SAVE_NEW_TOKEN,
    payload: data
} );

const lastTokenUpdate = ( data ) => ( {
    type: types.LAST_TOKEN_UPDATE,
    payload: data
} );

const parseSubscription = ( data, extraData ) => ( {
    type: types.PARSE_SUBSCRIPTION,
    additionalData: extraData,
    payload: data
} );


const setFilter = ( data ) => ( {
    type: types.SET_FILTER,
    payload: data
} );

const checkEmail = ( data ) => {
    const body = data;
    return {
        type: types.CHECK_EMAIL,
        async: true,
        generalFetching: true,
        payload: {
            path: '/checkEmail',
            method: 'POST',
            body
        }
    };
};

const verifyIntercomUser = ( data = {} ) => ( {
    type: types.VERIFY_INTERCOM_USER,
    async: true,
    generalFetching: true,
    payload: {
        path: '/verifyIntercomUser',
        method: 'POST'
    }
} );

export default {
    login,
    logout,
    refreshToken,
    parseSubscription,
    checkEmail,
    checkToken,
    saveNewToken,
    lastTokenUpdate,
    setFilter,
    verifyIntercomUser,
    saveIntercomSigniture
};
