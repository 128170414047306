/* eslint-disable no-duplicate-case,no-fallthrough */
import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';
import { determineCurrentUser } from '../../../utils/determineCurrentUser';

const initialState = null;

const currentUserReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case types.TERMS_AND_CONDITIONS_COMPLETED: return { ...state, termsAccepted: action.payload.data.user.termsAccepted };
        case applicationTypes.PARSE_SUBSCRIPTION:

            if ( action.payload.target === 'groups' ) {
                const { resources, currentUser } = action.additionalData;
                if ( !currentUser ) {
                    return null;
                }
                const groups = typeof action.payload.data !== 'undefined' ? build( action.payload.data ) : [];
                return determineCurrentUser( action, groups, currentUser, resources, state );
            }
            if ( action.payload.target === 'currentUser' ) {
                const { groups, resources } = action.additionalData;
                const userData = typeof action.payload.data !== 'undefined' ? build( action.payload.data )[0] : null;
                return determineCurrentUser( action, groups, userData, resources, state );
            }
            if ( action.payload.target === 'myInvites' ) {
                const invites = typeof action.payload.data !== 'undefined' ? build( action.payload.data ) : [];
                return Object.assign( {}, state, { invites } );
            }
            if ( action.payload.target === 'accounts' ) {
                const { groups, resources, currentUser } = action.additionalData;
                if ( !currentUser ) {
                    return null;
                }
                return determineCurrentUser( action, groups, currentUser, resources, state );
            }
            if ( action.payload.target === 'resources' ) {
                const { groups, currentUser } = action.additionalData;
                if ( !currentUser ) {
                    return null;
                }
                const resourcesData = typeof action.payload.data !== 'undefined' ? build( action.payload.data ) : [];
                return determineCurrentUser( action, groups, currentUser, resourcesData, state );
            }
            return state;
        default: return state;
    }
};

export default currentUserReducer;
