import React from 'react';
import { ReactiveBase, ResultList, CategorySearch, DataController } from '@appbaseio/reactivesearch';
import PropTypes from 'prop-types';
import { flatten } from 'flat';
import { connect } from 'react-redux';

import DocumentsList from '../Document/DocumentsList';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import { applicationOperations } from '../../redux/ducks/application';
import messageOperations from '../../redux/ducks/messages/operations';


// eslint-disable-next-line prefer-destructuring
const unflatten = flatten.unflatten;

class DocumentSearch extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { isSearch: false, categories: [] };

      this.getPermittedCategories = this.getPermittedCategories.bind(this);
      this.customQuery = this.customQuery.bind(this);
    }

  getPermittedCategories = (resources) => {

    const categories = [];

    if ((typeof (resources) === "undefined" || resources.length === 0)) return ['NO_CATEGORY_ACCESS!'];

    resources.forEach((resource) => {

      if (resource.actions.filter((action) => action === 'read' ).length > 0) {


        resource.subject.forEach( (subject) => {

          const resArray = subject.split("::");
          // check if access to all

          if (resArray.length === 3) {
            categories.push('*');
          }

          if (resArray.length > 3) {

            if (resArray[2].split(':')[0] === 'category') {
              const cat = resArray[2].split(':')[1];
              categories.push(cat.toLowerCase());
            }
          }

        });
      }

    });
    return categories;
  };


  generateMatchPhrases = (categories) => {
    const phrases = [];
    categories.forEach( (category) => {
      phrases.push({
        match_phrase: {
          'formData_location': category
        }
      })
    } )

    return phrases;
  };

  customQuery = (fn) => {

    //const categats = [...this.state.categories];
    const categats = fn();
    
    if(categats.filter( cat => cat === '*' || cat === 'all' ).length > 0) {
      return {
        from: 1,
        size: 100,
        query:
          {
            wildcard: {
              'formData_location': '*'
            }
          }
      };

    }

    const matchPhrases = this.generateMatchPhrases(categats);

    return {
      from: 1,
      size: 100,
      query:
        {
          bool:
            {
              should: matchPhrases
            }
        }
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({categories: this.getPermittedCategories(this.props.resources)});
  }

    componentDidMount() {
    // need to listenr for change on search input.
        const that = this;
        if ( document.getElementById( 'Searchbox-downshift-input' ) ) {
            document.getElementById( 'Searchbox-downshift-input' ).addEventListener( 'input', function ( evt ) {
                if ( this.value.trim().length > 0 ) {
                    if ( typeof document.getElementsByClassName( 'cancel-icon' )[0] !== 'undefined' ) {
                        document.getElementsByClassName( 'cancel-icon' )[0].addEventListener( 'click', ( evt ) => {
                            if ( that.state.isSearch ) {
                                setTimeout( () => { that.setState( { isSearch: false } ); that.props.callback( false ); }, 100 );
                            }
                        } );
                    }
                    if ( !that.state.isSearch ) {
                        setTimeout( () => { that.setState( { isSearch: true } ); that.props.callback( true ); }, 100 );
                    }
                } else if ( that.state.isSearch ) {
                    setTimeout( () => { that.setState( { isSearch: false } ); that.props.callback( false ); }, 100 );
                }
            } );
        }
    }

    componentWillUnmount() {
        if ( document.getElementById( 'Searchbox-downshift-input' ) ) {
            document.getElementById( 'Searchbox-downshift-input' ).removeEventListener( 'input', () => {} );
        }

        if ( typeof document.getElementsByClassName( 'cancel-icon' )[0] !== 'undefined' ) {
            document.getElementsByClassName( 'cancel-icon' )[0].removeEventListener( 'click', () => {} );
        }
    }

    searchDom = data => {
        const unflattenData = data.map( entry => {
            const newVal = unflatten( entry, { delimiter: '_' } );
            newVal.id = entry._id;
            return newVal;
        } );
        const a = ( <DocumentsList
            history={ this.props.history }
            documentsForCategory={ unflattenData }
            redirect={ this.props.redirect }
            selectDocument={ this.props.selectDocument } /> );
        return (
            <div>{ a }</div>
        );
    };

    triggerRefreshToken = () => {
        if ( this.props.auth.currentUser ) {
            this.props.refreshToken( this.props.auth.currentUser, true );
        }
    };

    render() {
        const { currentAccount } = this.props;
        const sessionId = localStorage.getItem( 'sessionId' );
        return (
            <ReactiveBase
                app="documents"
                url={ `${process.env.elasticsearch_host}/search` }
                headers={ {
                    accountId: this.props.currentAccount.id,
                    categories: this.getPermittedCategories(this.props.resources),
                    authorization: `Basic ${sessionId}`
                } } >

                <DataController
                    title="DataController"
                    componentId="AccountSearch"
                    dataField="accountId"
                    customQuery={
                        ( ) =>
                            ( { match: { accountId: currentAccount.id || 'accountNotProvided' } } )
                    }
                >
                </DataController>

                <DataController
                  title="CategoryController"
                  componentId="CategorySearch"
                  dataField="categoryNode"
                  customQuery={() => this.customQuery(() => this.getPermittedCategories(this.props.resources))}
                  size={10}
                >
                </DataController>

                <DataController
                    title="DataController"
                    componentId="DocSearchSoftDelete"
                    dataField="deleted"
                    customQuery={
                        ( ) =>
                            ( { match: { deleted: true } } )
                    }
                >
                </DataController>

                <div>
                    <div className="col-md-12 mb-4">
                        <CategorySearch
                            componentId="Searchbox"
                            dataField={ [ 'formData_title' ,'parties_0_label','parties_1_label','parties_2_label','parties_3_label','parties_4_label' ] }
                            categoryField="formData_location"
                            placeholder="Search for document by title or party "
                            debounce={ 150 }
                            autoselect={ false }
                            showClear
                            showFilter={ false }
                            fuzziness="AUTO"
                            filterLabel="category filter"
                            react={ {
                                and: [ 'Searchbox', 'AccountSearch', 'SearchResult','CategorySearch' ],
                                not: [ 'DocSearchSoftDelete' ],
                            } }
                        />
                    </div>
                </div>
                <div className="col-md-12" style={ this.state.isSearch ? {} : { display: 'none' } }>
                    <ResultList
                        onError={ ( err ) => {
                            if ( err.status === 403 ) {
                                if ( typeof document !== 'undefined' ) {
                                    this.triggerRefreshToken();
                                }
                            }
                        } }
                        style={ { width: '100%' } }
                        componentId="SearchResult"
                        dataField="formData_title"
                        from={ 0 }
                        size={ 15 }
                        onAllData={ this.searchDom }
                        className="result-list-container"
                        showResultStats={ false }
                        react={ {
                            and: [ 'Searchbox', 'AccountSearch', 'SearchResult','CategorySearch' ],
                            not: [ 'DocSearchSoftDelete' ],
                        } }
                    />
                </div>

            </ReactiveBase>
        );
    }
}

DocumentSearch.defaultProps = {
    callback: () => {},
    resources: []
};
DocumentSearch.propTypes = {
    currentAccount: PropTypes.object.isRequired,
    callback: PropTypes.func
};


const mapStateToProps = state => ({
  resources: state.currentUser.acl,
});

const mapDispatchToProps = dispatch => ( {
    saveCalendarEvent: ( data ) => dispatch( calendarEventsOperations.saveCalendarEvent( data ) ),
    selectEvent: ( data ) => dispatch( calendarEventsOperations.selectEvent( data ) ),
    refreshToken: ( data, forced ) => dispatch( applicationOperations.refreshToken( data, forced ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentSearch );
