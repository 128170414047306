import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    Button,
    Input,
    InputGroup,
    InputGroupAddon } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import RecaptchaV3 from '../../../components/Recaptcha/RecaptchaV3';
import { applicationOperations } from '../../../redux/ducks/application';
import MessageCenter from '../../../components/MessageCenter/MessageCenter';
import SpinnerModal from '../../../components/Modals/SpinnerModal';
import Display from '../../../components/Display';
import messageOperations from '../../../redux/ducks/messages/operations';

const versionInfo = '[AIV]v{version} - {date}[/AIV]';

class Login extends Component {
    constructor( props ) {
        super( props );
        this.state = { isLoading: false, email: '', recaptchaScore: 0 };
        this.history = this.props.history;
        this.handleOpen = () => this.setState( { modalOpen: true } );
        this.handleClose = () => this.setState( { modalOpen: false } );
        this.handleChange = this.handleChange.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleKeyPress = this.handleKeyPress.bind( this );
        this.handleResult = this.handleResult.bind( this );

        // logout in-case user is still logged-in
        this.props.auth.signOut()
            .then( () => {
                // Sign-out successful.
                console.log( 'Logout success' );
            } )
            .catch( ( error ) => {
                // An error happened
            } );
        setTimeout( () => {
            localStorage.clear();
        }, 1000 );
    }

  handleOpen = () => this.setState( { modalOpen: true } );

  handleChange = ( e ) => this.setState( { [e.target.name]: e.target.value } );

  handleClose = () => {
      this.setState( { modalOpen: false } );
  };

  handleResult = ( result ) => {
      this.setState( { recaptchaScore: result.score } );
  };

  componentDidMount() {
      const loggedOutReason = localStorage.getItem( 'loggedOutReason' );
      if ( typeof loggedOutReason !== 'undefined' ) {
          this.props.showNotificationInfo( loggedOutReason );
          localStorage.setItem( 'loggedOutReason', null );
      }
      setTimeout( () => {
          if ( document.getElementById( 'submitBtn' ) ) {
              document.getElementById( 'submitBtn' ).focus();
          }
          if ( window.location.search.length > 0 && this.state.email === '' && window.location.search.contains( 'email' ) ) {
              this.setState( { email: window.location.search.split( 'email=' )[1] } );
          }
      }, 5000 );
  }

  handleSubmit = () => {
      const { password, email } = this.state;
      const cleanEmail = email.toLocaleLowerCase().trim();
      this.setState( { submitting: true, submittedPassword: password, submittedEmail: cleanEmail } );
      const self = this;
      this.props.checkEmail( { email: cleanEmail } )
          .then( ( res ) => {
              this.props.auth.signInWithEmailAndPassword( self.state.email.toLocaleLowerCase().trim(), self.state.password ).then( ( user ) => {
                  this.props.login( user );
                  self.setState( { success: true, errorMessage: '', error: false, submitting: false } );
                  const tz = moment.tz.guess();
                  console.info( `Timezone: ${tz}` );
                  // set the default user timezone
                  moment.tz.setDefault( tz );
                  this.props.history.replace( '/dashboard' );
              } ).catch( ( error ) => {
                  self.setState( { errorMessage: error.message, success: false, error: true, errorCode: error.code, submitting: false } );
              } );
          } )
          .catch( () => { this.setState( { submitting: false } ); } );
  };

  handleRegister = () => {
      this.history.push( '/register' );
  };

  handleKeyPress = ( e ) => {
      if ( e.key === 'Enter' ) {
          this.handleSubmit();
      }
  };

  render() {
      const { errorMessage, submitting } = this.state;

      return (
          <div className="app flex-row align-items-center">
              <Display when={ process.env.host !== 'http://localhost:8080/' && process.env.host !== 'https://demo.mycontracts.cloud/' }>
                  <RecaptchaV3 action="Login" handleResult={ this.handleResult } />
              </Display>
              <MessageCenter />
              <SpinnerModal showSpinner={ this.state.submitting } />
              <Container>
                  <Row className="justify-content-center">
                      <Col md="5">
                          <img style={ { marginBottom: '50px', display: 'block', height: 'auto', width: 'auto' } } src="/img/mycontracts-logo-3-long-v3.svg" alt="mycontracts_logo" />
                      </Col>
                  </Row>

                  <Display when={ this.state.recaptchaScore > 0.5 || process.env.host === 'http://localhost:8080/' || process.env.host === 'https://demo.mycontracts.cloud/' }>
                      <Row className="justify-content-center">
                          <Col md="9">
                              <CardGroup>
                                  <Card className="p-4">

                                      <CardBody>
                                          <h1>Login</h1>
                                          <p className="text-muted">Sign In to your account</p>
                                          <form>
                                              <InputGroup className="mb-3">
                                                  <InputGroupAddon addonType="append"><i className="icon-user"></i></InputGroupAddon>
                                                  <Input
                                                      className="insideIcon"
                                                      name="email"
                                                      autoComplete="email"
                                                      type="email"
                                                      placeholder="email"
                                                      required
                                                      autoFocus
                                                      value={ this.state.email.toLocaleLowerCase() }
                                                      onChange={ this.handleChange } />
                                              </InputGroup>
                                              <InputGroup className="mb-4">
                                                  <InputGroupAddon addonType="append"><i className="icon-lock"></i></InputGroupAddon>
                                                  <Input
                                                      className="insideIcon"
                                                      name="password"
                                                      autoComplete="current-password"
                                                      type="password"
                                                      placeholder="Password"
                                                      required
                                                      onChange={ this.handleChange }
                                                      onKeyPress={ this.handleKeyPress } />
                                              </InputGroup>
                                          </form>
                                          <Row>
                                              <Col xs="12">
                                                  <p style={ { color: 'red' } }>{errorMessage}</p>
                                              </Col>
                                              <Col xs="6">
                                                  <Button
                                                      disabled={ submitting }
                                                      color="primary"
                                                      className="px-4"
                                                      id="submitBtn"
                                                      onClick={ this.handleSubmit }>Login
                                                  </Button>
                                              </Col>
                                              <Col xs="6" className="text-right align-bottom">
                                                  <a className="top7" href="/reset">Forgot password?</a>
                                              </Col>
                                          </Row>
                                      </CardBody>
                                  </Card>
                                  <Card className="text-white bg-primary py-5">
                                      <CardBody className="text-center">
                                          <div>
                                              <div className="text-white">
                                                  <h2>Sign up</h2>
                                                  <p>Sign up here for a new account</p>
                                              </div>
                                              <Button
                                                  disabled={ submitting }
                                                  color="primary"
                                                  className="mt-3"
                                                  onClick={ this.handleRegister }
                                                  active>Register Now!
                                              </Button>
                                          </div>
                                      </CardBody>
                                  </Card>
                              </CardGroup>
                              <div className="p-1 text-secondary text-center" style={ { marginTop: 20 } }>{versionInfo}</div>
                          </Col>
                      </Row>
                  </Display>
                  <Display when={ this.state.recaptchaScore <= 0.5 }>
                      <div />

                  </Display>
              </Container>
          </div>
      );
  }
}

Login.propTypes = {
    history: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ( {
    checkEmail: ( data ) => dispatch( applicationOperations.checkEmail( data ) ),
    showNotificationInfo: ( message ) => dispatch( messageOperations.showInfo( message ) ),
    login: ( data ) => dispatch( applicationOperations.login( data ) )
} );

export default connect( null, mapDispatchToProps )( Login );
