import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment-timezone';

import {applicationOperations} from '../../redux/ducks/application';
import DashCountCard from '../../components/Dashboard/DashCountCard';
import Display from '../../components/Display';
import ActivityList from "../../components/Activity/ActivityList";
import { Button } from 'reactstrap';

// Main Chart
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  UNSAFE_componentWillMount() {
    if (this.props.auth.currentUser) {
      this.props.refreshToken(this.props.auth.currentUser);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentAccount !== nextProps.currentAccount) {

    }
  }

  componentDidMount() {
    const {checkToken, auth} = this.props;
    checkToken(auth);
  }


  gotoAlerts = filter => {
    this.props.setFilter(filter);
    this.props.history.push('/alerts');
  };


  render() {
    if ( this.props.currentUser.isDefault) {
      return (
        <h3 className="col-lg-12 text-center mt-4">
          Looks like you don't have any permissions assigned.<br />
          Please ask your admin to grant you access.
        </h3>
      )
    }
    const today = moment();
    const startOfWeek = today.startOf('week').startOf('day').toISOString();
    const endOfWeek = today.endOf('week').endOf('day').toISOString();
    const tomorrowStartOfDay = moment(new Date()).clone().add(1, 'days').startOf('day')
      .toISOString();
    const tomorrowEndOfDay = moment(new Date()).clone().add(1, 'days').endOf('day')
      .toISOString();
    const startOfOverdue = moment(new Date('01/01/1910')).startOf('day').toISOString();
    const endOfOverdue = moment(new Date()).clone().subtract(1, 'days').endOf('day')
      .toISOString();
    const overdueFilter = {columnName: 'date', operation: 'between', value: {start: startOfOverdue, end: endOfOverdue}};
    const todayFilter = {
      columnName: 'date',
      operation: 'between',
      value: {start: moment().toISOString(), end: moment().toISOString()}
    };
    const tomorrowFilter = {
      columnName: 'date',
      operation: 'between',
      value: {start: tomorrowStartOfDay, end: tomorrowEndOfDay}
    };
    const weekFilter = {columnName: 'date', operation: 'between', value: {start: startOfWeek, end: endOfWeek}};
    return (
      <div className="animated fadeIn">

        <div style={{marginTop: '15px'}}/>

        <Display when={typeof (this.props.currentUser.email) !== 'undefined'}>
          <div className="">
            <div className="h3">ALERTS</div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3 col-xxl-2"
                 onClick={() => {
                   this.gotoAlerts(overdueFilter);
                 }}>
              <DashCountCard
                count={ this.props.eventsBreakdown.overdue}
                title="OVERDUE"
                color="red"
                icon="fa fa-exclamation-circle"/>
            </div>
            <div
              className="col-sm-6 col-md-4 col-lg-3 col-xxl-2"
              onClick={() => this.gotoAlerts(todayFilter)}>
              <DashCountCard
                count={ this.props.eventsBreakdown.today}
                title="TODAY"
                color="orange"
                icon="fa fa-exclamation"/>
            </div>
            <div
              className="col-sm-6 col-md-4 col-lg-3 col-xxl-2"
              onClick={() => this.gotoAlerts(tomorrowFilter)}>
              <DashCountCard
                count={ this.props.eventsBreakdown.tomorrow}
                title="TOMORROW"
                color="blue"
                icon="fa fa-calendar-o"/>
            </div>
            <div
              className="col-sm-6 col-md-4 col-lg-3 col-xxl-2"
              onClick={() => this.gotoAlerts(weekFilter)}>
              <DashCountCard
                count={ this.props.eventsBreakdown.week}
                title="WEEK"
                color="green"
                icon="fa fa-calendar"/>
            </div>
          </div>

          <div className="row">

            <div className="col-sm-6 col-md-4 col-lg-3 col-xxl-2"
                 onClick={() => this.gotoAlerts({columnName: 'priority', value: 'high'})}>
              <DashCountCard
                count={ this.props.eventsBreakdown.high}
                title="HIGH"
                color="red"
                icon="fa fa-arrow-circle-up"/>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xxl-2"
                 onClick={() => this.gotoAlerts({columnName: 'priority', value: 'medium'})}>
              <DashCountCard
                count={ this.props.eventsBreakdown.medium}
                title="MEDIUM"
                color="orange"
                icon="fa fa-arrow-circle-right"/>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xxl-2"
                 onClick={() => this.gotoAlerts({columnName: 'priority', value: 'low'})}>
              <DashCountCard
                count={ this.props.eventsBreakdown.low}
                title="LOW"
                color="green"
                icon="fa fa-arrow-circle-down"/>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xxl-2" onClick={() => this.gotoAlerts('')}>
              <DashCountCard
                count={ this.props.eventsBreakdown.total}
                title="TOTAL"
                color="green"
                icon="fa fa-calculator"/>
            </div>
          </div>
          <div className="">
            <div className="h3">Activity</div>
          </div>
          <div className="row">
            <ActivityList />
          </div>
        </Display>

      </div>

    );
  }
}

Dashboard.propTypes = {
  calendarEvents: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  checkToken: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  calendarEvents: state.events.calendarEvents,
  currentAccount: state.currentAccount,
  currentUser: state.currentUser,
  eventsBreakdown: state.events.breakdown,
});

const mapDispatchToProps = dispatch => ({
  parseSubscription: (data) => dispatch(applicationOperations.parseSubscription(data)),
  refreshToken: (data) => dispatch(applicationOperations.refreshToken(data)),
  setFilter: (data) => dispatch(applicationOperations.setFilter(data)),
  checkToken: (data) => dispatch(applicationOperations.checkToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
