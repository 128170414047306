import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import Display from '../Display';
import DocumentTypes from '../Settings/DocumentsTypes';
import messageOperations from '../../redux/ducks/messages/operations';
import { currentUserOperations } from '../../redux/ducks/currentUser';
import { buildPermissionsDom } from '../../utils/buildPermissionsDom';
import ImageUpload from '../Account/ImageUploader';
import { uploadsOperations } from '../../redux/ducks/uploads';

class Profile extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isDocTypesModalOpen: false,
            displayName: '',
            email: '',
            phoneNumber: '',
            photoURL: '',
            previewImage: null,
            files: [],
            displayEmailChangeMessage: false
        };
        this.toggleDocTypesModal = this.toggleDocTypesModal.bind( this );
        this.handleInputChange = this.handleInputChange.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleEmailChange = this.handleEmailChange.bind( this );
        this.uploadPicture = this.uploadPicture.bind( this );
        this.onFilesDrop = this.onFilesDrop.bind( this );
        this.passwordReset = this.passwordReset.bind( this );
    }

    UNSAFE_componentWillMount( ) {
        if ( Object.keys( this.props.currentUser ).hasItems() ) {
            this.setState( {
                displayName: this.props.currentUser.displayName,
                email: this.props.currentUser.email,
                phoneNumber: this.props.currentUser.phoneNumber,
                photoURL: this.props.currentUser.photoURL
            } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( Object.keys( nextProps.currentUser ).hasItems() ) {
            this.setState( {
                displayName: nextProps.currentUser.displayName,
                email: nextProps.currentUser.email,
                phoneNumber: nextProps.currentUser.phoneNumber,
                photoURL: nextProps.currentUser.photoURL
            } );
        }
    }

    toggleDocTypesModal() {
        this.setState( { isDocTypesModalOpen: !this.state.isDocTypesModalOpen } );
    }

    handleInputChange( event ) {
        const { target } = event;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if ( name === 'email' ) {
            value = value.toLocaleLowerCase().trim();
        }
        this.setState( {
            [name]: value
        } );
    }

    handleSubmit( event ) {
        event.preventDefault();
        const { displayName, phoneNumber, photoURL } = this.state;
        this.handleEmailChange();
        this.props.firestore.collection( 'users' ).doc( this.props.currentUser.id ).update( { displayName, phoneNumber, photoURL } )
            .then( () => {
                this.props.dataUpdatedConfirmation();
            } )
            .catch( () => {
                this.props.dataUpdatedConfirmation();
            } );
    }

    handleEmailChange() {
        if ( this.state.email !== this.props.currentUser.email ) {
            this.setState( { displayEmailChangeMessage: true } );
            this.props.changeEmail( { email: this.state.email } );
        }
    }

    uploadPicture() {
        const user = this.props.firebase.auth.currentUser;
        const file = this.state.files[0];
        // Create a Storage Ref w/ username
        const storageRef = firebase.storage().ref( `${user.uid}/profilePicture/${file.name}` );
        const task = storageRef.put( file );
        return new Promise( ( ( resolve, reject ) => {
            task.on(
                'state_changed',

                ( snapshot ) => {
                    const percentage = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;
                    console.log( 'percentage', percentage );
                },

                ( error ) => {
                    console.log( 'error', error );
                    reject( error );
                },
                ( ) => {
                    storageRef.getDownloadURL().then( ( url ) => resolve( url ) ).catch( ( error ) => {
                        reject( error );
                    } );
                }
            );
        } ) );
    }

    onFilesDrop( files ) {
        // const filesArray = files.map( file => ( { fileName: `${this.props.accountId}^${file.name}` } ) );
        this.setState( { files, filesMeta: files, previewImage: files[0].preview } );
    }

    passwordReset( event ) {
        event.preventDefault();
        const auth = firebase.auth();
        const emailAddress = this.props.currentUser.email;

        auth.sendPasswordResetEmail( emailAddress ).then( ( ) => {
        // Email sent.
            this.props.passwordResetEmailConfirmation();
        } ).catch( ( error ) => {
        // An error happened.
            this.props.passwordResetEmailError();
            console.log( 'email sent with err', error );
        } );
    }

    render() {
        const { currentUser } = this.props;
        const permissionsDom = buildPermissionsDom( currentUser.prettyPermissions );
        const imgSrc = this.state.previewImage ? this.state.previewImage : this.state.photoURL ? this.state.photoURL : 'https://ssl.gstatic.com/images/branding/product/1x/avatar_circle_blue_512dp.png';
        return (
            <div className="accountSettingsLayout">
                <div className="row">
                    <Display when={ this.state.displayEmailChangeMessage }>
                        <Alert style={ { width: '100%' } } className="text-center" color="info">Email needs to be validated before use. Please check your inbox.</Alert>
                    </Display>
                    <div className="col-lg-6">

                        <ImageUpload
                            userId={ this.props.currentUser.uid }
                            uploadAccountLogo={ this.props.uploadAccountLogo }
                            accountId={ this.props.currentAccount.id }
                            currentLogo={ imgSrc }
                        />

                    </div>
                    <div className="col-lg-6">
                        <div className="card white-box">
                            <div className="card-header  pt-0">
                                <i className="fa fa-align-justify" />  Profile information
                            </div>
                            <form onSubmit={ this.handleSubmit }>
                                <div className="form-group">
                                    <label htmlFor="email">Name: </label>
                                    <input
                                        className="form-control"
                                        name="displayName"
                                        type="text"
                                        value={ this.state.displayName }
                                        onChange={ this.handleInputChange } />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email: </label>
                                    <input
                                        className="form-control"
                                        name="email"
                                        type="email"
                                        disabled
                                        value={ this.state.email }
                                        onChange={ this.handleInputChange } />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Phone: </label>
                                    <input
                                        className="form-control"
                                        name="phoneNumber"
                                        type="text"
                                        value={ this.state.phoneNumber }
                                        onChange={ this.handleInputChange } />
                                </div>
                                <input className="btn btn-outline-primary" type="submit" value="Update info" />
                                <button className="btn btn-outline-secondary" style={ { marginLeft: '5px' } } onClick={ this.passwordReset }>Pass reset</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card white-box">
                            <div className="card-header pt-0">
                                <i className="fa fa-align-justify" /> Permissions
                            </div>
                            <div className="col-lg-12">
                                { permissionsDom }
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.isDocTypesModalOpen
                    && <DocumentTypes toggle={ this.toggleDocTypesModal } open={ this.state.isDocTypesModalOpen } />
                }
            </div>
        );
    }
}

// AccountSettings.propTypes = {
//     onSubmit: PropTypes.func.isRequired,
//     onDelete: PropTypes.func.isRequired,
//     log: PropTypes.func.isRequired,
//     accountInfo: PropTypes.array.isRequired,
//     firestore: PropTypes.object.isRequired,
//     ownerRef: PropTypes.string.isRequired,
// };

const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    changeEmail: ( data ) => dispatch( currentUserOperations.changeEmail( data ) ),
    uploadAccountLogo: ( data ) => dispatch( uploadsOperations.uploadAccountLogo( data ) ),
    passwordResetEmailConfirmation: ( ) => dispatch( messageOperations.showSuccess( 'Password reset email sent' ) ),
    dataUpdatedConfirmation: ( ) => dispatch( messageOperations.showSuccess( 'Profile information updated' ) ),
    dataUpdatedError: ( ) => dispatch( messageOperations.showSuccess( 'Error on profile information update' ) ),
    passwordResetEmailError: ( ) => dispatch( messageOperations.showError( 'Error encountered while sending password reset email sent' ) )
} );
export default connect( mapStateToProps, mapDispatchToProps )( Profile );
