import React from 'react';
import { connect } from 'react-redux';

import { currentUserOperations } from '../../redux/ducks/currentUser';
import { applicationOperations } from '../../redux/ducks/application';

class PrivacyPolicy extends React.Component {
    constructor( props ) {
        super( props );
    }

    UNSAFE_componentWillMount() {
        if ( !this.props.currentUser ) {
            this.props.history.replace( '/login' );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( !nextProps.currentUser ) {
            this.props.history.replace( '/login' );
        }
    }

    render() {
        return (
            <div className="container" style={ { height: '100%' } }>
                <img
                    src="/img/mycontracts-logo-3-long-v3.svg"
                    alt="mycontracts"
                    width="250px"
                    className="logo"
                    style={ { margin: '30px auto', paddingLeft: 0 } } />

                <h2 style={ { color: '#5a5f61', textAlign: 'center', marginBottom: 30 } }>Privacy Policy</h2>
                <object width="100%" height="550px" data="/img/privacy.pdf"></object>

                <button className="btn btn-outline-secondary pull-right" onClick={ () => { this.props.history.replace( '/' ); } }>to Dashboard</button>

            </div>
        );
    }
}

const mapStateToProps = state => ( {
    currentUser: state.currentUser
} );


const mapDispatchToProps = dispatch => ( {
    logout: ( ) => dispatch( applicationOperations.logout( ) ),
    saveTermsAndConditions: ( data ) => dispatch( currentUserOperations.saveTermsAndConditions( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( PrivacyPolicy );
