import types from './types';
import { combineReducers } from 'redux';
import applicationTypes from '../application/types';

const initialState = [];
function build( data ) {
    const array = [];
    data.forEach( ( doc ) => {
        array.push( { ...doc.data(), id: doc.id } );
    } );
    return array;
}
const partiesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'parties' ) {
                return build( action.payload.data );
            }
        default: return state;
    }
};

const currentPartyReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT:
            return {};
        case types.SELECT_PARTY:
            return action.payload;
        default:
            return state;
    }
};

const parties = combineReducers( {
    list: partiesReducer,
    currentParty: currentPartyReducer
} );

export default parties;
