import actions from './actions';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import messageOperations from '../messages/operations';

// This is a link to an action defined in actions.js.
const { changeFolder } = actions;

const saveDocument = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.saveDocument( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Document type saved' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const deleteDocument = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.deleteDocument( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Document type deleted' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    saveDocument,
    changeFolder,
    deleteDocument
};
