import { combineReducers } from 'redux';
import types from './types';

const initialState = null;
const generalInitialState = {
    changingAccount: false
};

const categoriesReducer = ( state = false, action ) => {
    switch ( action.type ) {
        case '@@reduxFirestore/SET_LISTENER':
            if ( action.meta.collection === 'categories' && action.meta.storeAs === 'categories' ) {
                return true;
            }
        case '@@reduxFirestore/LISTENER_RESPONSE':
            if ( action.meta.collection === 'categories' && action.meta.storeAs === 'categories' ) {
                return false;
            }
        default: return state;
    }
};
const userDataReducer = ( state = null, action ) => {
    switch ( action.type ) {
        case 'LOGIN':
            return {
                email: action.payload.user.email,
                uid: action.payload.user.uid,
                displayName: action.payload.user.displayName,
                photoURL: action.payload.user.photoURL,
                accessToken: action.payload.user.qa,
                refreshToken: action.payload.user.refreshToken,
                lastTokenUpdate: action.payload.user.lastTokenUpdate
            };
        case 'LOGOUT':
            return initialState;
            break;
        case types.SAVE_NEW_TOKEN:
            window.localStorage.setItem( 'sessionId', action.payload );
            const newState = Object.assign( {}, state );
            newState.accessToken = action.payload;
            newState.lastTokenUpdate = new Date().getTime();
            return newState;
        default: return state;
    }
};

const filtersReducer = ( state = [], action ) => {
    switch ( action.type ) {
        case 'SET_FILTER':
            return action.payload;
        default: return state;
    }
};

const intercomReducer = ( state = [], action ) => {
    switch ( action.type ) {
        case 'SAVE_INTERCOM_SIGNITURE':
            return { data: action.payload.data };
        default: return state;
    }
};

const generalReducer = ( state = generalInitialState, action ) => {
    switch ( action.type ) {
        case 'SELECT_ACCOUNT':
            return { ...state, changingAccount: true };
        case 'PARSE_SUBSCRIPTION':
            if ( action.payload.target === 'documentTypes' ) {
                return { ...state, changingAccount: false };
            }
            return state;
        default: return state;
    }
};
const application = combineReducers( {
    categoriesLoading: categoriesReducer,
    user: userDataReducer,
    filters: filtersReducer,
    general: generalReducer,
    intercom: intercomReducer
} );
export default application;
