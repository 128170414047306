/* eslint-disable import/no-named-as-default,import/no-named-as-default-member */
import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { AppContainer } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/browser';
// import { hot } from 'react-hot-loader/root';
// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../scss/style.scss';
// Temp fix for reactstrap
import '../scss/core/_dropdown-menu-right.scss';
import 'open-iconic/font/css/open-iconic-bootstrap.scss';
// Containers
import Full from './containers/Full';
// Pages
import Login from './views/Pages/Login';
import Register from './views/Pages/Register';
import Reset from './views/Pages/Reset';
import Page404 from './views/Pages/Page404';
import Page500 from './views/Pages/Page500';
import Verify from './views/Pages/VerifyEmail';
import ResetConfirmation from './views/Pages/ResetConfirmation/ResetConfirmation';
// store
import configureStore from './redux/store';
// constants
// Service-worker
import RouteWrapper from './RouteEnhancer';
import './utils/sugarify';
import TermsAndConditions from './views/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';

//
if ( process.env.NODE_ENV === 'production' ) {
    Sentry.init( {
        dsn: 'https://671ce4e449de4926af5046e5b36634b4@sentry.io/1302733',
        release: `mycontracts ${process.env.VERSION}` || 'undefined_dev_version',
        environment: process.env.environment,
    } );
}

const history = createBrowserHistory();

history.listen( ( location, action ) => {
    window.Intercom( 'update' );
    console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
    );
    console.log( `The last navigation action was ${action}` );
} );

window.intercomSettings = {
    app_id: 'idj2qwhm',
    alignment: 'left',
    horizontal_padding: 65,
    vertical_padding: 20
};

window.Intercom( 'boot', {
    app_id: 'idj2qwhm'
} );

// Initialize Redux
const { store, persistor } = configureStore();

// for hot reloading
if ( module.hot ) {
    module.hot.accept( './redux/ducks', () => {
        const nextRootReducer = require( './redux/ducks' );
        persistor.replaceReducer( nextRootReducer );
    } );
}

const Main = () => (
    <AppContainer>
        <Provider store={ store }>
            <PersistGate loading={ null } persistor={ persistor }>
                <BrowserRouter>
                    <Switch>
                        <RouteWrapper exact path="/login" name="Login Page" component={ Login } />
                        <RouteWrapper
                            exact
                            path="/terms-and-conditions"
                            name="Terms and Conditions"
                            component={ TermsAndConditions } />
                        <RouteWrapper
                            exact
                            path="/privacy-policy"
                            name="Privacy Policy"
                            component={ PrivacyPolicy } />
                        <RouteWrapper exact path="/register" name="Register Page" component={ Register } />
                        <RouteWrapper exact path="/reset" name="Reset Password Page" component={ Reset } />
                        <RouteWrapper exact path="/passwordChange" name="Reset Password Page" component={ ResetConfirmation } />
                        <Route exact path="/verify" name="Verify Email Page" component={ Verify } />
                        <Route exact path="/404" name="Page 404" component={ Page404 } />
                        <Route exact path="/500" name="Page 500" component={ Page500 } />
                        <RouteWrapper path="/" name="Home" component={ Full } />
                    </Switch>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </AppContainer>
);

export default hot( Main );
