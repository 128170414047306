import { combineReducers } from 'redux';
import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';
import { isBeforeToday, isThisWeek, isToday, isTomorrow } from '../../../utils/datetime';

const initialState = [];
const initialBreakdown = {
    high: 0,
    low: 0,
    medium: 0,
    overdue: 0,
    today: 0,
    tomorrow: 0,
    total: 0,
    week: 0
};

const a = {
    calendarEvents: [],
    selectedEvent: {},
    recurrenceDefinition: {},
    breakdown: {
        high: 0,
        low: 0,
        medium: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        total: 0,
        week: 0
    }
};

const calendarEventsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case types.SELECT_ACCOUNT: return action.payload.data;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'calendarEvents' ) {
                const { breadcrumbs } = action.additionalData.categories;
                const originalEvents = build( action.payload.data );
                return originalEvents.filter( x => breadcrumbs.having( 'breadcrumb', x.categoryNode ).length > 0 );
                // return build( action.payload.data );
            }
        default: return state;
    }
};
const calendarEventsBreakdownReducers = ( state = initialBreakdown, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case types.SELECT_ACCOUNT: return action.payload.data;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'calendarEvents' ) {
                const { breadcrumbs } = action.additionalData.categories;
                const originalEvents = build( action.payload.data );
                const events = originalEvents.filter( x => breadcrumbs.having( 'breadcrumb', x.categoryNode ).length > 0 );
                return countEvents( events );
            // return build( action.payload.data );
            }
            return state;
        default: return state;
    }
};

const selectedEventReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return {};
        case types.SELECT_ACCOUNT: return {};
        case types.CLEAR_SELECTED_EVENT: return {};
        case types.SELECT_EVENT:
            return action.payload.data;
        default: return state;
    }
};

const recurrenceDefinitionReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return {};
        case types.SELECT_ACCOUNT: return {};
        case types.CLEAR_SELECTED_EVENT: return {};
        case types.GET_RECURRENCE_DEFINITION_COMPLETED:
            return action.payload.data;
        default: return state;
    }
};

const events = combineReducers( {
    calendarEvents: calendarEventsReducer,
    selectedEvent: selectedEventReducer,
    recurrenceDefinition: recurrenceDefinitionReducer,
    breakdown: calendarEventsBreakdownReducers
} );

export default events;


function countEvents( array ) {
    const counter = {
        high: 0,
        low: 0,
        medium: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        total: 0,
        week: 0
    };
    array.forEach( ( event ) => {
        if ( event.resolved ) {
            return;
        }
        counter.total += 1;
        if ( isBeforeToday( event.date ) ) {
        // event is overdue
            counter.overdue += 1;
            return;
        }
        if ( isThisWeek( event.date ) ) {
            // event is overdue
            counter.week += 1;
        }
        if ( isToday( event.date ) ) {
            // event is overdue
            counter.today += 1;
        }
        if ( isTomorrow( event.date ) ) {
            // event is overdue
            counter.tomorrow += 1;
        }
        if ( event.priority === 'low' ) {
            // event is overdue
            counter.low += 1;
        }
        if ( event.priority === 'medium' ) {
            // event is overdue
            counter.medium += 1;
        }
        if ( event.priority === 'high' ) {
            // event is overdue
            counter.high += 1;
        }
    } );
    return counter;
}
