import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import Display from '../Display';
const modalRoot = document.getElementById( 'modal-root' );
const ConfirmationModal = ( { message, open, action, toggle, title = null, hideActions, processing } ) => (
    ReactDOM.createPortal(
        <Modal isOpen={ open } toggle={ toggle }>
            <ModalHeader toggle={ toggle }>{ title || 'Confirm action'}</ModalHeader>
            <ModalBody>
                { message }
            </ModalBody>
            <Display when={ !hideActions && !processing }>
                <ModalFooter>
                    <Button color="info" onClick={ action }>Confirm</Button>
                    {' '}
                    <Button color="secondary" onClick={ toggle }>Cancel</Button>
                </ModalFooter>
            </Display>
            <Display when={ !hideActions && processing }>
                <ModalFooter>
                    <p>processing</p>
                </ModalFooter>
            </Display>
        </Modal>,
        modalRoot
    )
);

const { func, string, bool } = PropTypes;
ConfirmationModal.propTypes = {
    toggle: func.isRequired,
    action: func.isRequired,
    // inputChange: func.isRequired,
    // date: string.isRequired,
    // priority: string.isRequired,
    message: string.isRequired,
    open: bool.isRequired
};

export default ConfirmationModal;
