import * as React from 'react';

import { Badge, Collapse, ListGroupItem } from 'reactstrap';
import GroupItemsTable from './GroupItemsTable';
import { GroupService } from '../../../Services';
import Display from '../Display';


export default class ListItemGroup extends React.PureComponent {
    constructor( props ) {
        super( props );

        this.state = {
            collapse: false,
            edit: false,
            escalationTime: 1,
            escalationTimeType: 'day'
        };
        this.toggle = this.toggle.bind( this );
        this.saveChanges = this.saveChanges.bind( this );
        this.deleteUserFromGroup = this.deleteUserFromGroup.bind( this );
        this.deleteGroup = this.deleteGroup.bind( this );
    }

    componentWillMount() {
        const { time, type } = calculateEscalateTime( this.props.data.escalationTime );
        this.setState( {
            escalationTime: time,
            value: this.props.data.name,
            escalationTimeType: type
        } );
    }

    componentWillReceiveProps( nextProps ) {
        this.setState( { collapse: nextProps.open } );
    }

    toggle() {
        this.setState( { collapse: !this.state.collapse } );
    }

    deleteUserFromGroup( userId ) {
        const newGroup = { ...this.props.data };
        delete newGroup.members[userId];
        delete newGroup.updatedAt;
        delete newGroup.createdAt;
        this.props.updateGroup( newGroup );
    }

    deleteGroup() {
        const payload = { accountId: this.props.data.accountId, id: this.props.data.id };
        this.props.deleteGroup( payload );
    }

    saveChanges( ) {
        this.setState( { edit: false } );
        let time = Number( this.state.escalationTime );
        if ( this.state.escalationTimeType === 'day' || this.state.escalationTimeType === 'days' ) {
            time *= 24;
        }
        const data = { ...this.props.data, name: this.state.value, escalationTime: time };
        delete data.updatedAt;
        delete data.createdAt;
        this.props.saveGroup( data );
    }

    render() {
        const { group, members } = GroupService.constructGroupListData( this.props.data, this.props.isDefaultUser, this.props.currentUserId );
        return (
            <React.Fragment>
                <ListGroupItem style={ { border: 'none', padding: '5px 0 15px 30px' } } key={ this.props.data.id }>
                    <Display when={ this.state.edit }><hr style={ { margin: '10px 0' } } /></Display>
                    <div style={ { display: 'inline-block' } }>
                        <Display when={ this.state.collapse && !this.state.edit }>
                            <span className="fa fa-minus" style={ { position: 'absolute', left: 10, top: '23%', fontSize: 14 } } onClick={ this.toggle } />
                        </Display>
                        <Display when={ !this.state.collapse && !this.state.edit }>
                            <span className="fa fa-plus" style={ { position: 'absolute', left: 10, top: '23%', fontSize: 14 } } onClick={ this.toggle } />
                        </Display>
                        <Display when={ !this.state.edit }>
                            <span onClick={ this.toggle }><strong>{group}</strong>
                                <Display when={ this.props.data.escalation && !this.props.data.default }>
                                    <span> - Escalate policy: { calculateEscalateValue( this.props.data ) }</span>
                                </Display>
                            </span>
                        </Display>
                        <Display when={ this.state.edit }>
                            <div className="form-group">
                                <label htmlFor="escalateTimeframeValue">Group name: </label>
                                <Display when={ this.state.showNameError }>
                                    <div style={ { color: 'red', fontSize: 12, marginBottom: 15 } }>{this.state.error}</div>
                                </Display>
                                <input
                                    className="form-control"
                                    style={ { padding: '0 5px' } }
                                    key={ `name-${this.props.data.id}` }
                                    value={ this.state.value || group }
                                    onChange={ e => { e.preventDefault(); this.setState( { value: e.target.value } ); } } />
                            </div>
                            <Display when={ this.props.data.escalation }>
                                <div className="form-group">
                                    <label htmlFor="escalateTimeframeValue">Escalate before/after <span style={ { fontSize: 10 } }>( negative values permitted )</span>: </label>
                                    <div className="row">
                                        <div className="col-5 pr-0">
                                            <input
                                                className="form-control"
                                                name="escalationTime"
                                                type="number"
                                                value={ this.state.escalationTime }
                                                max={ 99 }
                                                onChange={ ( e ) => { this.setState( { escalationTime: e.target.value } ); } } />
                                        </div>
                                        <div className="col-7 pl-0">
                                            <select
                                                className="form-control"
                                                name="escalationTimeType"
                                                value={ this.state.escalationTimeType }
                                                onChange={ ( e ) => { this.setState( { escalationTimeType: e.target.value } ); } }>
                                                <option value="day">{this.state.escalationTime > 1 ? 'days' : 'day'}</option>
                                                <option value="hour">{this.state.escalationTime > 1 ? 'hours' : 'hour'}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Display>
                            <button className="btn btn-primary mr-3" onClick={ this.saveChanges }> Save </button>
                            <button className="btn btn-outline-secondary" onClick={ () => { this.setState( { edit: false } ); } }> Cancel </button>
                        </Display>
                    </div>

                    <div
                        style={ { display: 'inline-block',
                            float: 'right',
                            zIndex: 999 } }>
                        <Display when={ this.props.currentUserEmail !== group && !this.props.restrictedView && !this.props.data.default && this.props.data.name !== 'No Access' && this.props.data.name !== 'Users' && this.props.data.name !== 'Read Only' }>
                            <span
                                className="icon-trash"
                                style={ { marginRight: '10px', color: 'primary' } }
                                onClick={ this.deleteGroup }
                                role="presentation" />
                        </Display>
                        <Display when={ !this.props.restrictedView && !this.props.data.single && !this.props.data.default && this.props.data.name !== 'No Access' && this.props.data.name !== 'Users' && this.props.data.name !== 'Read Only' }>
                            <span
                                className="fa fa-edit"
                                style={ { marginRight: '10px', color: 'primary' } }
                                onClick={ () => { this.setState( { edit: !this.state.edit, collapse: false } ); } }
                                role="presentation" />
                        </Display>
                    </div>
                    <Display when={ this.state.edit }><hr style={ { margin: '10px 0' } } /></Display>
                </ListGroupItem>
                <Collapse isOpen={ this.state.collapse } style={ { marginTop: 5 } }>
                    <div>
                        <Display when={ !this.props.data.single }>
                            <React.Fragment>
                                <label style={ { fontSize: 13 } }>Group members</label>
                                <GroupItemsTable
                                    data={ members }
                                    groupName={ this.props.data.name }
                                    account={ this.props.account }
                                    deleteCallback={ this.deleteUserFromGroup }
                                    restrictedView={ this.props.restrictedView }
                                />
                            </React.Fragment>
                        </Display>
                        <label style={ { fontSize: 13, marginTop: 20 } }>Group access</label>
                        <Display when={ !this.props.restrictedView && !this.props.data.default && this.props.data.name !== 'No Access' && this.props.data.name !== 'Users' && this.props.data.name !== 'Read Only' && !this.props.data.escalation }>
                            <Badge
                                style={ { top: '4px', position: 'relative', float: 'right', cursor: 'pointer', color: 'primary' } }
                                onClick={ () => { this.props.editGroup( this.props.data ); } }
                                role="presentation">add resource
                            </Badge>
                        </Display>
                        {buildResources( this.props.data, this.props.resources, this.props.deleteResourceFromGroup, this.props.editResource, this.props.restrictedView )}
                    </div>
                </Collapse>

            </React.Fragment>
        );
    }
}

function buildResources( data, resources, deleteResource, editResource, restrictedView ) {
    if ( resources.isEmpty() ) {
        return <p className="text-center mt-2 mb-1">No data</p>;
    }
    return resources.map( resource => {
        const name = resource.prettyResourceName.split( '::' );
        let catName = '';
        let title = '';
        let resourceType = '';
        if ( name.length === 5 ) {
            // we are at doc level
            if ( name.last() === '*' ) {
                resourceType = name[name.length - 2].split( ':' )[0];
            } else {
                resourceType = name.last().split( ':' )[0];
            }
            catName = name[2].split( ':' )[1];
            title = name[3].split( ':' )[1];
        }
        if ( name.length === 4 ) {
            // we are at category level
            if ( name.last() === '*' ) {
                resourceType = name[name.length - 2].split( ':' )[0];
            } else {
                resourceType = name.last().split( ':' )[0];
            }
            title = name[2].split( ':' )[1];
        }
        if ( name.length === 3 ) {
            // we are at account level
            if ( name.last() === '*' ) {
                resourceType = name[name.length - 2].split( ':' )[0];
            } else {
                resourceType = name.last().split( ':' )[0];
            }
            title = name[1].split( ':' )[1];
        }
        return (
            <div style={ { marginBottom: 5, borderBottom: '1px solid #dadada', borderTop: '1px solid #dadada', paddingBottom: 5, paddingTop: 5 } } key={ Math.random() }>
                <div style={ { fontSize: 10, color: 'gray', position: 'relative' } }>Id: {resource.resourceId}
                    <Display when={ !restrictedView && !data.default && data.name !== 'No Access' && data.name !== 'Users' && data.name !== 'Read Only' && !data.escalation }>
                        <React.Fragment>
                            <span
                                className="icon-trash"
                                style={ { color: '#054d67', position: 'absolute', right: 25, top: 10, fontSize: 16, cursor: 'pointer' } }
                                onClick={ () => { deleteResource( data, resource.id ); } }
                                role="presentation" />
                            <span
                                className="fa fa-edit"
                                style={ { color: '#054d67', position: 'absolute', right: 0, top: 12, fontSize: 16, cursor: 'pointer' } }
                                onClick={ () => { editResource( data, resource.id ); } }
                                role="presentation" />
                        </React.Fragment>
                    </Display>
                </div>
                <div>
                Resource type: { resourceType }
                </div>
                <div>
                Title: { title }
                </div>
                <Display when={ resourceType !== 'category' && resourceType !== 'account' }>
                    <div>
                        Category: { catName }
                    </div>
                </Display>
                <div>
                Permissions: {resource.acl[0].actions.join( ', ' )}
                </div>
            </div>
        );
    } );
}

function calculateEscalateValue( data ) {
    let escalateValue = '';
    if ( typeof data.escalationTime === 'undefined' ) {
        return 'not defined';
    }
    const time = data.escalationTime;
    if ( time >= 24 ) {
        if ( time % 24 === 0 ) {
            escalateValue = `${Math.abs( time ) / 24} days after event expiration.`;
            if ( time === 24 ) {
                escalateValue = `${Math.abs( time ) / 24} day after event expiration.`;
            }
        } else {
            escalateValue = `${Math.abs( time )} hours days after event expiration.`;
        }
    }
    if ( time < 24 ) {
        if ( time <= 0 ) {
            if ( ( time * -1 ) % 24 === 0 ) {
                escalateValue = `${Math.abs( time ) / 24} days before event expiration.`;
            } else {
                escalateValue = `${Math.abs( time )} hours before event expiration.`;
            }
            if ( time > -10 && time < 0 ) {
                if ( time === -1 ) {
                    escalateValue = `${Math.abs( time )} hour before event expiration.`;
                } else {
                    escalateValue = `${Math.abs( time )} hours before event expiration.`;
                }
            }
        } else {
            escalateValue = `${Math.abs( time )} hours after event expiration.`;
        }
    }
    return escalateValue;
}

function calculateEscalateTime( hours ) {
    if ( ( hours < 24 && hours > 0 ) || ( hours > -24 && hours < 0 ) ) {
        return {
            time: hours,
            type: hours > 1 ? 'hour' : 'hour'
        };
    }
    if ( hours === 24 ) {
        return {
            time: 1,
            type: 'day'
        };
    }
    if ( hours === -24 ) {
        return {
            time: -1,
            type: 'day'
        };
    }
    if ( ( hours > 24 || hours < -24 ) && hours % 24 === 0 ) {
        return {
            time: hours / 24,
            type: 'day'
        };
    }
    return {
        time: hours,
        type: 'hour'
    };
}
