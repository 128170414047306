// import * as auth from './auth';
import * as firebase from './firebase';
import { firestore } from './firebase';
import { auth } from './firebase';

export {
    auth,
    firebase,
    firestore
};
