import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';

import HeaderDropdown from './HeaderDropdown';
import { applicationOperations } from '../../redux/ducks/application';
import { currentAccountOperations } from '../../redux/ducks/currentAccount';
import Breadcrumb from '../Breadcrumb';
import { uploadsOperations } from '../../redux/ducks/uploads';

const timeoutInMiliseconds = 43200000; // -12 hours
let timeoutId;

class Header extends React.Component {
    componentDidMount() {
        this.resetTimer();
        this.checkFocus(); // init
        window.addEventListener( 'focus', this.checkFocus );
        document.addEventListener( 'mousemove', this.resetTimer, false );
        document.addEventListener( 'mousedown', this.resetTimer, false );
        document.addEventListener( 'keypress', this.resetTimer, false );
        document.addEventListener( 'touchmove', this.resetTimer, false );
    }

    componentWillUnmount() {
        document.removeEventListener( 'mousemove', this.resetTimer, false );
        document.removeEventListener( 'mousedown', this.resetTimer, false );
        document.removeEventListener( 'keypress', this.resetTimer, false );
        document.removeEventListener( 'touchmove', this.resetTimer, false );
    }

    checkFocus = () => {
        if ( this.props.auth.currentUser ) {
            this.props.refreshToken( this.props.auth.currentUser );
        }
    };

    resetTimer = () => {
        window.clearTimeout( timeoutId );
        this.startTimer();
    };

    startTimer = () => {
        // window.setTimeout returns an Id that can be used to start and stop a timer
        timeoutId = window.setTimeout( this.doInactive, timeoutInMiliseconds );
    };

    doInactive = () => {
        this.props.logout();
        this.props.clear();
    };

    sidebarToggle( e ) {
        e.preventDefault();
        document.body.classList.toggle( 'sidebar-hidden' );
    }

    sidebarMinimize( e ) {
        e.preventDefault();
        document.body.classList.toggle( 'sidebar-minimized' );
    }

    mobileSidebarToggle( e ) {
        e.preventDefault();
        document.body.classList.toggle( 'sidebar-mobile-show' );
    }

    asideToggle( e ) {
        e.preventDefault();
        document.body.classList.toggle( 'aside-menu-hidden' );
    }

    render() {
        return (
            <header className="app-header navbar">
                <div/>
                <img
                    src={ this.props.currentAccount.logo || '/img/mycontracts-logo-3-long-v3.svg' }
                    alt="mycontracts"
                    className="logo" />
                <div className="mini" />
                <Nav className="userDropdown" navbar>
                    <HeaderDropdown
                        history={ this.props.history }
                        logout={ this.props.logout }
                        accounts={ this.props.accounts }
                        currentUser={ this.props.currentUser }
                        selectAccount={ this.props.selectAccount }
                        saveNewToken={ this.props.saveNewToken }
                        auth={ this.props.auth }
                    />
                </Nav>
            </header>
        );
    }
}

Header.propTypes = {
    history: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    saveNewToken: PropTypes.func.isRequired,
    selectAccount: PropTypes.func.isRequired,
    accounts: PropTypes.array.isRequired,
};

const mapStateToProps = state => ( {
    accounts: state.accounts,
    currentAccount: state.currentAccount,
} );

const mapDispatchToProps = dispatch => ( {
    logout: ( ) => dispatch( applicationOperations.logout( ) ),
    clear: ( ) => dispatch( uploadsOperations.clear( ) ),
    saveNewToken: ( data ) => dispatch( applicationOperations.saveNewToken( data ) ),
    refreshToken: ( data ) => dispatch( applicationOperations.refreshToken( data ) ),
    selectAccount: ( data ) => dispatch( currentAccountOperations.selectAccount( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Header );
