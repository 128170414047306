import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';
import { buildUserPermissions } from '../currentUser/utils';

const initialState = {
    usersPermissions: {}
};

const currentAccount = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case types.SELECT_ACCOUNT: return action.payload.data;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'accounts' ) {
                // replace old current account data with newly changed value.
                if ( Object.keys( state ).hasItems() && state.id ) {
                    const dataset = { ...build( action.payload.data ).having( 'id', state.id )[0] || {}, usersPermissions: {} };
                    try {
                        Object.keys( dataset.members ).map( memberId => {
                            if ( dataset.members[memberId].deleted ) {
                                delete dataset.members[memberId];
                            }
                        } );
                    } catch ( e ) {
                        console.error( e );
                    }
                    return dataset;
                }
                if ( action.payload.data.empty ) {
                    return initialState;
                }
                return { ...build( action.payload.data )[0], usersPermissions: {} };
            }
            if ( action.payload.target === 'resources' ) {
                const { groups } = action.additionalData;
                const resources = typeof action.payload.data !== 'undefined' ? build( action.payload.data ) : [];
                return { ...state, usersPermissions: buildUserPermissions( groups, resources ) };
            }
            return state;
        case types.DELETE_ACCOUNT_COMPLETED:
            return {};
        default: return state;
    }
};

export default currentAccount;
