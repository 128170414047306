import React, {Component} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';
import {Link} from 'react-router-dom'

import RecaptchaV3 from '../../../components/Recaptcha/RecaptchaV3';
import Display from '../../../components/Display';

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {modalOpen: true, resetState: '', recaptchaScore: 0};
    this.history = this.props.history;
    this.handleOpen = () => this.setState({modalOpen: true});
    this.handleClose = () => this.setState({modalOpen: false});
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResult = this.handleResult.bind(this);
  }

  handleOpen = () => this.setState({modalOpen: true})
  handleChange = (e) => this.setState({[e.target.name]: e.target.value.toLocaleLowerCase()})

  handleClose = () => {
    this.setState({modalOpen: false});
    this.history.push('/');
  };

  handleResult = (result) => {
    // console.info('RecaptchaVerify Result:', result);
    this.setState({recaptchaScore: result.score});
  };

  handleSubmit = () => {
    this.setState({
      submitting: true, error: null, errorCode: null, resetState: 'requesting reset ...'
    });
    const {password, email} = this.state;
    this.setState({submittedPassword: password, submittedEmail: email});
    const self = this;
    this.props.auth.sendPasswordResetEmail(this.state.email).then(() => {
      self.setState({
        success: true,
        errorMessage: '',
        error: false,
        resetState: 'Reset success ! Check your Email for the reset link ...'
      });
      self.setState({submitting: false});
    }).catch((error) => {
      console.error(error);
      self.setState({
        errorMessage: error.message, success: false, error: true, errorCode: error.code, resetState: ''
      });
      self.setState({submitting: false});
    });
  }

  render() {
    const {errorMessage} = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Display when={process.env.host !== 'http://localhost:8080/'}>
          <RecaptchaV3 action='Reset' handleResult={this.handleResult}/>
        </Display>
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <img
                style={{
                  marginBottom: '50px', display: 'block', height: 'auto', width: 'auto'
                }}
                src="/img/mycontracts-logo-3-long-v3.svg"
                alt="mycontracts_logo"/>
            </Col>
          </Row>
          <Display when={this.state.recaptchaScore > 0.5 || process.env.host==='http://localhost:8080/' }>
            <Row className="justify-content-center">
              <Col md="6">
                <Card className="mx-4">
                  <CardBody className="p-4">
                    <h1>Reset Password</h1>
                    <p className="text-muted">Request password reset</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="append"><i className="icon-email">@</i></InputGroupAddon>
                      <Input className="insideIcon" name="email" type="email" placeholder="your.email.com"
                             onChange={this.handleChange}/>
                    </InputGroup>
                    <Row>
                      <Col xs="12">
                        <p style={{color: 'blue'}}>{this.state.resetState}</p>
                      </Col>
                      <Col xs="12">
                        <p style={{color: 'red'}}>{errorMessage}</p>
                      </Col>
                      <Col xs="12">
                        <Button className="text-white bg-primary" block onClick={this.handleSubmit}>Reset</Button>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="bg-white py-5" style={{margin: "44px"}}>
                    <Row>
                      <Col xs="12" sm="6">
                        <Link className="text-primary" to="/login">Go to Login</Link>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Display>
          <Display when={this.state.recaptchaScore <= 0.5}>
            <div/>
          </Display>
        </Container>
      </div>
    );
  }
}

export default Reset;
