import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button,
    Input,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import Display from '../../../components/Display';
import { messageOperations } from '../../../redux/ducks/messages';
import passwordStrengthMeter from '../../../utils/passwordStrengthMeter';
import PasswordInputWithStrength from '../../../components/PasswordStrengthInput/PasswordInputWithStrength';

class ResetConfirmation extends Component {
    constructor( props ) {
        super( props );
        this.state = { modalOpen: true, resetState: '', showForm: false, error: null };
        this.history = this.props.history;
        this.handleChange = this.handleChange.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
    }

    UNSAFE_componentWillMount() {
        const that = this;
        if ( this.props.location.search.split( '&' )[0].split( '=' )[1] === 'resetPassword' ) {
            const code = this.props.location.search.split( '&' )[1].split( '=' )[1];
            const apiKey = this.props.location.search.split( '&' )[2].split( '=' )[1];

            firebase.auth().verifyPasswordResetCode( code )
                .then( ( email ) => {
                    // Display a "new password" form with the user's email address
                    that.setState( { code, apiKey, showForm: true } );
                } )
                .catch( ( err ) => {
                    // Invalid code
                    that.setState( { showForm: false, error: err.message } );
                } );
        }
    }

  handleChange = ( e ) => this.setState( { [e.target.name]: e.target.value } )

  handleSubmit = () => {
      this.setState( {
          submitting: true, error: null, errorCode: null, resetState: 'changing password ...'
      } );
      const { passwordChanged, history } = this.props;
      const { password, passwordConf, code } = this.state;
      if ( password === passwordConf ) {
          const resp = passwordStrengthMeter.test( password );
          if ( resp.errors.length > 0 ) {
              this.setState( {
                  error: resp.errors.map( error => (
                      <span
                          style={ {
                              color: 'red',
                              display: 'block'
                          } }>{error}
                      </span>
                  ) ),
                  submitting: false,
                  resetState: null
              } );
              return;
          }
          firebase.auth().confirmPasswordReset( code, password )
              .then( () => {
                  // Success
                  passwordChanged();
                  setTimeout( () => { history.replace( '/login' ); }, 1500 );
              } )
              .catch( ( err ) => {
                  // Invalid code
                  this.setState( { errorMessage: err.message, submitting: false, resetState: null } );
              } );
      } else {
          this.setState( { error: 'Passwords do not match', submitting: false, resetState: null } );
      }
  };

  render() {
      const { errorMessage } = this.state;

      return (
          <div className="app flex-row align-items-center">
              <Container>
                  <Row className="justify-content-center">
                      <Col md="5">
                          <img
                              style={ {
                                  marginBottom: '50px', display: 'block', height: 'auto', width: 'auto'
                              } }
                              src="/img/mycontracts-logo-3-long-v3.svg"
                              alt="mycontracts_logo" />
                      </Col>
                  </Row>
                  <Row className="justify-content-center">
                      <Col md="6">
                          <Card className="mx-4">
                              <Display when={ this.state.showForm }>
                                  <CardBody className="p-4">
                                      <h1>Change password</h1>
                                      <p className="text-muted">Please input password twice.</p>
                                      <PasswordInputWithStrength
                                          onChange={ ( value ) => { this.handleChange( { target: { name: 'password', value } } ); } }
                                          error={ this.state.error } />
                                      <InputGroup className={ `${this.state.error ? 'border-red' : ''} mb-3` }>
                                          <InputGroupAddon addonType="append"><i className="icon-lock" /></InputGroupAddon>
                                          <Input className="insideIcon" name="passwordConf" type="password" onChange={ this.handleChange } />
                                      </InputGroup>
                                      <Row>
                                          <Col xs="12">
                                              <Col xs="12">
                                                  <p style={ { color: 'blue' } }>{this.state.resetState}</p>
                                              </Col>
                                              <Col xs="12">
                                                  <p style={ { color: 'red' } }>{errorMessage}</p>
                                              </Col>
                                          </Col>
                                          <Col xs="12">
                                              <Button className="text-white bg-primary" block onClick={ this.handleSubmit }>Change password</Button>
                                          </Col>

                                      </Row>
                                  </CardBody>
                              </Display>
                              <Display when={ this.state.error }>
                                  <Col xs="12">
                                      <p style={ { color: 'red' } }>{this.state.error}</p>
                                  </Col>
                              </Display>

                              <CardFooter className="bg-white py-5" style={ { margin: '44px' } }>
                                  <Row>
                                      <Col xs="12" sm="6">
                                          <Link className="text-primary" to="/login">Go to Login</Link>
                                      </Col>
                                  </Row>
                              </CardFooter>
                          </Card>
                      </Col>
                  </Row>
              </Container>
          </div>
      );
  }
}

const mapDispatchToProps = dispatch => ( {
    passwordChanged: ( ) => dispatch( messageOperations.showSuccess( 'Password changed' ) )
} );

export default connect( null, mapDispatchToProps )( ResetConfirmation );
