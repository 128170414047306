
export const determineCurrentUser = ( action, groups = [], user = {}, resources = [], state ) => {
    let isadmin = false;
    let isowner = false;
    let isuser = false;
    let isreadonly = false;
    let isDefault = true;
    let canCreate = false;
    let acls = [];
    let userResources = [];
    let prettyPermissions = [];
    if ( typeof action.additionalData !== 'undefined' ) {
        if ( groups.isEmpty() || resources.isEmpty() ) {
            return Object.assign(
                {},
                state,
                { ...user },
                { invites: state ? state.invites : [], acl: acls, isAdmin: isadmin, isOwner: isowner, isUser: isuser, isReadOnly: isreadonly, canCreate, isDefault, prettyPermissions }
            );
        }
        const userGroups = groups.filter( group => typeof group.members[user.id] !== 'undefined' );
        const defaultGroup = userGroups.having( 'name', 'Owners and admins' )[0];
        if ( userGroups.length > 0 ) {
            // If he already has access to the entire account ||
            // direct higher access to that resource, then that acl is not taken into consideration.

            const escalationGroups = userGroups.filter( x => x.escalation );
            const normalGroups = userGroups.filter( x => !x.escalation );
            const normalGroupsResources = normalGroups.map( group => group.resources ).flatten().map( id => resources.having( 'id', id ) ).flatten();
            let escalationGroupsResources = escalationGroups.map( group => group.resources ).flatten().map( id => resources.having( 'id', id ) ).flatten();

            if ( normalGroupsResources.filter( group => group.resourceId.indexOf( 'category:all::*' > -1 ) )
              || normalGroupsResources.filter( group => group.resourceId === `rn::account:${action.additionalData.currentAccount.id}::*` )
            ) {
                escalationGroupsResources = [];
            } else {
                escalationGroupsResources = escalationGroupsResources.filter( resource => {
                    const data = resource.resourceId.split( '::' );
                    if ( data.length > 3 ) {
                        if ( data[2].split( ':' )[0] === 'category' ) {
                            const cat = data[2].split( ':' )[1];
                            return !normalGroupsResources.filter( group => group.resourceId.indexOf( cat ) > -1 ).length > 0;
                        }
                    }
                    return true;
                } );
            }
            userResources = [ ...normalGroupsResources, ...escalationGroupsResources ];
            acls = userResources.map( resource => resource.acl ).flatten();
        }
        prettyPermissions = composePermissionsList( userGroups, userResources );
        if ( typeof defaultGroup !== 'undefined' && typeof defaultGroup.members[user.id] !== 'undefined' ) {
            if ( defaultGroup.members[user.id].role === 'Owner' ) {
                isowner = true;
                canCreate = true;
            } else {
                isadmin = true;
                canCreate = true;
            }
            isDefault = false;
        } else {
            // check here is user is user or read only.
            if ( userGroups.having( 'name', 'Read Only' ).length > 0
            || userHasOnlyReadOnlyPermissions( acls ) ) {
                isuser = false;
                canCreate = false;
                isreadonly = true;
                isDefault = false;
                isadmin = false;
                isowner = false;
            }
            if ( userGroups.having( 'name', 'Users' ).length > 0
            || canUserModifyThings( acls ) ) {
                isuser = true;
                canCreate = false;
                isreadonly = false;
                isDefault = false;
                isadmin = false;
                isowner = false;
            }
            if ( userGroups.having( 'name', 'Users' ).length > 0
            || canUserCreate( acls ) ) {
                isuser = true;
                canCreate = true;
                isreadonly = false;
                isDefault = false;
                isadmin = false;
                isowner = false;
            }
        }
    }
    return Object.assign(
        {},
        state,
        { ...user },
        {
            invites: state ? state.invites : [],
            acl: acls,
            isAdmin: isadmin,
            isOwner: isowner,
            isUser: isuser,
            canCreate,
            isReadOnly: isreadonly,
            isDefault,
            prettyPermissions
        }
    );
};


function composePermissionsList( groups, userResources ) {
    return userResources.map( res => {
        let resource = '';
        let type = 'account';
        if ( res.resourceId.contains( 'category:' ) ) {
            resource = res.prettyResourceName.split( '::' )[2].split( ':' )[1];
            type = 'category';
        } else {
            resource = res.prettyResourceName.split( '::' )[1].split( ':' )[1];
        }
        return {
            resource,
            access: res.acl[0].actions.join( ', ' ),
            group: groups.having( 'id', res.groupId )[0].name,
            type
        };
    } );
}


function userHasOnlyReadOnlyPermissions( acls ) {
    return acls.filter(
        acl => acl.actions[0] === 'read'
    ).hasItems();
}

function canUserModifyThings( acls ) {
    return acls.filter(
        acl => acl.actions.contains( 'create' ) || acl.actions.contains( 'delete' ) || acl.actions.contains( 'update' )
    ).hasItems();
}
function canUserCreate( acls ) {
    return acls.filter(
        acl => acl.actions.contains( 'create' )
    ).hasItems();
}
