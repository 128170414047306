import React from 'react';
import $ from 'jquery';
import calendar from 'fullcalendar'; // this is needed here even if it shows that it's not used
import 'fullcalendar/dist/fullcalendar.css';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { applicationOperations } from '../../redux/ducks/application';
import CalendarEventModal from '../../components/Modals/CalendarEventModal';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import { generateCan } from '../../utils/Can';
import canUserPerformAction from '../../utils/acls/canUserPerformAction';

const views = {
    month: 'month',
    year: 'year',
};

function buildEvents( array ) {
    return array.map( event => ( {
        title: event.name,
        start: moment( event.date ),
        resolved: event.resolved,
        resolvedBy: event.changedBy.email || '',
        resolveReason: event.reason || '',
        resolvedAt: event.updatedAt || '',
        meta: {
            data: event
        },
        className: event.resolved ? 'event-resolved' : '',
        color: event.resolved ? '#858585' : event.priority === 'low' ? '#53e69d' : event.priority === 'high' ? '#ff7676' : '#2cabe3',
        borderColor: event.priority === 'low' ? '#50ad67!important' : event.priority === 'high' ? '#c26060!important' : '#2281aa!important',
    } ) );
}

class Calendar extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            target: 'div',
            tooltipOpen: false
        };
    }

    calendar = null;

    initFullCalendar( domNode ) {
        const { view, events, selectable, header, customButtons, defaultDate, nowIndicator, locale } = this.props;
        moment.locale( locale );
        this.calendar = $( domNode ).fullCalendar( {
            view: views[view],
            events,
            selectable,
            header,
            customButtons,
            defaultDate,
            nowIndicator,
            displayEventTime: true,
            firstDay: 1,
            views: {
                basic: {
                // options apply to basicWeek and basicDay views
                },
                agenda: {
                // options apply to agendaWeek and agendaDay views
                },
                week: {
                // options apply to basicWeek and agendaWeek views
                    columnHeaderFormat: 'ddd DD/MM',
                },
                day: {
                // options apply to basicDay and agendaDay views
                }
            },
            timeFormat: 'HH:mm A',
            slotEventOverlap: false,
            eventLimit: 7,
            // dayClick: this.onDayClick,
            eventClick: this.onEventClick,
            eventMouseover: ( event, jsEvent, view ) => {
                if ( this.state.tooltipOpen ) {
                    this.setState( { tooltipOpen: false } );
                    setTimeout( () => {
                        this.setState( {
                            tooltipOpen: true,
                            target: jsEvent.target,
                            title: event.title,
                            resolved: event.resolved,
                            resolvedBy: event.resolvedBy || '',
                            resolveReason: event.resolveReason || '',
                            resolvedAt: event.resolvedAt || '',
                            dueDate: moment( event.meta.data.date ).format( 'DD MMM YYYY' ),
                            priority: event.meta.data.priority,
                            docName: event.meta.data.documentName,
                            docLink: `/edit-document?accountId=${this.props.currentAccount.id}&id=${event.meta.data.documentId}`
                        } );
                    }, 150 );
                } else {
                    this.setState( {
                        tooltipOpen: true,
                        target: jsEvent.target,
                        title: event.title,
                        resolved: event.resolved,
                        resolvedBy: event.resolvedBy || '',
                        resolveReason: event.resolveReason || '',
                        resolvedAt: event.resolvedAt || '',
                        dueDate: moment( event.meta.data.date ).format( 'DD MMM YYYY' ),
                        priority: event.meta.data.priority,
                        docName: event.meta.data.documentName,
                        docLink: `/edit-document?accountId=${this.props.currentAccount.id}&id=${event.meta.data.documentId}`
                    } );
                }
            }
        // locale,
        } );
    }

    destroyFullCalendar() {
        this.calendar.fullCalendar( 'destroy' );
    }

    // onDayClick = (date, jsEvent, view) => {
    //   const object = $(this);
    //   if (this.props.onDayClick) {
    //     this.props.onDayClick(date, jsEvent, view, object);
    //   }
    // };

    onEventClick = ( calEvent, jsEvent, view ) => {
        // if ( this.props.onEventClick ) {
        //     this.props.onEventClick( calEvent, jsEvent, view );
        // }
        const categoryName = calEvent.meta.data.categoryNode.indexOf( '/' ) > -1 ? calEvent.meta.data.categoryNode.split( '/' )[0] : calEvent.meta.data.categoryNode;
        const { abilities } = generateCan( this.props.currentUser.acl );
        const canSee = canUserPerformAction( 'read', abilities, this.props.currentAccount.id, categoryName );
        if ( !canSee ) {
            return;
        }
        this.props.clearSelectedEvent();
        this.props.selectEvent( calEvent.meta.data );

        setTimeout( () => {
            this.setState( { eventOpen: true } );
        }, 100 );
    };


    componentDidMount() {
        const { calendar } = this.refs;
        this.initFullCalendar( calendar );
        if ( this.props.calendarEvents.hasItems() ) {
            const events = buildEvents( this.props.calendarEvents );
            this.calendar.fullCalendar( 'addEventSource', events );
        }
    }

    componentWillUnmount() {
        this.destroyFullCalendar();
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        const events = buildEvents( nextProps.calendarEvents );
        // this.calendar.fullCalendar( 'changeView', views[nextProps.view] );
        // this.calendar.fullCalendar( 'gotoDate', nextProps.date );
        this.calendar.fullCalendar( 'removeEvents' );
        this.calendar.fullCalendar( 'addEventSource', events );
    }

    toggle = () => {
        this.setState( {
            tooltipOpen: !this.state.tooltipOpen
        } );
    };

    render() {
        return (
            <div className="animated fadeIn">
                { this.state.eventOpen
                    && (
                        <CalendarEventModal
                            toggle={ () => { this.setState( { eventOpen: false } ); } }
                            openState={ this.state.eventOpen }
                            showEdit
                        />
                    )
                }
                <div className="row">
                    <div className="col-lg-12 white-box">
                        <div className="card">
                            <div className="card-block">
                                <Tooltip
                                    placement="right"
                                    autohide={ false }
                                    isOpen={ this.state.tooltipOpen }
                                    target={ this.state.target }
                                    toggle={ this.toggle }>
                                    <div className="tooltipContent">
                                        <p><span className="label">Event: </span><span>{this.state.title}</span></p>
                                        <p><span className="label">Document: </span><Link className="text-primary" to={ this.state.docLink || '/calendar' }>{this.state.docName}</Link></p>
                                        <p><span className="label">Due Date: </span><span>{this.state.dueDate}</span></p>
                                        <p><span className="label">Priority: </span><span>{this.state.priority}</span></p>
                                        { this.state.resolved && (
                                            <React.Fragment>
                                                <p><span className="label">Resolved by: </span><span>{this.state.resolvedBy}</span></p>
                                                <p><span className="label">Resolved at: </span><span>{moment( this.state.resolvedAt ).format( 'DD/MM/YYYY HH:mm A' )}</span></p>
                                                <p><span className="label">Reason: </span><span>{this.state.resolveReason}</span></p>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Tooltip>
                                <div ref="calendar" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Calendar.defaultProps = {
    view: 'week',
    events: [],
    selectable: false,
    header: {
        left: '',
        center: 'title',
        right: 'today prev,next',
    },
    customButtons: {},
    defaultDate: null,
    nowIndicator: true,

};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    currentUser: state.currentUser,
    calendarEvents: state.events.calendarEvents
} );

const mapDispatchToProps = dispatch => ( {
    parseSubscription: ( data ) => dispatch( applicationOperations.parseSubscription( data ) ),
    clearSelectedEvent: ( data ) => dispatch( calendarEventsOperations.clearSelectedEvent( data ) ),
    selectEvent: ( data ) => dispatch( calendarEventsOperations.selectEvent( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Calendar );

// {
//   title: 'event1',
//     start: '2018-04-25T12:30:00',
//   color: 'red',
//   text: 'white'
// },
