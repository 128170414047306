import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    InputGroupAddon, Input, InputGroup
} from 'reactstrap';

import passwordStrengthMeter from '../../utils/passwordStrengthMeter';
import Display from '../Display';
import TooltipWrapper from '../Tooltip/Tooltip';

class PasswordInputWithStrength extends Component {
    constructor( props ) {
        super( props );
        this.state = {
        };
        this.handleChange = this.handleChange.bind( this );
    }

    componentDidMount() {
        const resp = passwordStrengthMeter.test( '' );
        const showTooltip = true;
        const strengthMessage = resp.errors.map( error => <span style={ { display: 'block', textAlign: 'left' } }>{error}</span> );
        this.setState( { strengthMessage, showTooltip } );
    }

    handleChange( e ) {
        if ( e.target.value !== '' ) {
            const resp = passwordStrengthMeter.test( e.target.value );
            let errorValue = 0;
            let color = 'red';
            const showTooltip = true;
            let strengthMessage = resp.errors.map( error => <span style={ { display: 'block', textAlign: 'left' } }>{error}</span> );
            switch ( resp.errors.length ) {
                case 5: errorValue = 0; break;
                case 4: errorValue = 15; break;
                case 3: errorValue = 30; color = '#ff7600'; break;
                case 2: errorValue = 60; color = '#ffd400'; break;
                case 1: errorValue = 80; color = '#ffd400'; break;
                case 0: errorValue = 100; color = '#95cc12'; strengthMessage = 'All good, strong password.'; break;
                default: break;
            }
            this.setState( { errorValue, color, strengthMessage, showTooltip } );
        }
        if ( e.target.value === '' ) {
            const errorValue = 0;
            const color = '#a9d6c5';
            const resp = passwordStrengthMeter.test( '' );
            const showTooltip = true;
            const strengthMessage = resp.errors.map( error => <span style={ { display: 'block', textAlign: 'left' } }>{error}</span> );
            this.setState( { errorValue, color, strengthMessage, showTooltip, password: e.target.value } );
        }
        this.props.onChange( e.target.value );
    }

    render() {
        const { error } = this.props;
        const { showTooltip, strengthMessage, errorValue, color } = this.state;

        return (
            <InputGroup className={ `${error ? 'border-red' : ''} mb-3` }>
                <InputGroupAddon addonType="append"><i className="icon-lock" /></InputGroupAddon>
                <Input
                    className="insideIcon"
                    autoComplete="new-password"
                    name="password"
                    type="password"
                    placeholder="password"
                    onChange={ this.handleChange }>
                </Input>
                <Display when={ showTooltip }>
                    <TooltipWrapper refId="passwordStrenghInfo" content={ strengthMessage } />
                </Display>
                <i id="passwordStrenghInfo" className="icon-info passwordStrengthInfo" style={ { color: `${color}` } } />
                <div className="passwordLiveValidatorBar" style={ { width: `${errorValue}%`, background: `${color}` } } />
            </InputGroup>

        );
    }
}

PasswordInputWithStrength.propTypes = {
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
};

export default PasswordInputWithStrength;
