/* eslint-disable no-labels,no-unused-labels,no-restricted-syntax */
import React, { Component } from 'react';
import { ReactiveBase, ResultList, DataSearch, DataController } from '@appbaseio/reactivesearch';
import PropTypes from 'prop-types';
import { flatten } from 'flat';
import { connect } from 'react-redux';
import qs from 'query-string';
import { Alert } from 'reactstrap';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import AlertsList from '../Alerts/AlertsList';
import { applicationOperations } from '../../redux/ducks/application';
import messageOperations from '../../redux/ducks/messages/operations';

// eslint-disable-next-line prefer-destructuring
const unflatten = flatten.unflatten;

class AlertSearch extends Component {
    constructor( props ) {
        super( props );
        this.state = { oldSearchString: '', searchString: '', cannotAccess: false, categories: [] };

        this.getPermittedCategories = this.getPermittedCategories.bind( this );
        this.customQuery = this.customQuery.bind( this );
    }

    UNSAFE_componentWillMount() {
        const params = qs.parse( window.location.search );
        if ( typeof params.id === 'undefined' || typeof params.accountId === 'undefined' ) {
            return;
        }
        if ( this.props.currentAccount.id !== params.accountId ) {
            const desiredAccount = this.props.accounts.having( 'id', params.accountId )[0];
            if ( typeof desiredAccount === 'undefined' ) {
                this.setState( { cannotAccess: true } );
            }
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        this.setState( { categories: this.getPermittedCategories( this.props.resources ) } );

        // user goes to /alerts after an denial of access.
        if ( nextProps.location.pathname === '/alerts' ) {
            if ( this.props.location.search.length > nextProps.location.search.length ) {
                this.setState( { cannotAccess: false } );
            }
        }
    }

  refreshEsQuery = () => {
      const oldString = this.state.oldSearchString;
      setTimeout( () => {
          this.setState( { searchString: '' } );
          this.setState( { searchString: oldString } );
      }, 2000 );
  };

  getPermittedCategories = ( resources ) => {
      let categories = [];

      if ( ( typeof ( resources ) === 'undefined' || resources.length === 0 ) ) return [ 'NO_CATEGORY_ACCESS!' ];

      resources.forEach( ( resource ) => {
          if ( resource.actions.filter( ( action ) => action === 'read' ).length > 0 ) {
              resource.subject.forEach( ( subject ) => {
                  const resArray = subject.split( '::' );
                  // check if access to all

                  if ( resArray.length === 3 ) {
                      categories.push( '*' );
                  }

                  if ( resArray.length > 3 ) {
                      if ( resArray[2].split( ':' )[0] === 'category' ) {
                          const cat = resArray[2].split( ':' )[1].toLowerCase();
                          if ( cat === 'all' ) {
                              categories = [ '*' ];
                          } else {
                              categories.push( cat );
                          }
                      }
                  }
              } );
          }
      } );
      return categories;
  };

  generateMatchPhrases = ( categories ) => {
      const phrases = [];
      categories.forEach( ( category ) => {
          phrases.push( {
              match_phrase: {
                  categoryNode: category
              }
          } );
      } );

      return phrases;
  };

  customQuery = ( fn ) => {
      // const categats = [...this.state.categories];
      const categats = fn();

      if ( categats.filter( cat => cat === '*' || cat === 'all' ).length > 0 ) {
          return {
              from: 1,
              size: 100,
              query:
          {
              wildcard: {
                  categoryNode: '*'
              }
          }
          };
      }

      const matchPhrases = this.generateMatchPhrases( categats );

      return {
          from: 1,
          size: 100,
          query:
        {
            bool:
            {
                should: matchPhrases
            }
        }
      };
  };

  buildRow = data => {
      const parsedData = data.map( entry => {
          const newVal = unflatten( entry, { delimiter: '_' } );
          newVal.id = entry._id;
          return newVal;
      } );

      return (
          <div itemRef="alertTable" style={ { marginTop: 30 } }>
              <AlertsList
                  externalData={ parsedData }
                  refreshEsQuery={ this.refreshEsQuery }
                  isSearch={ this.state.oldSearchString.length > 0 }
                  showFilter />
          </div>
      );
  };

  triggerRefreshToken = () => {
      if ( this.props.auth.currentUser ) {
          this.props.refreshToken( this.props.auth.currentUser, true );
      }
  };

  render() {
      const { currentAccount } = this.props;
      const sessionId = localStorage.getItem( 'sessionId' );
      if ( this.state.cannotAccess ) {
          return (
              <div className="col-lg-12">
                  <Alert color="danger">
            Sorry, you do not have access to this account
                  </Alert>
              </div>
          );
      }
      return (
          <ReactiveBase
              app="calendarevents"
              url={ `${process.env.elasticsearch_host}/search` }
              headers={ {
                  accountId: this.props.currentAccount.id,
                  categories: this.getPermittedCategories( this.props.resources ),
                  authorization: `Basic ${sessionId}`

              } }>

              <DataController
                  title="DataController"
                  componentId="AccountSearch"
                  dataField="accountId"
                  customQuery={
                      () => ( { match: { accountId: currentAccount.id || 'accountNotProvided' } } )
                  }
                  size={ 10 }
              >
              </DataController>

              <DataController
                  title="DataController"
                  componentId="AccountSearch"
                  dataField="accountId"
                  customQuery={
                      () => ( { match: { accountId: currentAccount.id || 'accountNotProvided' } } )
                  }
                  size={ 10 }
              >
              </DataController>
              <DataController
                  title="CategoryController"
                  componentId="CategorySearch"
                  dataField="categoryNode"
                  customQuery={ () => this.customQuery( () => this.getPermittedCategories( this.props.resources ) ) }
                  size={ 10 }
              >
              </DataController>

              <DataController
                  title="DataController"
                  componentId="AlertSoftDelete"
                  dataField="deleted"
                  customQuery={
                      () => ( { match: { deleted: true } } )
                  }
              >
              </DataController>
              <br />
              <div className="row">
                  <div className="col-md-12" id="alertSearchContainer">
                      <DataSearch
                          componentId="searchbox"
                          dataField={ [ 'name', 'documentName' ] }
                          placeholder="Search alerts by name or document"
                          defaultSelected={ this.state.searchString || '' }
                          onValueChange={ ( value ) => {
                              this.setState( { oldSearchString: value } );
                          }
                          }
                          suggestions={ false }
                          autosuggest={ false }
                          debounce={ 150 }
                          fuzziness="AUTO"
                          showClear
                          react={ {
                              and: [ 'searchbox', 'SearchResult', 'AccountSearch', 'CategorySearch' ],
                              not: [ 'AlertSoftDelete' ],
                          } }
                          // customQuery={ alertQuery }
                      />
                  </div>
              </div>
              <ResultList
                  onError={ ( err ) => {
                      if ( err.status === 403 ) {
                          if ( typeof document !== 'undefined' ) {
                              this.triggerRefreshToken();
                          }
                      }
                  } }
                  style={ { width: '100%' } }
                  componentId="SearchResult"
                  dataField="name"
                  from={ 0 }
                  size={ 15 }
                  onAllData={ this.buildRow }
                  className="result-list-container"
                  showResultStats={ false }
                  react={ {
                      and: [ 'searchbox', 'SearchResult', 'AccountSearch', 'CategorySearch' ],
                  } }
              />
          </ReactiveBase>
      );
  }
}

const { object } = PropTypes;

AlertSearch.defaultProps = {
    document: {},
    resources: []
};

AlertSearch.propTypes = {
    currentAccount: PropTypes.object.isRequired,
    document: object
};

const mapStateToProps = state => ( {
    resources: state.currentUser.acl,
    calendarEvents: state.events.calendarEvents,
    accounts: state.accounts,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    saveCalendarEvent: ( data ) => dispatch( calendarEventsOperations.saveCalendarEvent( data ) ),
    selectEvent: ( data ) => dispatch( calendarEventsOperations.selectEvent( data ) ),
    refreshToken: ( data, forced ) => dispatch( applicationOperations.refreshToken( data, forced ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( AlertSearch );
