import owasp from 'owasp-password-strength-test';

// Pass a hash of settings to the `config` method. The settings shown here are
// the defaults.
owasp.config( {
    maxLength: 128,
    minLength: 10,
    minOptionalTestsToPass: 5,
} );
const passwordStrengthMeter = owasp;

export default passwordStrengthMeter;
