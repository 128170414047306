/* eslint-disable no-labels,no-unused-labels,no-restricted-syntax */
import React, { Component } from 'react';
import {
    DataController,
    DataSearch,
    ReactiveBase,
    ResultCard,
    SelectedFilters,
    SingleList
} from '@appbaseio/reactivesearch';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import DateRange from '@appbaseio/reactivesearch/lib/components/date/DateRange';
import { Link } from 'react-router-dom';
import messageOperations from '../../redux/ducks/messages/operations';
import { applicationOperations } from '../../redux/ducks/application';
import fetchHelper, { baseAxios } from '../../utils/fetchHelper';


class FileContentSearch extends Component {
    constructor( props ) {
        super( props );
        this.state = { oldSearchString: '', searchString: '', downloading: [] };
    }

    triggerRefreshToken = () => {
        if ( this.props.auth.currentUser ) {
            this.props.refreshToken( this.props.auth.currentUser, true );
        }
    };

    customQuery = term => {
        if ( !term ) {
            return { match_all: {} };
        }
        return {
            from: 1,
            size: 100,
            query:
        {
            wildcard: {
                'attachment.content': `${term.toLowerCase()}*`
            }
        }
        };
    };

    customQuery2( term ) {
        if ( !term ) {
            return { match_all: {} };
        }
        return { match: { 'payload.accountId': this.props.currentAccount.id || 'accountNotProvided' } };
    }


    buildHighLights = data => data.highlight['attachment.content'].map( highlights => (
        <p
            style={ { border: '1px solid rgb(178, 185, 191)', padding: 5, marginBottom: 2 } }
            dangerouslySetInnerHTML={ { __html: highlights } } />
    ) );

    download = file => {
        if ( this.state.downloading.contains( file.fileName ) ) {
            return;
        }
        this.setState( { downloading: [ ...this.state.downloading, file.fileName ] } );
        fetchHelper( 'requestDownloadUrl', 'POST', null, `{ "filename": "/${file.filePath}", "accountId": "${this.props.currentAccount.id}" }` )
            .then( ( result ) => {
                baseAxios()( {
                    url: result.data.downloadUrl,
                    method: 'GET',
                    responseType: 'blob', // important
                } ).then( ( response ) => {
                    const url = window.URL.createObjectURL( new Blob( [ response.data ] ) );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    link.setAttribute( 'download', file.fileName );
                    document.body.appendChild( link );
                    link.click();
                    this.setState( { downloading: this.state.downloading.filter( x => x !== file.fileName ) } );
                } );
            } )
        // next then()
            .catch( ( err ) => {
                this.setState( { downloading: this.state.downloading.filter( x => x !== file.fileName ) } );
                console.error( err );
            } );
    };

    buildCards = data => {
        const cards = data.filter( doc => typeof ( doc.highlight['attachment.content'] ) !== 'undefined' ).map( fileContent => (
            <div className="card">
                <div style={ { padding: 10 } }>
                    <div className="card-header  pt-0">
                        <div className="row">
                            <div className="col-xs-12 col-md-9">
                                <div className="card-title h4 mb-1">
                    Name: {fileContent.payload.fileName}
                                </div>
                                {/* <div className="card-title h5 teal">Content-Type: {fileContent.payload.contentType}</div> */}
                                <div className="card-title h7 mb-0">
                    Uploaded: {moment( fileContent.payload.uploaded ).format( 'DD MMM YYYY' )}
                                </div>
                                <div className="card-title h7 mb-0">
                    Entries: {fileContent.highlight['attachment.content'].length}
                                </div>
                                <div className="card-title h7 mb-0">
                    Category: {fileContent.payload.categoryNode}
                                </div>
                                {/* <div className="card-title h5 blue">Path: {fileContent.payload.filePath}</div> */}
                            </div>
                            <div className="col-xs-12 col-md-3 text-right">
                                <Button
                                    color="primary"
                                    onClick={ () => {
                                        this.download( fileContent.payload );
                                    } }>
Download
                                </Button>
                                <Link
                                    className="btn btn-outline-primary"
                                    id="edit-document"
                                    color="info"
                                    to={ `/edit-document?accountId=${fileContent.payload.accountId}&id=${fileContent.payload.documentId}` }>Show Doc
                                </Link>
                                {/* <Button color="secondary" outline onClick={ ()=>{} } style={ { marginLeft: 10 } }>View</Button> */}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.buildHighLights( fileContent )}
                    </div>
                    <div className="row mb-4">
                        <div className="col-xs-12 col-md-9">
                        </div>
                        <div className="col-xs-12 col-md-3 text-right">
                            <Button
                                color="primary"
                                onClick={ () => {
                                    this.download( fileContent.payload );
                                } }>
Download
                            </Button>
                            {/* <Button color="secondary" outline onClick={ ()=>{} } style={ { marginLeft: 10 } }>View</Button> */}
                        </div>
                    </div>
                </div>
            </div>
        ) );

        return cards;
    };

    getPermittedCategories = ( resources ) => {
        const categories = [];

        if ( ( typeof ( resources ) === 'undefined' || resources.length === 0 ) ) return [ 'NO_CATEGORY_ACCESS!' ];

        resources.forEach( ( resource ) => {
            if ( resource.actions.filter( ( action ) => action === 'read' ).length > 0 ) {
                resource.subject.forEach( ( subject ) => {
                    const resArray = subject.split( '::' );
                    // check if access to all

                    if ( resArray.length === 3 ) {
                        categories.push( '*' );
                    }

                    if ( resArray.length > 3 ) {
                        if ( resArray[2].split( ':' )[0] === 'category' ) {
                            const cat = resArray[2].split( ':' )[1];
                            categories.push( cat.toLowerCase() );
                        }
                    }
                } );
            }
        } );
        return categories;
    };

    render() {
        const { currentAccount, currentUser } = this.props;
        const sessionId = localStorage.getItem( 'sessionId' );
        return (
            <ReactiveBase
                app="attachments"
                url={ `${process.env.elasticsearch_host}/search` }
                headers={ {
                    accountId: this.props.currentAccount.id,
                    authorization: `Basic ${sessionId}`,
                    categories: this.getPermittedCategories( this.props.resources )
                } }>

                <DataController
                    title="DataController"
                    componentId="AccountSearch"
                    dataField="accountId"
                    customQuery={
                        () => ( { match: { 'payload.accountId': currentAccount.id || 'accountNotProvided' } } )
                    }
                >
                </DataController>
                <DataController
                    title="DataController"
                    componentId="FilesSearchSoftDelete"
                    dataField="deleted"
                    customQuery={
                        ( ) => ( { term: { 'payload.deleted': true } } )
                    }
                >
                </DataController>

                <br />
                <div className="row">
                    <div className="col-md-12">
                        <DataSearch
                            componentId="searchbox"
                            dataField="attachment.content"
                            className="result-list-container"
                            placeholder="Full text search"
                            customQuery={ ( value ) => this.customQuery( value ) }
                            defaultSelected={ this.state.searchString || '' }
                            onValueChange={ ( value ) => this.setState( { searchString: value } ) }
                            highlight
                            customHighlight={ ( props ) => ( {
                                highlight: {
                                    pre_tags: [ '<mark>' ],
                                    post_tags: [ '</mark>' ],
                                    fields: {
                                        'attachment.content': {}
                                    },
                                    number_of_fragments: 100,
                                    fragment_size: 100,
                                }
                            } ) }
                            suggestions={ false }
                            autosuggest={ false }
                            debounce={ 600 }
                            showClear
                            react={ {
                                and: [ 'searchbox', 'SearchResult', 'AccountSearch', 'DateSensor', 'ContentTypeSensor' ],
                                not: [ 'FilesSearchSoftDelete' ]

                            } }
                        />

                        <SingleList
                            componentId="ContentTypeSensor"
                            dataField="payload.contentType.raw"
                            title="Content Types"
                            size={ 100 }
                            sortBy="asc"
                            react={ {
                                and: [ 'searchbox', 'SearchResult', 'AccountSearch', 'DateSensor', 'ContentTypeSensor' ],
                                not: [ 'FilesSearchSoftDelete' ]

                            } }
                        />
                        {/* <DateRange */}
                        {/* componentId="DateSensor" */}
                        {/* dataField="payload.uploaded.raw" */}
                        {/* react={ { */}
                        {/* and: [ 'searchbox', 'SearchResult', 'AccountSearch', 'DateSensor', 'ContentTypeSensor' ] */}

                        {/* } } */}
                        {/* /> */}

                    </div>
                </div>
                <br />

                <br />

                <SelectedFilters
                    showClearAll
                    clearAllLabel="Clear filters"
                />
                <ResultCard
                    onError={ ( err ) => {
                        if ( err.status === 403 ) {
                            if ( typeof document !== 'undefined' ) {
                                this.triggerRefreshToken();
                            }
                        }
                    } }
                    dataField="payload.accountId"
                    style={ { width: '100%' } }
                    componentId="SearchResult"
                    from={ 0 }
                    size={ 5 }
                    className="reactiveSearchResults"
                    onAllData={ this.buildCards }
                    showResultStats
                    pagination
                    react={ {
                        and: [ 'searchbox', 'SearchResult', 'AccountSearch', 'DateSensor', 'ContentTypeSensor' ],
                        not: [ 'FilesSearchSoftDelete' ]
                    } }
                />
            </ReactiveBase>
        );
    }
}

FileContentSearch.defaultProps = {};

FileContentSearch.propTypes = {
    currentAccount: PropTypes.object.isRequired
};

const mapStateToProps = state => ( {
    resources: state.currentUser.acl,
    currentAccount: state.currentAccount,
    currentUser: state.currentUser
} );

const mapDispatchToProps = dispatch => ( {
    refreshToken: ( data, forced ) => dispatch( applicationOperations.refreshToken( data, forced ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( FileContentSearch );
