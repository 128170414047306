/* eslint-disable no-labels,no-unused-labels,no-restricted-syntax */
import React, { Component } from 'react';
import { ReactiveBase, ResultList, DataSearch, DataController } from '@appbaseio/reactivesearch';
import PropTypes from 'prop-types';
import { flatten } from 'flat';
import { connect } from 'react-redux';
import PartiesModal from '../../views/Parties/PartiesModal';
import { partiesOperations } from '../../redux/ducks/parties';
import PartyList from '../Party/PartiesList';
import messageOperations from '../../redux/ducks/messages/operations';
import { applicationOperations } from '../../redux/ducks/application';

// eslint-disable-next-line prefer-destructuring
const unflatten = flatten.unflatten;

class PartySearch extends Component {
    constructor( props ) {
        super( props );
        this.state = { oldSearchString: '', searchString: '' };
    }

    refreshEsQuery = () => {
        const oldString = this.state.oldSearchString;
        setTimeout( () => {
            this.setState( { searchString: `${oldString} ` } );
            this.setState( { searchString: oldString } );
        }, 2000 );
    };

    buildRow = data => {
        const parsedData = data.map( entry => {
            const newVal = unflatten( entry, { delimiter: '_' } );
            newVal.id = entry._id;
            delete newVal[0];
            delete newVal.objectID;
            return newVal;
        } );

        return (
            <div itemRef="partyTable">
                <PartyList
                    externalData={ parsedData }
                    refreshEsQuery={ this.refreshEsQuery }
                    isSearch={ this.state.oldSearchString.length > 0 } />
            </div>
        );
    };

    triggerRefreshToken = () => {
        if ( this.props.auth.currentUser ) {
            this.props.refreshToken( this.props.auth.currentUser, true );
        }
    };

    getPermittedCategories = (resources) => {
    const categories = [];

    if ((typeof (resources) === "undefined" || resources.length === 0)) return ['NO_CATEGORY_ACCESS!'];

    resources.forEach((resource) => {

      if (resource.actions.filter((action) => action === 'read' ).length > 0) {


        resource.subject.forEach( (subject) => {

          const resArray = subject.split("::");
          // check if access to all

          if (resArray.length === 3) {
            categories.push('*');
          }

          if (resArray.length > 3) {

            if (resArray[2].split(':')[0] === 'category') {
              const cat = resArray[2].split(':')[1];
              categories.push(cat.toLowerCase());
            }
          }

        });
      }

    });
    return categories;
  };

    render() {
        const { currentAccount, currentUser } = this.props;
        const sessionId = localStorage.getItem( 'sessionId' );
        return (
            <ReactiveBase
                app="parties"
                url={ `${process.env.elasticsearch_host}/search` }
                headers={ {
                    accountId: this.props.currentAccount.id,
                    authorization: `Basic ${sessionId}`,
                    categories: this.getPermittedCategories(this.props.resources)
                } }>

                <DataController
                    title="DataController"
                    componentId="AccountSearch"
                    dataField="accountId"
                    customQuery={
                        ( ) => ( { match: { accountId: currentAccount.id || 'accountNotProvided' } } )
                    }
                >
                </DataController>

                <DataController
                    title="DataController"
                    componentId="PartySearchSoftDelete"
                    dataField="deleted"
                    customQuery={
                        ( ) => ( { match: { deleted: true } } )
                    }
                >
                </DataController>
                <br />
                <div className="row">
                    <div className="col-md-12" id="partiesSearchContainer">
                        <DataSearch
                            componentId="searchbox"
                            dataField={ [ 'partyName', 'contactPerson', 'contactPersonEmail' ] }
                            placeholder="Search by party name or contact"
                            defaultSelected={ this.state.searchString || '' }
                            onValueChange={ ( value ) => this.setState( { oldSearchString: value } ) }
                            suggestions={ false }
                            autosuggest={ false }
                            debounce={ 150 }
                            fuzziness="AUTO"
                            showClear
                            react={ {
                                and: [ 'searchbox', 'SearchResult', 'AccountSearch' ],
                                not: [ 'PartySearchSoftDelete' ]
                            } }
                        />
                    </div>
                </div>
                <br />
                { !currentUser.isReadOnly
                    && <PartiesModal />
                }
                <br />
                <ResultList
                    onError={ ( err ) => {
                        if ( err.status === 403 ) {
                            if ( typeof document !== 'undefined' ) {
                                this.triggerRefreshToken();
                            }
                        }
                    } }
                    style={ { width: '100%' } }
                    componentId="SearchResult"
                    dataField="name"
                    from={ 0 }
                    size={ 15 }
                    onAllData={ this.buildRow }
                    className="result-list-container white-box"
                    showResultStats={ false }
                    // pagination
                    react={ {
                        and: [ 'searchbox', 'SearchResult', 'AccountSearch' ],
                        not: [ 'PartySearchSoftDelete' ]
                    } }
                />
            </ReactiveBase>
        );
    }
}


const { object } = PropTypes;

PartySearch.defaultProps = {

};

PartySearch.propTypes = {
    currentAccount: PropTypes.object.isRequired
};

const mapStateToProps = state => ( {
    // calendarEvents: state.events.calendarEvents
    resources: state.currentUser.acl,
    currentAccount: state.currentAccount,
    currentUser: state.currentUser
} );

const mapDispatchToProps = dispatch => ( {
    selectParty: ( data ) => dispatch( partiesOperations.selectParty( data ) ),
    refreshToken: ( data, forced ) => dispatch( applicationOperations.refreshToken( data, forced ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( PartySearch );
