
import * as Sentry from '@sentry/browser';

import actions from './actions';
import messageOperations from '../messages/operations';
import db from '../../../utils/indexDB';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';

// This is a link to an action defined in actions.js.
const { saveNewToken, setFilter } = actions;
let interval = '';

const logToSentryAccount = ( currentAccount ) => {
    Sentry.addBreadcrumb( {
        category: 'currentAccount',
        message: `Account ID: ${currentAccount.accountId} | Account Name: ${currentAccount.accountName}`,
        level: 'info'
    } );
    Sentry.configureScope( ( scope ) => {
        scope.setExtra( 'accountName', currentAccount.accountName );
        scope.setExtra( 'accountId', currentAccount.accountId );
    } );
};

const parseSubscription = ( data ) => ( dispatch, getState ) => {
    const { currentAccount, groups, currentUser, resources, categories, application } = getState();
    if ( data.data.size === 0 ) {
        console.info( `no data found for ${data.target}` );
    }
    logToSentryAccount( currentAccount );
    // console.info('parseSubscription', currentUser, currentAccount)
    try {
        window.Intercom( 'boot', {
            app_id: 'idj2qwhm',
            name: currentUser ? currentUser.displayName : '', // Full name
            email: currentUser ? currentUser.email : '', // Email address
            account: currentAccount ? currentAccount.id : '',
            accountName: currentAccount ? currentAccount.accountName : '',
            company: currentAccount ? currentAccount.company : '',
            user_hash: application.intercom.data ? application.intercom.data.signature : ''
        } );
    } catch ( e ) {
        console.error( e );
    }

    dispatch( actions.parseSubscription( data, { currentAccount, groups, currentUser, resources, categories } ) );
};

const logSentryUser = ( data ) => {
    Sentry.configureScope( ( scope ) => {
        scope.setUser( { email: data.user.email } );
        scope.setUser( { id: data.user.uid } );
    } );
};

const login = ( data ) => ( dispatch, getState ) => {
    dispatch( actions.login( data ) );
    db.open().then( () => db.items.clear().then( ) );
    data.user.getIdToken( true ).then( ( accessToken ) => {
        dispatch( actions.saveNewToken( accessToken ) );
        setTimeout( () => {
            // need a bit of time before currentUser is available
            logSentryUser( data );

            window._paq.push( [ 'setUserId', data.user.email ] );
            window._paq.push( [ 'trackPageView' ] );
            window._paq.push( [ 'enableLinkTracking' ] );

            window.Intercom( 'boot', {
                app_id: 'idj2qwhm',
                name: data.user.displayName, // Full name
                email: data.user.email, // Email address
            } );
        }, 1000 );
    } );

    setTimeout( () => {
        dispatch( actions.verifyIntercomUser() ).then(
            ( res ) => {
                if ( typeof res === 'undefined' ) {
                    Sentry.captureException( new Error( 'verifyIntercomUser call returned undefined response' ), { extra: { ...data } } );
                } else {
                    dispatch( actions.saveIntercomSigniture( { data: res.data } ) );
                }
            },
            ( err ) => {
                console.error( err );
            }
        );
    }, 1000 );
};

const refreshToken = ( data, forced = false ) => ( dispatch, getState ) => {
    const sessionId = localStorage.getItem( 'sessionId' );
    if ( forced || typeof sessionId === 'undefined' || typeof getState().application.user.lastTokenUpdate === 'undefined' || getState().application.user.lastTokenUpdate < new Date().getTime() - 1800000 ) {
        // forced comes from _msearch queries that fail with 403. no need for interval here.
        data.getIdToken( true )
            .then( ( accessToken ) => {
                dispatch( actions.saveNewToken( accessToken ) );
            } );
    } else {
        interval = setInterval( () => {
            if ( !getState().application.user ) {
                return;
            }
            if ( typeof getState().application.user.lastTokenUpdate === 'undefined' || getState().application.user.lastTokenUpdate < new Date().getTime() - 1800000 ) {
                data.getIdToken( true )
                    .then( ( accessToken ) => {
                        dispatch( actions.saveNewToken( accessToken ) );
                    } );
            }
        }, 5000 );
    }
};

const checkToken = ( auth ) => ( dispatch, getState ) => {
    if ( !getState().application.user ) {
        return;
    }
    auth
        .onIdTokenChanged( ( user ) => {
            if ( !getState().application.user ) {
                return;
            }
            if ( typeof getState().application.user.lastTokenUpdate === 'undefined' || getState().application.user.lastTokenUpdate < new Date().getTime() - 1800000 ) {
                if ( user ) {
                    // User is signed in or token was refreshed.
                    user.getIdToken( true )
                        .then( ( accessToken ) => {
                            dispatch( actions.saveNewToken( accessToken ) );
                        } );
                }
            }
        } );
};

const logout = ( ) => ( dispatch ) => {
    dispatch( actions.logout( ) );
    db.open().then( () => db.items.clear().then( ) );
    clearInterval( interval );
    window._paq.push( [ 'resetUserId' ] );
};

const checkEmail = ( data ) => ( dispatch ) => new Promise( ( ( resolve, reject ) => {
    dispatch( actions.checkEmail( data ) )
        .then(
            ( res ) => {
                resolve( res );
                // dispatch( messageOperations.showSuccess( res.data.message ) );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const displayRegisteredMessage = () => ( dispatch ) => {
    dispatch( messageOperations.showSuccess( 'Registration completed. Please check your email address' ) );
};

export default {
    login,
    logout,
    parseSubscription,
    checkEmail,
    saveNewToken,
    refreshToken,
    displayRegisteredMessage,
    checkToken,
    setFilter
};
