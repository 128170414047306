import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button
} from 'reactstrap';

import fetchHelper from '../../../utils/fetchHelper';

class VerifyEmail extends Component {
    constructor( props ) {
        super( props );
        this.state = { fetching: false, status: 'verifying ...', isVerified: false, token: this.props.location.search.split( '=' )[1] || 'badTokenParameter007' };
        this.history = this.props.history;

        this.gotoLogin = this.gotoLogin.bind( this );
    }

    gotoLogin = () => {
      this.props.history.push('/login')
    };

    checkToken = ( token ) => fetchHelper( 'verifyToken', 'post', null, { token } )
        .then( ( result ) => {
            this.setState( { isVerified: true, status: 'verified', fetching: false } );
        } )
        .catch( err => {
            this.setState( { isVerified: false, status: 'invalid', fetching: false } );
        } );

    UNSAFE_componentWillMount() {
        this.setState( { fetching: true } );
        this.checkToken( this.state.token );
    }

    render() {
        const { isVerified, token, status } = this.state;

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="5">
                            <img
                                style={ {
                                    marginBottom: '50px', display: 'block', height: 'auto', width: 'auto'
                                } }
                                src="/img/mycontracts-logo-3-long-v3.svg"
                                alt="mycontracts_logo" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card className="mx-4">
                                <CardBody className="p-4">
                                    {!isVerified &&
                                  <h1 style={ { color: 'blue' } } >Verify Email</h1>
                                    }

                                    {isVerified &&
                                      <div>
                                          <Col xs="12">
                                              <h1 style={ { color: 'green' } }> Email verified</h1>
                                              <p> </p>
                                          </Col>
                                      </div>
                                    }

                                    <Col xs="12">
                                      Token <b>{token}</b>
                                    </Col>

                                    <Col xs="12">
                                      Status <b style={ { color: status === 'verified' ? 'green' : 'red' } }>{status}</b>
                                    </Col>

                                </CardBody>
                                <CardFooter className="bg-white py-5" >
                                    <Row>
                                        <Col xs="12" sm="6">
                                          <Button disabled={this.state.fetching === true } className="btn px-4 text-white bg-primary " onClick={this.gotoLogin} >Go to Login</Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default VerifyEmail;
