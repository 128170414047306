import { combineReducers } from 'redux';
import types from './types';
import applicationTypes from '../application/types';

const initialState = [];
function build( data ) {
    const array = [];
    data.forEach( ( doc ) => {
        array.push( { ...doc.data(), id: doc.id } );
    } );
    return array;
}
const documentTypesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'documentTypes' ) {
                return build( action.payload.data );
            }
            return state;
        default: return state;
    }
};

const selectedFolderReducer = ( state = null, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT:
            return null;
        case types.CHANGE_FOLDER_FOR_DEFAULT_OPTIONS:
            return action.payload;
        default:
            return state;
    }
};
//
const documentTypes = combineReducers( {
    list: documentTypesReducer,
    selectedFolder: selectedFolderReducer
} );

export default documentTypes;
