/* eslint-disable no-nested-ternary,import/no-named-as-default,import/no-named-as-default-member */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Detector } from 'react-detect-offline';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { engineName } from 'react-device-detect';
// import 'firebase/database';

// Components
import Account from '../../views/Account';
import Calendar from '../../views/Calendar';
import DocumentSelector from '../../views/Document';
import Vault from '../../views/Vault';
import CategoryView from '../../views/Vault/CategoryView';
import RouteWrapper from '../../RouteEnhancer';
import RestrictedRoute from '../../RestrictedRoute';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

import Footer from '../../components/Footer';
import Dashboard from '../../views/Dashboard';
import Category from '../../components/Category/Category';
import MessageCenter from '../../components/MessageCenter/MessageCenter';
import DocumentAdd from '../../components/Document/DocumentAdd';

import { applicationOperations } from '../../redux/ducks/application';
import AlertSearch from '../../components/Search/AlertSearch';
import PartiesView from '../../views/Parties';
import Profile from '../../components/Profile/Profile';
import { uploadsOperations } from '../../redux/ducks/uploads';
import OfflineModal from '../../components/Modals/OfflineModal';
import Display from '../../components/Display';
import { invitesOperations } from '../../redux/ducks/invites';
import InviteMessageModal from '../../components/Member/InviteMessageModal';
import FileContentSearch from '../../components/Search/FileContentSearch';
import ActivityList from '../../components/Activity/ActivityList';
import Groups from '../../components/Groups/Groups';
import Aside from '../../components/Aside';

let instance = false;
let addAccountDependantListenersCounter = 0;
const acceptedBrowserEngines = [ 'Gecko', 'WebKit', 'Blink' ];
class Full extends React.Component {
    constructor() {
        super();
        this.state = {
            createDemoAccountTriggered: false
        };
        this.accountsListener = () => {};
        this.usersListener = () => {};
        this.myInvitesListener = () => {};
        this.eventsCountListener = () => {};
        this.groupsListener = () => {};
        this.resourcesListener = () => {};
        this.categoriesListener = () => {};
        this.partiesListener = () => {};
        this.documentTypesListener = () => {};
        this.summariesListener = () => {};
        this.documentsListener = () => {};
        this.optionsListener = () => {};
        this.calendarEventsListener = () => {};
        this.invitesListener = () => {};
        this.unsubscribe = this.unsubscribe.bind( this );
    }

    UNSAFE_componentWillMount() {
        // this will clear the upload queue;
        this.props.clear();
        if ( this.props.currentUser ) {
            if ( typeof this.props.currentUser.termsAccepted === 'undefined' || !this.props.currentUser.termsAccepted ) {
                this.props.history.replace( '/terms-and-conditions' );
            }
        }
    }

    componentDidMount() {
        this.props.auth.onAuthStateChanged( ( user ) => {
            if ( user ) {
            } else if ( typeof document !== 'undefined' ) {
                this.props.history.replace( '/login' );
            }
        } );
        if ( this.props.user ) {
            // this will ensure that we set the listeners only once.
            if ( !instance ) {
                this.addDbListeners( this.props );
            }
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        const { user, currentUser } = nextProps;

        if ( !currentUser || typeof currentUser.id === 'undefined' ) {
            return;
        }
        if ( typeof currentUser.termsAccepted === 'undefined' || !currentUser.termsAccepted ) {
            this.props.history.replace( '/terms-and-conditions' );
            return;
        }

        if ( typeof document !== 'undefined' ) {
            if ( !user ) {
                window.localStorage.removeItem( 'currentUserId' );
                return;
            }
            if ( localStorage.getItem( 'currentUserId' ) !== user.uid ) {
                window.localStorage.setItem( 'currentUserId', user.uid );
            }
            if ( localStorage.getItem( 'sessionId' ) !== nextProps.user.accessToken ) {
                window.localStorage.setItem( 'sessionId', nextProps.user.user );
            }
        }
        if ( this.props.currentAccount.id !== nextProps.currentAccount.id ) {
            this.unsubscribe();
            this.addDbListeners( nextProps );
            this.addAccountDependantListeners( nextProps );
        }
        this.addAccountDependantListeners( nextProps );
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    unsubscribe() {
        instance = false;
        addAccountDependantListenersCounter = 0;
        this.accountsListener();
        this.usersListener();
        this.myInvitesListener();
        this.eventsCountListener();
        this.groupsListener();
        this.resourcesListener();
        this.categoriesListener();
        this.partiesListener();
        this.documentTypesListener();
        this.summariesListener();
        this.documentsListener();
        this.optionsListener();
        this.calendarEventsListener();
        this.invitesListener();
    }

    addDbListeners( nextProps ) {
        const { user } = nextProps;
        if ( user && typeof user.uid !== 'undefined' && typeof user.email !== 'undefined' && user.email ) {
            this.accountsListener = firebase.firestore().collection( 'accounts' )
                .where( `members.${user.uid}.email`, '==', user.email )
                .where( `members.${user.uid}.deleted`, '==', false )
                .where( 'deleted', '==', false )
                .onSnapshot( ( querySnapshot ) => {
                    this.props.parseSubscription( { target: 'accounts', data: querySnapshot } );
                } );
            this.usersListener = firebase.firestore().collection( 'users' )
                .where( 'uid', '==', user.uid )
                .onSnapshot( ( querySnapshot ) => {
                    this.props.parseSubscription( { target: 'currentUser', data: querySnapshot } );
                } );
            this.myInvitesListener = firebase.firestore().collection( 'invites' )
                .where( 'email', '==', user.email )
                .orderBy( 'updatedAt', 'desc' )
                .onSnapshot( ( querySnapshot ) => {
                    this.props.parseSubscription( { target: 'myInvites', data: querySnapshot } );
                } );
        }
        instance = true;
    }

    addAccountDependantListeners( nextProps ) {
        const { currentAccount, user } = nextProps;
        if ( typeof currentAccount.accountName !== 'undefined' && currentAccount && addAccountDependantListenersCounter < 1 ) {
            addAccountDependantListenersCounter += 1;
            this.groupsListener = firebase.firestore().collection( 'groups' )
                .where( 'accountId', '==', currentAccount.id )
                .where( 'deleted', '==', false )
                .onSnapshot( ( querySnapshot ) => {
                    this.props.parseSubscription( { target: 'groups', data: querySnapshot } );
                } );
            this.resourcesListener = firebase.firestore().collection( 'resources' )
                .where( 'accountId', '==', currentAccount.id )
                .onSnapshot( ( querySnapshot ) => {
                    this.props.parseSubscription( { target: 'resources', data: querySnapshot } );
                } );
            this.eventsCountListener = firebase.firestore().collection( 'eventsCount' )
                .where( 'accountId', '==', currentAccount.id )
                .onSnapshot( ( querySnapshot ) => {
                    this.props.parseSubscription( { target: 'eventsCount', data: querySnapshot } );
                } );
            this.invitesListener = firebase.firestore().collection( 'invites' )
                .where( 'accountId', '==', currentAccount.id )
                .orderBy( 'updatedAt', 'desc' )
                .onSnapshot( ( querySnapshot ) => {
                    this.props.parseSubscription( { target: 'invites', data: querySnapshot } );
                } );
            setTimeout( () => {
                // we need to delay a bit so that we can fetch ACL
                this.categoriesListener = firebase.firestore().collection( 'categories' )
                    .where( 'accountId', '==', currentAccount.id )
                    .onSnapshot( ( querySnapshot ) => {
                        this.props.parseSubscription( { target: 'categories', data: querySnapshot } );
                    } );
                this.partiesListener = firebase.firestore().collection( 'parties' )
                    .where( 'accountId', '==', currentAccount.id )
                    .where( 'deleted', '==', false )
                    .onSnapshot( ( querySnapshot ) => {
                        this.props.parseSubscription( { target: 'parties', data: querySnapshot } );
                    } );
                this.documentTypesListener = firebase.firestore().collection( 'documentTypes' )
                    .where( 'accountId', '==', currentAccount.id )
                    .where( 'deleted', '==', false )
                    .onSnapshot( ( querySnapshot ) => {
                        this.props.parseSubscription( { target: 'documentTypes', data: querySnapshot } );
                    } );
                this.summariesListener = firebase.firestore().collection( 'summaries' )
                    .where( 'accountId', '==', currentAccount.id )
                    .where( 'deleted', '==', false )
                    .onSnapshot( ( querySnapshot ) => {
                        this.props.parseSubscription( { target: 'summaries', data: querySnapshot } );
                    } );
                this.documentsListener = firebase.firestore().collection( 'documents' )
                    .where( 'accountId', '==', currentAccount.id )
                    .where( 'deleted', '==', false )
                    .orderBy( 'updatedAt', 'desc' )
                    .onSnapshot( ( querySnapshot ) => {
                        this.props.parseSubscription( { target: 'documents', data: querySnapshot } );
                    } );
                this.optionsListener = firebase.firestore().collection( 'options' )
                    .where( 'accountId', '==', currentAccount.id )
                    .where( 'deleted', '==', false )
                    .orderBy( 'updatedAt', 'desc' )
                    .onSnapshot( ( querySnapshot ) => {
                        this.props.parseSubscription( { target: 'options', data: querySnapshot } );
                    } );
                this.calendarEventsListener = firebase.firestore().collection( 'calendarEvents' )
                    .where( 'accountId', '==', currentAccount.id )
                    .where( 'deleted', '==', false )
                    .orderBy( 'date', 'asc' )
                    .onSnapshot( ( querySnapshot ) => {
                        this.props.parseSubscription( { target: 'calendarEvents', data: querySnapshot } );
                    } );
            }, 300 );
        }
    }

    render() {
        if ( process.env.NODE_ENV !== 'production' ) {
            console.info( 'browser engine name', engineName );
        }
        if ( !acceptedBrowserEngines.includes( engineName ) ) {
            return (
                <div className="app flex-row align-items-center">
                    <Container>
                        <div className="justify-content-center">
                            <div className="content-container in" style={ { top: "5%", textAlign: 'center' } }>
                                <div className="head-line secondary-text-color" style={ { fontSize: 30, lineHeight: '60px', color: '#054d6791', marginBottom: 20 } }>
                    Browser not supported.
                                </div>
                                <div className="subheader primary-text-color" style={ { fontSize: 20, lineHeight: '46px' } }>
                    Please user Google Chrome or Firefox.
                                </div>
                                <hr style={ { margin: '35px 0', border: 'none' } } />
                                <div className="clearfix" />
                            </div>
                        </div>
                    </Container>
                </div>
            );
        }
        if ( !this.props.currentUser ) {
            return <script />;
        }
        if ( this.props.accounts.length === 0 ) {
            return (
                <div>
                    <Display when={ typeof ( this.props.currentUser.email ) !== 'undefined' }>
                        <InviteMessageModal
                            auth={ this.props.auth }
                            currentUser={ this.props.currentUser } />
                    </Display>
                    <h3 style={ { textAlign: 'center', marginTop: '15%', marginBottom: '30px' } }>You’re now a registered user! - please see your organisation’s administrator to be invited to the account. </h3>
                    <div style={ { textAlign: 'center' } }>
                        <button className="btn btn-outline-secondary" onClick={ this.props.logout }>Logout</button>
                    </div>
                </div>
            );
        }

        return (
            <Detector
                polling={ false }
                render={ ( { online } ) => (
                    <div>
                        <OfflineModal showsOfflineMessage={ !online } />
                        <div className="app">

                            <Sidebar { ...this.props } />
                            <Header
                                location={ this.props.location }
                                match={ this.props.match }
                                history={ this.props.history }
                                auth={ this.props.auth }
                                currentUser={ this.props.currentUser } />
                            <MessageCenter />
                            <div className="app-body">
                                <main className="main">
                                    <Container fluid>
                                        <Switch>
                                            <Route
                                                path="/dashboard"
                                                name="Dashboard"
                                                render={ ( props ) => (
                                                    <Dashboard auth={ this.props.auth } history={ this.props.history } />
                                                ) }
                                            />
                                            <RouteWrapper
                                                path="/account"
                                                component={ Account } />
                                            <RouteWrapper
                                                path="/parties"
                                                component={ PartiesView } />
                                            <RouteWrapper
                                                path="/alerts"
                                                component={ AlertSearch } />
                                            <Route
                                                path="/calendar"
                                                name="Calendar"
                                                component={ Calendar } />
                                            <Route
                                                path="/activity"
                                                name="Activity"
                                                component={ ActivityList } />
                                            <RouteWrapper
                                                path="/docvault/category"
                                                name="Doc Category"
                                                component={ CategoryView } />
                                            <RouteWrapper
                                                path="/docvault"
                                                name="Doc Vault"
                                                component={ Vault } />
                                            <RouteWrapper
                                                path="/filesearch"
                                                name="File Search"
                                                component={ FileContentSearch } />
                                            <RouteWrapper
                                                path="/add"
                                                name="Add"
                                                component={ DocumentSelector } />
                                            <Route
                                                path="/edit-document"
                                                name="Edit"
                                                render={ ( props ) => (
                                                    <DocumentAdd history={ props.history } location={ props.location } />
                                                ) } />
                                            <RestrictedRoute
                                                exact
                                                path="/settings"
                                                name="Categories"
                                                component={ Category } />
                                            <RouteWrapper
                                                exact
                                                path="/profile"
                                                name="Profile"
                                                component={ Profile } />
                                            <RouteWrapper
                                                exact
                                                path="/groups"
                                                name="Groups"
                                                component={ Groups } />
                                            <Redirect
                                                from="/"
                                                to="/dashboard" />
                                            {/* <Route path="/" name="Page not found" component={Page404} /> */}
                                        </Switch>
                                    </Container>
                                </main>
                            </div>
                            <Footer />
                        </div>
                        <Aside />
                        <Display when={ typeof ( this.props.currentUser.email ) !== 'undefined' }>
                            <InviteMessageModal
                                auth={ this.props.auth }
                                currentUser={ this.props.currentUser } />
                        </Display>
                    </div>
                ) }
            />
        );
    }
}


const { object, string, func } = PropTypes;
Full.propTypes = {
    auth: object,
    updateInvite: func.isRequired,
};

const mapStateToProps = state => ( {
    user: state.application.user,
    currentUser: state.currentUser,
    currentAccount: state.currentAccount,
    categoryTree: state.categories.categoryTree,
    accounts: state.accounts,
} );

const mapDispatchToProps = dispatch => ( {
    parseSubscription: ( data ) => dispatch( applicationOperations.parseSubscription( data ) ),
    updateInvite: ( status ) => dispatch( invitesOperations.updateInvite( status ) ),
    logout: ( ) => dispatch( applicationOperations.logout( ) ),
    clear: ( ) => dispatch( uploadsOperations.clear( ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Full );
