import * as Sentry from '@sentry/browser';

import fetchHelper from '../../utils/fetchHelper';
import { auth } from '../../firebase';
import actions from '../ducks/application/actions';
import types from '../ducks/application/types';

// TODO: extract handle 403 logic to separate class and add unit tests for it
let calls = 0;
const firebaseAuth = auth;
function handleErrors( err, action, next ) {
    Sentry.captureException( err, { extra: { ...action } } );
    if ( err.status >= 500 ) {
        return next( new Error( 'there was a problem', err ) );
    }
    if ( err.message === 'Network Error' ) {
        next( {
            type: `${action.type}_CANCELED`,
            async: true,
            generalFetching: action.generalFetching,
            payload: err
        } );
        // show session expired message
        const id = Math.floor( 1000 * Math.random( ) );
        next( {
            type: 'SHOW_MESSAGE',
            payload: {
                text: 'There is a CORS or network problem',
                type: 'info',
                options: {},
                id
            }
        } );

        // delete session expired message after 10 seconds
        setTimeout( () => {
            next( {
                type: 'DELETE_MESSAGE',
                payload: {
                    id
                }
            } );
        }, 6000 );
    } else if ( err.response.status === 403 ) {
        if ( firebaseAuth.currentUser === null ) {
            return;
        }
        if ( calls < 3 ) {
            // we need a failsafe to avoid infinite loop
            calls++;
            console.warn( 'Session token invalid, refetching new token' );

            return firebaseAuth.currentUser.getIdToken( true )
                .then( ( accessToken ) => {
                    // dispatch event with new token ( this will write it in localStorage as well)
                    next( {
                        type: 'SAVE_NEW_TOKEN',
                        payload: accessToken
                    } );
                    setTimeout( () => {
                        const { url, method, headers, body } = prepareCallData( action );
                        return fetchHelper( url, method, headers, body ).then(
                            response => handleResponse( response, action, next ),
                            error => handleErrors( error, action, next )
                        );
                    }, 500 );
                } )
                .catch( ( error ) => {
                    Sentry.captureException( error );
                    // if getting new token fails, logout user.
                    next( {
                        type: `${action.type}_FAILED`,
                        async: true,
                        generalFetching: action.generalFetching,
                        payload: error
                    } );

                    // delete session id and redirect to login
                    if ( typeof document !== 'undefined' ) {
                        localStorage.removeItem( 'sessionId' );
                        return this.props.history.replace( '/login' );
                    }
                    Sentry.captureException( error, { extra: { type: action.type, payload: action.payload } } );
                    return Promise.reject( error );
                } );
        }

        // delete session id and redirect to login


        if ( !firebaseAuth.currentUser ) {
        // if somehow user is not present, clear the redux store,
        // session id from local storage and redirect to login

            // prevent side effects for current action
            next( {
                type: `${action.type}_CANCELED`,
                async: true,
                generalFetching: action.generalFetching,
                payload: err
            } );

            // clear Redux store
            // next( { type: 'LOGOUT' } );

            // show session expired message
            const id = Math.floor( 1000 * Math.random( ) );
            next( {
                type: 'SHOW_MESSAGE',
                payload: {
                    text: 'Your session expired. Please login again.',
                    type: 'info',
                    options: {},
                    id
                }
            } );

            // delete session expired message after 10 seconds
            setTimeout( () => {
                next( {
                    type: 'DELETE_MESSAGE',
                    payload: {
                        id
                    }
                } );
            }, 10000 );

            // delete session id and redirect to login
            if ( typeof document !== 'undefined' ) {
                localStorage.removeItem( 'sessionId' );
                window.location.href = '/login';
            }

            // Reject current action promise.
            // pass here a param to prevent default message to appear in UI. Need to treat it in each operation.
            Sentry.captureException( err, { extra: { type: action.type, payload: action.payload, canceled: true } } );
            return Promise.reject( err );
        }
    }

    next( {
        type: `${action.type}_FAILED`,
        async: true,
        generalFetching: action.generalFetching,
        payload: err
    } );
    Sentry.captureException( err, { extra: { type: action.type, payload: action.payload } } );
    return Promise.reject( err );
}

function handleResponse( res, action, next ) {
    const redirectUrl = res.redirectUrl || ( res.contentNode && res.contentNode.redirectUrl );
    if ( redirectUrl ) {
        next( {
            type: `${action.type}_REDIRECTED`,
            payload: redirectUrl
        } );
        return res;
    }

    next( {
        type: `${action.type}_COMPLETED`,
        async: true,
        generalFetching: action.generalFetching,
        additionalData: action.additionalData,
        payload: res
    } );

    return res;
}

const apiService = ( ) => next => async ( action ) => {
    const result = next( action );
    if ( !action.async ) {
        return result;
    }
    await checkAndGenerateToken( action, next );
    const { url, method, headers, body } = prepareCallData( action );

    return fetchHelper( url, method, headers, body ).then(
        res => handleResponse( res, action, next ),
        err => handleErrors( err, action, next )
    );
};

async function checkAndGenerateToken( action, next ) {
    return new Promise( async ( resolve, reject ) => {
        const sessionId = localStorage.getItem( 'sessionId' );
        if ( typeof action.additionalData === 'undefined' || typeof action.additionalData.user === 'undefined' ) {
            resolve();
            return;
        }
        const { user } = action.additionalData;
        if ( typeof sessionId === 'undefined' || sessionId === null
      || typeof user.lastTokenUpdate === 'undefined'
      || user.lastTokenUpdate < new Date().getTime() - 1800000 ) {
            if ( firebaseAuth.currentUser === null ) {
                resolve();
                return;
            }
            const accessToken = await firebaseAuth.currentUser.getIdToken( true )
                .catch( e => { console.error( e ); throw e; } );
            next( {
                type: 'SAVE_NEW_TOKEN',
                payload: accessToken
            } );
            resolve();
        }
        resolve();
    } );
}

export default apiService;

function prepareCallData( action ) {
    const { path, method = 'GET', body, forwardedIp } = action.payload;
    if ( !path ) {
        throw new Error( `'path' not specified for async action ${action.type}` );
    }
    let url = `${path}`;
    const headers = {};
    if ( action.external ) {
        url = path;
    }

    if ( forwardedIp ) {
        headers['x-real-ip'] = forwardedIp;
    }
    return { url, method, headers, body };
}
