import React from 'react';
import PropTypes from 'prop-types';

const Display = ( { when, children } ) => ( when ? <React.Fragment>{children}</React.Fragment> : ( <noscript /> ) );

const { bool, any } = PropTypes;
Display.propTypes = {
    when: bool,
    children: any
};

export default Display;
