/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Alert, Badge } from 'reactstrap';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import Display from '../Display';
import DateSelector from '../Date/DateSelector';
import Timepicker from '../Timepicker/Timepicker';
import Repeater from '../Repeater/Repeater';
import Switch from '../Repeater/Switch';
import Notes from '../Notes/Notes';
import GroupSelector from '../GroupSelector/GroupSelector';
import buildRule from '../../utils/buildRule';

class CalendarEventModal extends React.Component {
    constructor() {
        super();
        const time = new Date();
        time.setMinutes( 0 );
        this.state = {
            resolve: false,
            editRecurrence: false,
            decisionMade: false,
            explicitEdit: false,
            error: false,
            repeatError: false,
            date: new Date(),
            time,
            reason: '',
            name: '',
            priority: '',
            groups: {},
            notes: []
        };
        this.handleChange = this.handleChange.bind( this );
        this.timeCallback = this.timeCallback.bind( this );
        this.repeater = this.repeater.bind( this );
        this.sendData = this.sendData.bind( this );
        this.toggleRepeat = this.toggleRepeat.bind( this );
        this.editSingleEvent = this.editSingleEvent.bind( this );
        this.editRecurrence = this.editRecurrence.bind( this );
        this.prefill = this.prefill.bind( this );
        this.resolveEvent = this.resolveEvent.bind( this );
        this.handleInputChange = this.handleInputChange.bind( this );
        this.toggleEdit = this.toggleEdit.bind( this );
        this.toggleResolve = this.toggleResolve.bind( this );
        this.cancelRecurrence = this.cancelRecurrence.bind( this );
        this.buildSelectedGroupsDOM = this.buildSelectedGroupsDOM.bind( this );
        this.removeSelectedGroup = this.removeSelectedGroup.bind( this );
        this.selectGroup = this.selectGroup.bind( this );
        this.notesUpdates = this.notesUpdates.bind( this );
        this.removeGroup = this.removeGroup.bind( this );
    }

    UNSAFE_componentWillMount() {
        this.prefill( this.props.selectedEvent );
        if ( this.props.explicitEdit ) {
            this.setState( { explicitEdit: this.props.explicitEdit } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( Object.keys( nextProps.selectedEvent ).hasItems() && nextProps.selectedEvent.id !== this.props.selectedEvent.id ) {
            this.prefill( nextProps.selectedEvent );
        }
    }

    prefill( data ) {
        const time = new Date(); time.setMinutes( 0 );
        this.setState( { ...data, time: data.date ? new Date( data.date ) : time } );
    }

    resolveEvent() {
        if ( this.state.reason.length === 0 ) {
            this.setState( { error: 'Please enter a reason' } );
            return;
        }
        const payload = {
            name: this.state.name,
            priority: this.state.priority,
            categoryNode: this.state.categoryNode,
            date: moment.utc( this.state.date ),
            documentId: this.state.documentId,
            accountId: this.state.accountId,
            groups: this.state.groups,
            notes: this.state.notes,
            documentName: this.state.documentName,
            reason: this.state.reason,
            id: this.props.selectedEvent.id
        };
        this.props.resolveEvent( payload ).then( () => {
            this.props.toggle();
        } )
            .catch( () => {
                this.props.toggle();
            } );
    }

    handleChange( mom ) {
        mom.setHours( this.state.time.getHours() );
        mom.setMinutes( this.state.time.getMinutes() );
        this.setState( {
            date: mom
        } );
    }

    handleInputChange( event ) {
        if ( !this.state.explicitEdit ) {
            return;
        }
        if ( event.explicitEdit ) {
            this.setState( event );
        }
        if ( typeof event.target === 'undefined' ) {
            return;
        }
        const { target } = event;
        const value = target.type === 'level' ? target.checked : target.value;
        const { name } = target;
        this.setState( {
            [name]: value,
            error: false
        } );
    }

    sendData( cancel = false ) {
        if ( this.state.name.length === 0 ) {
            this.setState( { error: 'Please enter an event name' } );
            return;
        }
        if ( this.state.repeatError || this.state.error ) {
            return;
        }

        const payload = {
            id: this.state.id,
            name: this.state.name,
            priority: this.state.priority,
            categoryNode: this.state.categoryNode,
            date: moment.utc( this.state.date ),
            dateTimestamp: moment.utc( this.state.date ).valueOf(),
            documentId: this.state.documentId,
            accountId: this.state.accountId,
            documentName: this.state.documentName,
            repeatData: this.state.repeatData || this.props.recurrenceDefinition,
            dueTime: this.state.dueTime,
            groups: this.state.groups,
            notes: this.state.notes.map( note => { delete note.new; return note; } )
        };
        payload.dueTime = { hour: this.state.time.getHours(), minutes: this.state.time.getMinutes() };
        // add default group to event
        if ( Object.keys( payload.groups ).length === 0 ) {
            const singleGroup = this.props.groups.filter( group => group.single && typeof group.members[this.props.currentUser.id] !== 'undefined' )[0];
            if ( typeof singleGroup !== 'undefined' ) {
                payload.groups[singleGroup.id] = true;
            } else {
                console.error( 'User does not have single group' );
            }
        }
        if ( this.state.recurrencyId ) {
            payload.currentDate = new Date().toISOString();
            payload.recurrencyId = this.state.recurrencyId;
            if ( this.state.singleEventEdit ) {
                payload.singleRecurrentEventEdit = true;
            }
        }
        if ( this.props.selectedEvent.alertDate !== this.state.alertDate ) {
            payload.currentDate = new Date().toISOString();
            delete payload.recurrencyId;
            payload.repeatData = {};
        }
        if ( cancel ) {
            payload.cancelRecurrency = true;
        }
        if ( !document.body.classList.contains( 'aside-menu-hidden' ) ) {
            document.body.classList.toggle( 'aside-menu-hidden' );
        }
        if ( typeof this.props.action === 'undefined' ) {
            this.props.saveCalendarEvent( payload )
                .then( () => {
                    this.props.toggle();
                } )
                .catch( () => {
                    this.props.toggle();
                } );
        } else {
            this.props.action( payload );
        }
    }

    toggleEdit() {
        if ( this.state.resolve ) {
            this.setState( { resolve: false } );
        }
        this.setState( { explicitEdit: !this.state.explicitEdit } );
    }

    toggleResolve() {
        if ( this.state.explicitEdit ) {
            this.setState( { explicitEdit: false } );
        }
        this.setState( { resolve: !this.state.resolve } );
    }

    repeater( data ) {
        let dates = [];
        const payload = { dueTime: this.state.dueTime, repeatData: data, date: moment.utc( this.state.date ).toISOString() };
        try {
            const { rule } = buildRule( payload );
            dates = rule.all();
            if ( dates.length > 100 ) {
                this.setState( { repeatError: `Maximum 100 recurrent events allowed. The current setup creates ${dates.length}. Please modify the rule.` } );
            } else {
                this.setState( { repeatData: data, repeatError: false } );
            }
        } catch ( e ) {
            console.error( e );
        // throw e;
        }
    }

    notesUpdates( data ) {
        this.setState( { notes: data } );
    }

    timeCallback( dataString ) {
        const time = dataString.split( 'T' )[1].split( ':' );
        const data = new Date( dataString );
        let { date } = this.state;
        if ( typeof this.state.date === 'string' ) {
            date = new Date( this.state.date );
        }
        date.setHours( data.getHours() );
        date.setMinutes( data.getMinutes() );
        this.setState( { dueTime: { hour: time[0], minutes: time[1] }, time: data, date } );
    }

    toggleRepeat() {
        const newState = { ...this.state };
        if ( this.state.displayRepeat ) {
            newState.repeatData = {};
            newState.displayRepeat = false;
            newState.repeatError = false;
        } else {
            newState.displayRepeat = true;
            newState.repeatError = true;
        }
        this.setState( { ...newState } );
    }

    editSingleEvent() {
        this.setState( { singleEventEdit: true, decisionMade: true } );
    }

    editRecurrence() {
        this.setState( { editRecurrence: true, displayRepeat: true, decisionMade: true } );
    }

    cancelRecurrence() {
        this.sendData( true );
    }

    selectGroup( data ) {
        this.setState( { groups: { ...this.state.groups, [data]: true } } );
    }

    removeGroup( data ) {
        const a = {};
        data.map( entry => { a[entry] = true; } );
        this.setState( { groups: a } );
    }

    removeSelectedGroup( data ) {
        const newState = { ...this.state.groups };
        delete newState[data];
        this.setState( { groups: newState } );
    }

    buildSelectedGroupsDOM( ) {
        return Object.keys( this.state.groups ).map( id => {
            const selectedGroup = this.props.groups.having( 'id', id )[0];
            if ( typeof selectedGroup !== 'undefined' ) {
                return (
                    <Badge color="primary" className="selectedGroup">{selectedGroup.name} <span onClick={ () => { this.removeSelectedGroup( id ); } }>x</span></Badge>
                );
            }
            return '';
        } );
    }

    render() {
        const { resolved, recurrencyId } = this.props.selectedEvent;
        const { decisionMade } = this.state;
        const { toggle, openState, showEdit, currentUser } = this.props;
        if ( typeof recurrencyId !== 'undefined' && !decisionMade ) {
            return (
                <Modal isOpen={ openState } toggle={ toggle }>
                    <ModalHeader toggle={ toggle }>Repeating event edit</ModalHeader>
                    <ModalBody>
                        <Alert color="info">
                            <h4>You are about to change a repeating event.</h4>
                            <p>Do you want to change only this event, or all occurrences?</p>
                        </Alert>
                    </ModalBody>
                    <ModalFooter className="position-relative">
                        <button
                            className="btn btn-outline-primary btn-block col-sm-3 pull-left mt-0 position-absolute"
                            style={ { left: 15 } }
                            onClick={ toggle }
                        >
              Cancel
                        </button>
                        <Button color="secondary" onClick={ this.editRecurrence }>All</Button>
                        <Button color="primary" onClick={ this.editSingleEvent }>Only this event</Button>
                    </ModalFooter>
                </Modal>
            );
        }
        const groups = determineGroups( this.props.groups, this.props.resources, this.props.selectedEvent, this.props.selectedDocument, this.props.tempDocument )
            .filter( group => group.name !== 'No Access' && group.name !== 'Read Only' && group.name !== 'Users' && !group.escalation );
        return (
            <Modal isOpen={ openState } toggle={ toggle }>
                <ModalHeader toggle={ toggle }>
                    <Display when={ resolved }>
                        <span>Event closed</span>
                    </Display>
                    <Display when={ this.state.resolve && !resolved && !this.state.editRecurrence }>
                        <span>Resolve event</span>
                    </Display>
                    <Display when={ !this.state.resolve && !resolved }>
                        <span>Calendar event</span>
                    </Display>
                    <Display when={ !currentUser.isReadOnly }>
                        <span>
                            <Display when={ showEdit && !resolved && !this.state.editRecurrence }>
                                <Display when={ !this.state.resolve && !resolved }>
                                    <Button
                                        style={ { position: 'absolute', top: '11px', float: 'right', right: '100px' } }
                                        color="primary"
                                        onClick={ this.toggleResolve }
                                        className="btn btn-outline-info">Resolve
                                    </Button>
                                </Display>

                            </Display>
                            <Display when={ showEdit && !resolved }>
                                <div
                                    color="primary"
                                    name="edit"
                                    style={ { marginTop: '-4px', cursor: 'pointer' } }
                                    className="enableEditBtn btn btn-outline-primary btn-outline btn-circle m-r-5 float-right align-content-center"
                                    onClick={ this.toggleEdit }>
                                    <div className="fa fa-file-o" style={ {} } />
                                </div>
                            </Display>
                        </span>
                    </Display>
                    <Display when={ this.state.error }>
                        <div style={ { color: 'red', fontSize: '12px' } }>{this.state.error}</div>
                    </Display>
                </ModalHeader>
                <ModalBody>
                    <Display when={ !this.state.resolve }>
                        <form style={ { marginBottom: 20 } } className={ this.state.explicitEdit && !resolved ? '' : 'formDisabled' }>
                            <Display when={ !!this.state.documentName }>
                                <label className="control-label">Document name:</label>
                                <input
                                    name="documentName"
                                    id="documentName"
                                    type="input"
                                    disabled={ true }
                                    className="form-control"
                                    value={ this.state.documentName } />
                                <br />
                            </Display>
                            <label className="control-label">Event name:</label>
                            <input
                                name="name"
                                id="eventName"
                                type="input"
                                disabled={ !this.state.explicitEdit }
                                className="form-control"
                                value={ this.state.name }
                                onChange={ this.handleInputChange } />
                            <br />
                            <label>Event priority:</label>
                            <select
                                className="form-control"
                                name="priority"
                                id="eventPriority"
                                disabled={ !this.state.explicitEdit }
                                value={ this.state.priority }
                                onChange={ this.handleInputChange }>
                                <option value="low" style={ { color: 'green' } }>Low</option>
                                <option value="medium" style={ { color: 'blue' } }>Medium</option>
                                <option value="high" style={ { color: 'red' } }>High</option>
                            </select>
                            <br />
                            <label>Due date:</label>
                            <DateSelector disabled={ !this.state.explicitEdit } value={ this.state.date } onChange={ this.handleChange } />
                            <br />
                            <label style={ { marginRight: 10 } }>Due time:</label>
                            <Timepicker value={ this.state.time } onChange={ this.timeCallback } disabled={ !this.state.explicitEdit } />
                            <br />
                            <br />
                            <label>Assign to groups:</label>
                            <GroupSelector
                                eventModal
                                disabled={ !this.state.explicitEdit }
                                groups={ groups }
                                data={ Object.keys( this.state.groups ) }
                                removeGroup={ this.removeGroup }
                                callback={ this.selectGroup } />
                            <br />

                            <Display when={ this.state.displayRepeat && this.state.editRecurrence }>
                                <React.Fragment>
                                    <br />
                                    <Display when={ this.state.repeatError }>
                                        <div style={ { color: 'red', fontSize: '12px' } }>{this.state.repeatError}</div>
                                    </Display>
                                    <label>Repeat:</label>
                                    <Repeater
                                        callback={ this.repeater }
                                        eventDate={ typeof this.state.date === 'string' ? this.state.date : this.state.date.toISOString() }
                                        open={ this.state.displayRepeat }
                                        state={ this.props.recurrenceDefinition }
                                        disabled={ !this.state.explicitEdit }
                                    />
                                    <br />
                                </React.Fragment>
                            </Display>
                            <Display when={ Object.keys( this.props.selectedEvent ).length === 0 || typeof this.props.selectedEvent.recurrencyId === 'undefined' }>
                                <React.Fragment>

                                    <label className="repeatContainerLabel" style={ { marginLeft: 0, marginTop: 10 } }>Repeat: <Switch
                                        toggle={ this.toggleRepeat }
                                        disabled={ !this.state.explicitEdit } />
                                    </label>
                                    <Display when={ this.state.repeatError }>
                                        <div style={ { color: 'red', fontSize: '12px' } }>{this.state.repeatError}</div>
                                    </Display>
                                    <Display when={ this.state.displayRepeat }>
                                        <Repeater eventDate={ typeof this.state.date === 'string' ? this.state.date : this.state.date.toISOString() } callback={ this.repeater } open={ this.state.displayRepeat } disabled={ !this.state.explicitEdit } />
                                    </Display>
                                </React.Fragment>
                            </Display>
                            <br />
                            <br />
                            <div className="position-relative">
                                <label>Notes:</label>
                                <Notes data={ this.state.notes } notesUpdates={ this.notesUpdates } currentUser={ this.props.currentUser } />
                            </div>
                        </form>
                    </Display>

                    <Display when={ this.state.resolve || resolved }>
                        <form style={ { marginBottom: 20 } }>
                            <label className="control-label"> Reason:</label>
                            <input
                                type="input"
                                className="form-control"
                                value={ this.state.reason }
                                disabled={ resolved }
                                onChange={ ( e ) => {
                                    this.setState( { reason: e.target.value } );
                                } } />
                            <br />
                            <label>Resolved by:</label>
                            <input
                                name="name"
                                type="input"
                                className="form-control"
                                value={ this.state.resolve && !resolved ? this.props.currentUser.email : Object.keys( this.props.selectedEvent ).hasItems() ? this.props.selectedEvent.changedBy.email : '' }
                                disabled />
                            <br />
                            <label>Resolved at:</label>
                            <input
                                name="name"
                                type="input"
                                className="form-control"
                                value={ this.state.resolve && !resolved ? moment( Date.now() ).format( 'DD MMM YYYY, HH:mm A' ) : moment( this.props.selectedEvent.updatedAt ).format( 'DD MMM YYYY, HH:mm A' ) }
                                disabled />
                        </form>
                    </Display>
                </ModalBody>
                <Display when={ window.location.pathname !== '/edit-document' || this.state.explicitEdit || this.state.resolve }>
                    <ModalFooter>

                        <Display when={ this.state.explicitEdit && this.state.editRecurrence }>
                            <Button color="danger" id="saveEvent" onClick={ this.cancelRecurrence }>Cancel recurrence</Button>
                        </Display>

                        <Display when={ this.state.explicitEdit }>
                            <Button color="primary" id="saveEvent" disabled={ this.state.repeatError || this.state.error } onClick={ () => this.sendData( false ) }>Save</Button>
                        </Display>

                        <Display when={ this.state.resolve }>
                            <Button color="primary" id="saveEvent" onClick={ this.resolveEvent }>Save</Button>
                        </Display>
                        <Display when={ window.location.pathname !== '/edit-document' }>
                            <Link
                                className="btn btn-outline-primary"
                                id="edit-document"
                                color="info"
                                to={ `/edit-document?accountId=${this.props.currentAccount.id}&id=${this.state.documentId}` }>Show Entry
                            </Link>
                        </Display>
                    </ModalFooter>
                </Display>
            </Modal>
        );
    }
}

CalendarEventModal.defaultProps = {
    edit: true,
    showEdit: false,
    currentUser: {}
};

const { func, string, bool, object } = PropTypes;
CalendarEventModal.propTypes = {
    toggle: func.isRequired,
    action: func.isRequired,
    date: object,
    priority: string,
    name: string,
    openState: bool.isRequired,
    currentUser: object,
    edit: bool,
    showEdit: bool
};

const mapStateToProps = state => ( {
    user: state.application.user,
    selectedEvent: state.events.selectedEvent,
    recurrenceDefinition: state.events.recurrenceDefinition,
    selectedDocument: state.documents.selectedDocument,
    tempDocument: state.documents.tempDoc,
    currentUser: state.currentUser,
    resources: state.resources,
    currentAccount: state.currentAccount,
    groups: state.groups
} );

const mapDispatchToProps = dispatch => ( {
    resolveEvent: ( data ) => dispatch( calendarEventsOperations.resolveEvent( data ) ),
    saveCalendarEvent: ( data ) => dispatch( calendarEventsOperations.saveCalendarEvent( data ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( CalendarEventModal );

/**
 * Filters out to display only groups that have access to the event category.
 * @param {array} groups
 * @param {array} resourcesData
 * @param {object} selectedEvent
 * @param selectedDocument {object} selectedDocument
 * @returns {*}
 */
function determineGroups( groups, resourcesData, selectedEvent, selectedDocument, tempDocument ) {
    return groups.filter( group => {
        let isValid = false;
        let resources = [];
        group.resources.map( id => {
            resources = resourcesData.having( 'id', id );
        } );
        resources.map( resource => {
            if ( isValid ) { return; }
            const category = resource.prettyResourceName.split( '::' )[2].split( ':' )[1];
            let eventCategory;
            if ( Object.keys( selectedDocument ).length > 0 ) {
                // editing || adding a new alert
                eventCategory = selectedDocument.formData.location.split( '/' )[0];
            } else if ( Object.keys( tempDocument ).length > 0 && typeof tempDocument.formData.location !== 'undefined' ) {
                // adding an alert to a new doc
                eventCategory = tempDocument.formData.location.split( '/' )[0];
            } else if ( typeof selectedEvent !== 'undefined' && typeof selectedEvent.categoryNode !== 'undefined' ) {
                eventCategory = selectedEvent.categoryNode.split( '/' )[0];
            }

            if ( typeof category === 'undefined' || typeof eventCategory === 'undefined' ) {
                return;
            }
            isValid = category.toLocaleLowerCase() === 'all' || category.toLocaleLowerCase() === eventCategory.toLocaleLowerCase();
        } );
        return isValid;
    } );
}
