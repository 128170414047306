import types from './types';
import applicationTypes from '../application/types';

const initialState = [];
function build( data ) {
    const array = [];
    data.forEach( ( doc ) => {
        array.push( { ...doc.data(), id: doc.id } );
    } );
    return array;
}
const accounts = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'accounts' ) {
                return build( action.payload.data );
            }
        default: return state;
    }
};

export default accounts;
